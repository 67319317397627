import React from 'react';

import { LabelWithValue, Menu, RelativeTime, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';

import style from './DepartmentThumbnail.module.scss';

const DepartmentThumbnail = ({ department, onEdit, refreshData }) => {
  const { updated_at, created_at, name, id } = department;
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteDepartment = async () => {
    try {
      await WarehouseApi.deleteDepartment(id);
      if (refreshData) await refreshData();
      notifyCommon([`Jednostka "${name}" została usunięta.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteDepartmentHandler = () => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz usunąć "${name}"?`,
      handleConfirm: deleteDepartment,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const actions = [
    {
      title: 'Zmień nazwę',
      icon: 'edit',
      action: onEdit
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: deleteDepartmentHandler
    }
  ];

  return (
    <div className={style.container}>
      {renderedModalConfirm}
      <LabelWithValue
        value={name}
        className={style.name}
        label={'Nazwa jednostki'}
      />
      <LabelWithValue
        value={created_at ? <RelativeTime date={created_at} /> : '-'}
        label={'Data dodania'}
      />
      <LabelWithValue
        value={updated_at ? <RelativeTime date={updated_at} /> : '-'}
        label={'Data ostatniej edycji'}
      />
      <Menu
        actions={actions}
        className={style.menu}
      />
    </div>
  );
};

export default DepartmentThumbnail;
