import React from 'react';
import { useSelector } from 'react-redux';

import style from '../../SupplierForm.module.scss';

const FooterInfo = () => {
  const name = useSelector((state) => state.supplierForm.distributor_name);

  return (
    <>
      <div className={style.supplierInfo}>
        Avalio is a digital communication tool between dental clinics and suppliers. All information contained in the above quote
        is confidential.
        <br />
        Avalio does not share information contained in quotes with third parties or use them in any way.
        <br />
      </div>
      <div className={style.supplierInfo}>Supplier: {name}</div>
    </>
  );
};

export default FooterInfo;
