import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  EmptyState,
  Modal,
  SeoHelmet,
  StickyPageHeader,
  SwitchButton,
  useAuthUser,
  useCompany,
  useIsAdmin,
  useIsMobile,
  useRequestAbortController,
  useSearchParam
} from 'components';
import ColumnList from 'components/layout/ColumnList';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import AddCategoryForm from './components/AddCategoryForm';
import ExpenseThumbnail from './components/ExpenseThumbnail';
import Filtering from './components/Filtering';
import PaymentsSummary from './components/PaymentsSummary';
import { refreshExpenses } from './actions';

import style from './Expenses.module.scss';

const Expenses = (props) => {
  const params = query(props);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();
  const company = useCompany();
  const user = useAuthUser();
  const isHQ = company?.type === 'HQ';

  const companyIds = useMemo(() => {
    if (!isHQ) return;
    return user?.user_companies?.map((company) => company.id);
  }, [user]);

  const store = useSelector((state) => state.auth);
  const { selectedCompanyId } = store;

  const search = useSearchParam(params, 'search');
  const startDate = useSearchParam(params, 'startDate');
  const endDate = useSearchParam(params, 'endDate');

  const [pageData, setPageData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [historyStartDate, setHistoryStartDate] = useState(null);
  const [historyEndDate, setHistoryEndDate] = useState(null);
  const [history, setHistory] = useState([]);
  const [historyTotal, setHistoryTotal] = useState(0);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [displayGrouped, setDisplayGrouped] = useState(isHQ && user?.user_companies?.length > 1);

  const [abortController, setNewController] = useRequestAbortController();

  const version = useSelector((state) => state.expenses.version);

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();
    let pageData;

    try {
      setIsLoading(true);

      if (displayGrouped) {
        const params = {
          company_ids: companyIds,
          ...(startDate && { date_from: startDate }),
          ...(endDate && { date_to: endDate }),
          ...(search && { search: search })
        };
        const { data } = await ExpensesApi.getExpensesGrouped(params, signal);
        pageData = data;
      } else {
        const params = {
          ...(isAdmin ? { company_ids: [] } : { company_id: selectedCompanyId }),
          ...(startDate && { date_from: startDate }),
          ...(endDate && { date_to: endDate }),
          ...(search && { search: search })
        };
        const { data } = await ExpensesApi.getUserExpenses(params, signal);
        pageData = data;
      }

      setPageData(pageData);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getHistory = async () => {
    const params = {
      ...(isAdmin ? { company_ids: [] } : { company_id: selectedCompanyId }),
      page: 1,
      perPage: 100
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsHistoryLoading(true);
      const { data } = await ExpensesApi.getExpenseHistory(params, signal);
      setHistory(data.data);
      setHistoryTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [version, displayGrouped, startDate, endDate, search]);

  useEffect(() => {
    getHistory();
  }, [version, historyStartDate, historyEndDate]);

  const saveHandler = () => {
    setVisible(false);
    dispatch(refreshExpenses());
  };

  const headerContent = (
    <>
      <div style={{ flexShrink: '0', marginRight: 'auto', marginLeft: '24px' }}>
        <PaymentsSummary
          data={pageData}
          isLoading={isLoading}
        />
      </div>
      {isHQ && user.user_companies.length > 1 && (
        <div>
          <SwitchButton
            value={displayGrouped}
            setValue={setDisplayGrouped}
            label={'Show all branches'}
          />
        </div>
      )}
      {!isMobile && <Filtering params={params} />}
      {!displayGrouped && !isAdmin && (
        <Button
          label={'Define expense'}
          onClick={() => setVisible(true)}
          iconName={'add'}
        />
      )}
      <TimelineHistory
        history={history}
        buttonLabel={'Expenses history'}
        title={'Expenses history'}
        bottomMessage={history?.length ? `+ ${historyTotal - history?.length} other.` : ''}
        startDate={historyStartDate}
        endDate={historyEndDate}
        setStartDate={setHistoryStartDate}
        setEndDate={setHistoryEndDate}
        isLoading={isHistoryLoading}
        emptyStateType='expensesHistory'
      />
    </>
  );

  return (
    <div className={style.container}>
      <SeoHelmet title={pages.expenses.title} />
      <StickyPageHeader
        name={pages.expenses.title}
        filtering={<Filtering params={params} />}
        filteringOnlyPopover
      >
        {headerContent}
      </StickyPageHeader>
      <ColumnList
        isLoading={isLoading}
        list={pageData}
        component={ExpenseThumbnail}
        extraProps={{
          refreshCallback: () => dispatch(refreshExpenses()),
          startDate,
          endDate,
          search,
          isHQ,
          companyIds,
          displayGrouped
        }}
        emptyState={<EmptyState type={params.get('search') ? 'search' : 'expenses'} />}
      />
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Zdefiniuj wydatek'}
      >
        <AddCategoryForm closeModal={saveHandler} />
      </Modal>
    </div>
  );
};

export default Expenses;
