import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Input, PhoneInput, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CONTACT_PHONE_NUMBER, CONTACT_PHONE_NUMBER_FORMATTED } from 'src/constants/misc';

import { registerSupplierAccount } from '../../../../actions';

import style from '../../../../onboarding.module.scss';

const SupplierAccountForm = ({ closeModal, token }) => {
  const validator = useValidator();
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    const submitData = {
      email,
      name,
      phone,
      password,
      token
    };

    try {
      setLoading(true);
      await dispatch(registerSupplierAccount(submitData));
      closeModal();
      history.push(`/supplier/valuations/${params.uid}`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      className={classNames(style.card, style.withoutPadding)}
      onSubmit={(e) => handleSubmit(e)}
    >
      <p className={style.smallFormLabel}>
        Konto jest darmowe i daje dostęp do przesłanych ofert, aktualnych zamówień oraz nowych klientów.
      </p>
      <p>
        Potrzebujesz wsparcia? Skontaktuj się z nami pod numerem&nbsp;
        <a href={`tel:${CONTACT_PHONE_NUMBER}`}>{CONTACT_PHONE_NUMBER_FORMATTED}</a>
      </p>
      <Input
        id='name'
        name='name'
        placeholder='Imię i nazwisko'
        label='Imię i nazwisko'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Input
        id='email'
        name='email'
        placeholder='Adres e-mail'
        label='E-mail'
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        validator={validator}
        rule={'required|email'}
      />
      <PhoneInput
        id='phone'
        name='phone'
        label='Phone'
        value={phone}
        onChange={setPhone}
        validator={validator}
        rule={'required|phone'}
      />
      <Input
        id='password'
        name='password'
        placeholder='Hasło'
        label='Hasło'
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        validator={validator}
        rule={'required|min_password'}
      />
      <Input
        id='repeatPassword'
        name='repeatPassword'
        placeholder='Powtórz hasło'
        label='Powtórz hasło'
        type='password'
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
        validator={validator}
        rule={`required|min_password|in:${password}`}
      />
      <div className={style.singleButtonWrapper}>
        <Button
          type='submit'
          label={'Utwórz konto dostawcy'}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default SupplierAccountForm;
