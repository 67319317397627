import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';

import { Icon, Menu, RelativeTime, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OCRInvoicesApi } from 'src/api';
import { OCRInvoiceStatuses } from 'src/constants/enums';
import { refreshCartOrCatalog } from 'src/features/CatalogAndCartController/actions';
import { toDateNumber } from 'src/utils/dateTime';
import { getOCRInvoiceStatusTag } from 'src/utils/helpers';

import style from './InvoiceThumbnail.module.scss';

const InvoiceThumbnail = ({ invoice, refreshCallback }) => {
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const { status, wholesaler_name, invoice_number, sale_date, products, linked_products, invoice_name, uid, id, created_at } =
    invoice || {};

  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const reanalyzeInvoice = async () => {
    try {
      await OCRInvoicesApi.analyzeInvoice(uid);
      notifyCommon([`Plik "${invoice_name}" został przeanalizowany ponownie`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const addInvoiceToCatalog = async () => {
    try {
      await OCRInvoicesApi.fillOfferCatalog({ invoice_id: id, company_id: selectedCompany });
      await refreshCallback();
      dispatch(refreshCartOrCatalog());
      notifyCommon([`Produkty z faktury "${invoice_name}" zostały dodane`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteInvoice = async () => {
    try {
      await OCRInvoicesApi.deleteInvoice(id);
      await refreshCallback();
      notifyCommon(['Faktura została usunięta']);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: 'Analizuj ponownie',
      icon: 'replacement',
      action: reanalyzeInvoice
    },
    {
      title: 'Dodaj do katalogu',
      icon: 'add',
      color: 'blue',
      action: addInvoiceToCatalog
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: () =>
        handleOpenModalConfirm({
          message: 'Czy na pewno chcesz usunąć wybraną fakturę?',
          handleConfirm: handleDeleteInvoice
        })
    }
  ];

  const hasProductsWarning = invoice?.linked_products < invoice?.products;

  return (
    <>
      <div className={style.box}>
        <div
          className={style.container}
          onClick={() => history.push(`/offer-catalog/invoices/${id}${history.location.search}`)}
        >
          <div className={style.wrapper}>{status && getOCRInvoiceStatusTag({ status, className: style.tag })}</div>
          <div className={style.wrapper}>
            <p>{wholesaler_name}</p>
          </div>
          <div className={style.wrapper}>
            <Popover content={invoice_name}>
              <p className={style.invoiceName}>{invoice_name}</p>
            </Popover>
          </div>
          <div className={style.wrapper}>
            {products ? (
              <p>
                {linked_products ?? 0} / {products ?? 0}
              </p>
            ) : (
              <p>-</p>
            )}
            {status === OCRInvoiceStatuses.parsed && (
              <Icon
                name={hasProductsWarning ? 'warning' : 'checkInCircle'}
                fill={hasProductsWarning ? '#f1c043' : '#1d7268'}
              />
            )}
          </div>
          <div className={style.wrapper}>
            <Popover content={invoice_number || '-'}>
              <p className={style.invoiceName}>{invoice_number || '-'}</p>
            </Popover>
          </div>
          <div className={style.wrapper}>
            <p>{sale_date ? toDateNumber(sale_date) : '-'}</p>
          </div>
          <div className={style.wrapper}>{created_at ? <RelativeTime date={created_at} /> : '-'}</div>
        </div>
        <Menu
          actions={actions}
          className={style.menu}
        />
      </div>
      {renderedModalConfirm}
    </>
  );
};

export default InvoiceThumbnail;
