import React from 'react';
import classNames from 'classnames';

import { CommentModal, Tag } from 'components';

import { valuationStatuses } from 'src/constants/enums';

import style from '../../Column.module.scss';

const Comments = ({ comment, supplier, lastElement }) => {
  return (
    <div
      className={classNames(style.commentsAndOffers, {
        [style.lastElement]: lastElement
      })}
    >
      <div className={style.giftsAndCommentWrapper}>
        <CommentModal
          comment={supplier.status !== valuationStatuses.pending ? comment : ''}
          modalTitle={`Supplier comment ${supplier.name}`}
          emptyState={<Tag value={'No comment'} />}
        />
      </div>
    </div>
  );
};

export default Comments;
