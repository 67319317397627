import React, { useMemo } from 'react';

import { useAuthUser, useCompany, usePermissions } from 'components';

import { supplierTabs, userAndAdminTabs } from 'src/constants/dashboardElements';
import { userPermissions, userRoles } from 'src/constants/enums';
import Section from 'src/features/Dashboard/components/Section';

import AccessTab from './components/AccessTab';

import style from './QuickAccess.module.scss';

const QuickAccess = ({ config }) => {
  const user = useAuthUser();
  const company = useCompany();

  const [canViewLists] = usePermissions([userPermissions.lists.read]);
  const [canViewValuation] = usePermissions([userPermissions.valuations.read]);
  const [canViewOrders] = usePermissions([userPermissions.orders.read]);

  const tabs = useMemo(() => {
    switch (user.role) {
      case userRoles.admin:
        return userAndAdminTabs;
      case userRoles.user:
        return userAndAdminTabs.filter((item) => !item.requiredPermissions.some((perm) => !company.permissions.includes(perm)));
      case userRoles.supplier:
        return supplierTabs;
      default:
        return [];
    }
  }, [user]);

  if (!canViewLists && !canViewValuation && !canViewOrders) {
    return null;
  }

  return (
    <Section
      title={'Quick access'}
      id={'quick-access'}
    >
      <div className={style.container}>
        {tabs.map(
          (tab) =>
            config.elements?.includes(tab.id) && (
              <AccessTab
                key={tab.id}
                {...tab}
              />
            )
        )}
      </div>
    </Section>
  );
};

export default QuickAccess;
