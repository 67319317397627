import React from 'react';
import classnames from 'classnames';

import { Accordion } from 'components';

import SupplierThumbnail from 'src/features/OrdersView/components/OrderSummary/components/SupplierThumbnail';
import { getFormattedAmount, getOrderRealizationSum, handleNumeralWords } from 'src/utils/helpers';

import style from './OrderSuppliersTable.module.scss';

const OrderSummary = ({ order }) => {
  const { suppliers = [], total_sum, order_approval } = order || {};

  const title = (
    <h2 className={style.tableTitle}>
      Summary
      <p className={style.suppliersQty}>
        ({suppliers.length} {handleNumeralWords(['supplier', 'suppliers', 'suppliers'], suppliers.length)})
      </p>
    </h2>
  );

  return (
    <div className={style.accordionWrapper}>
      <Accordion title={title}>
        <div className={style.tableWrapper}>
          <div className={style.table}>
            <div className={classnames(style.wrapper, style.header)}>
              <p className={classnames(style.text, style.sum)}>Supplier</p>
              <p className={classnames(style.amount, style.sum)}>Value</p>
              <p className={classnames(style.amount, style.sum)}>Products</p>
              <p className={classnames(style.amount, style.sum)}>In progress</p>
              <p className={classnames(style.text, style.sum)}>Invoice</p>
              <p className={classnames(style.amount, style.sum, style.margin)}>Status</p>
            </div>
            {suppliers.map((supplier) => (
              <SupplierThumbnail
                key={supplier.id}
                supplier={supplier}
                orderApproval={order_approval}
              />
            ))}
            <div className={classnames(style.wrapper, style.bottom)}>
              <p className={classnames(style.amount, style.sum)}>Total:</p>
              <p className={classnames(style.amount, style.sum)}>{getFormattedAmount(total_sum)}</p>
              <p className={classnames(style.amount, style.sum)}>{getOrderRealizationSum(suppliers).allProductsSum}</p>
              <p className={classnames(style.amount, style.sum)}>{getOrderRealizationSum(suppliers).realizationSum}</p>
            </div>
          </div>
        </div>
      </Accordion>
    </div>
  );
};

export default OrderSummary;
