import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader, Modal, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { handleNumeralWords } from 'src/utils/helpers';

import PendingProductsTable from '../PendingProductsTable';

import style from './PendingValuations.module.scss';

const PendingValuations = ({ selectedCompany }) => {
  const [valuations, setValuations] = useState([]);
  const isAdmin = useIsAdmin();
  const version = useSelector((state) => state.cartAndCatalog.version);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const queryData = { ...(isAdmin && { company_id: selectedCompany }) };

      try {
        setIsLoading(true);
        const { data } = await ValuationsApi.getPendingStatus(queryData);
        setValuations(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [selectedCompany, version]);

  const haveItems = valuations?.length > 0;
  const description = !haveItems ? (
    <p>You are not currently waiting for any quotes. All visible offers are current.</p>
  ) : (
    <p>
      Widok ofert nie zawiera wszystkich cen. Aktualnie trwa oczekiwanie na&nbsp;
      <strong>
        {valuations?.length} {handleNumeralWords(['wycenę', 'wyceny', 'wycen'], valuations?.length)}.
      </strong>
    </p>
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {description}
      {haveItems && (
        <a
          className={style.link}
          onClick={() => setIsOpen(true)}
        >
          Pokaż aktywne wyceny
        </a>
      )}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Aktywne wyceny'}
      >
        <PendingProductsTable products={valuations} />
      </Modal>
    </>
  );
};

export default PendingValuations;
