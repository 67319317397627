import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Button, Modal } from 'components';

import { orderStatuses } from 'src/constants/enums';
import SendSelectedProduct from 'src/features/orderConfirmations/components/OrderCart/containers/SupplierCart/components/SendSelectedProduct';
import { handleNumeralWords } from 'src/utils/helpers';

import Attachments from '../../../Attachments';
import Communication from '../../../Communication';
import Invoices from '../../../Invoices';
import Summary from '../../../Summary';
import ProductsTable from '../../components/ProductsTable';
import TableHeader from '../../components/TableHeader';
import CancelOrderSupplierForm from './components/CancelOrderSupplierForm';
import ConfirmOrderSupplierForm from './components/ConfirmOrderSupplierForm';

import style from '../../OrderCart.module.scss';

const SupplierCart = (props) => {
  const { pageData, refreshDataCallback, uid } = props;
  const {
    messages,
    attachments,
    products,
    status,
    supplier_id: supplierId,
    created_at: createdAt,
    supplier_pricing_comment,
    user_comment,
    invoices,
    suborder_uid,
    order_uid
  } = pageData || {};

  const [selectedProducts, setSelectedProducts] = useState(products);
  const [isAcceptOrderOpen, setIsAcceptOrderOpen] = useState(false);
  const [isCancelOrderOpen, setIsCancelOrderOpen] = useState(false);
  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    setSelectedProducts((prev) =>
      prev
        .map((prod) => ({
          ...prod,
          ...products.find((p) => p.id === prod.id)
        }))
        .filter((prod) => [orderStatuses.pending, orderStatuses.processing].includes(prod?.status))
    );
  }, [products]);

  const filteredProducts = products.filter((prod) => [orderStatuses.pending, orderStatuses.processing].includes(prod.status));

  const submitButtonLabel = useMemo(() => {
    const productsWord = handleNumeralWords(['product', 'products', 'products'], selectedProducts?.length);
    if (status === orderStatuses.processing) {
      return selectedProducts?.length === 0
        ? 'Select products to ship'
        : `Send selected products (${selectedProducts?.length}/${products?.length} ${productsWord})`;
    } else {
      return selectedProducts?.length === 0
        ? 'Select products to approve'
        : `Confirm the order (${selectedProducts?.length}/${products?.length} ${productsWord})`;
    }
  }, [selectedProducts, products, status]);

  return (
    <div className={style.wrapper}>
      <TableHeader
        clientName={pageData?.order?.invoice_details?.company_name}
        createdAt={pageData?.created_at}
        orderValue={pageData?.total_sum}
        publicId={pageData?.public_id}
        suborderUid={suborder_uid}
        supplierId={supplierId}
        refreshDataCallback={refreshDataCallback}
      />
      <Invoices
        refreshCallback={refreshDataCallback}
        supplierId={supplierId}
        invoices={invoices}
        suborderUid={suborder_uid}
        supplierView
      />
      <ProductsTable
        uid={uid}
        refreshDataCallback={refreshDataCallback}
        setSelectedProducts={setSelectedProducts}
        selectedProducts={selectedProducts}
        products={products}
        status={status}
      />
      <Communication
        orderCreatedAt={createdAt}
        supplierView
        messages={messages}
        refreshHandler={refreshDataCallback}
        pricingComment={supplier_pricing_comment}
        userComment={user_comment}
      />
      {attachments?.length > 0 && (
        <Attachments
          uid={params.uid}
          attachments={attachments}
          supplierId={supplierId}
          refreshDataCallback={refreshDataCallback}
        />
      )}
      <Summary
        totalSum={pageData?.total_sum}
        productsSum={pageData?.products_sum}
        additionalShippingPrice={pageData?.order?.shipping_details?.additional_shipping_price}
        supplierComment={pageData?.supplier_pricing_comment}
        hideShipment
      />
      {filteredProducts.length > 0 && (
        <div className={style.buttonWrapper}>
          {status === orderStatuses.pending && (
            <Button
              label={'Cancel order'}
              onClick={() => setIsCancelOrderOpen(true)}
              isLoading={isCancelLoading}
              gray
            />
          )}
          <Button
            label={submitButtonLabel}
            onClick={() => setIsAcceptOrderOpen(true)}
            isLoading={isLoading}
            disabled={selectedProducts?.length === 0}
          />
        </div>
      )}
      <Modal
        visible={isAcceptOrderOpen}
        onClose={() => setIsAcceptOrderOpen(false)}
        title={
          status === orderStatuses.pending ? 'Potwierdzasz realizację zamówienia?' : 'Potwierdzasz wysłanie wybranych produktów?'
        }
      >
        {status === orderStatuses.pending ? (
          <ConfirmOrderSupplierForm
            uid={params.uid}
            orderUid={order_uid}
            selectedProducts={selectedProducts}
            products={products}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            closeConfirmOrderModal={() => setIsAcceptOrderOpen(false)}
            refreshDataCallback={refreshDataCallback}
            supplierId={supplierId}
          />
        ) : (
          <SendSelectedProduct
            uid={params.uid}
            orderUid={order_uid}
            selectedProducts={selectedProducts}
            products={products}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            closeConfirmOrderModal={() => setIsAcceptOrderOpen(false)}
            refreshDataCallback={refreshDataCallback}
            supplierId={supplierId}
          />
        )}
      </Modal>
      <Modal
        visible={isCancelOrderOpen}
        onClose={() => setIsCancelOrderOpen(false)}
        title='Czy na pewno chcesz anulować zamówienie?'
      >
        <CancelOrderSupplierForm
          uid={params.uid}
          orderUid={order_uid}
          isCancelLoading={isCancelLoading}
          setIsCancelLoading={setIsCancelLoading}
          closeCancelOrderModal={() => setIsCancelOrderOpen(false)}
          refreshDataCallback={refreshDataCallback}
          supplierId={supplierId}
        />
      </Modal>
    </div>
  );
};

export default SupplierCart;
