import React from 'react';

import { FilterByDateRange, FilterBySupplier } from 'components';
import FilterByOptions from 'components/layout/filtering/FilterByOptions';
import SortByOptions from 'components/layout/sorting/SortByOptions';

import { statuses, statusToOption } from '../InvoiceForm';

import style from './Filtering.module.scss';

const sortOptions = [
  { label: 'Payment term: ascending', value: 1, sort_by: 'payment_date', sort_order: 'ASC' },
  { label: 'Payment term: descending', value: 2, sort_by: 'payment_date', sort_order: 'DESC' },
  { label: 'Total: ascending', sort_by: 'sum', value: 3, sort_order: 'ASC' },
  { label: 'Total: descending', sort_by: 'sum', value: 4, sort_order: 'DESC' }
];

const Filtering = ({ params }) => {
  return (
    <div className={style.container}>
      <FilterByOptions
        valueToOption={statusToOption}
        placeholder={'Status'}
        optionKey={'status'}
        options={statuses}
        params={params}
        // isMulti
      />
      <FilterBySupplier
        params={params}
        fullWidth
        // isMulti
        asUser
      />
      <FilterByDateRange params={params} />
      <SortByOptions
        params={params}
        placeholder={'Sorting'}
        options={sortOptions}
      />
    </div>
  );
};

export default Filtering;
