import React from 'react';
import classNames from 'classnames';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './ShipmentPriceInfo.module.scss';

const ShipmentPriceInfo = ({ shippingPrice = 15, included, className }) => {
  if (included) {
    return (
      <p className={classNames(style.shippingPrice, className)}>(including {getFormattedAmount(shippingPrice)} shipping costs)</p>
    );
  }

  return (
    <span className={classNames(style.shippingPrice, className)}>
      {shippingPrice === 0 ? 'Free shipping' : `(+ shipping cost ${getFormattedAmount(shippingPrice)})`}
    </span>
  );
};

export default ShipmentPriceInfo;
