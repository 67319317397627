import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { EmptyState, Modal, useIsAdmin, useManageMany, useModalConfirm, useRecentlyOrderedProducts } from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import ProductsSectionsPlaceholder from 'components/layout/ProductsSectionPlaceholder';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { refreshListView } from 'src/features/ListView/actions';
import ListViewItem from 'src/features/ListView/components/ListViewItem';
import ProductCommentForm from 'src/features/ListView/components/ProductCommentForm';
import { handleNumeralWords } from 'src/utils/helpers';

import style from './ListViewTable.module.scss';
import shared from 'styles/Shared.module.scss';

const ListViewTable = ({ list, isLoading, uid }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const recentlyOrdered = useRecentlyOrderedProducts(uid);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const headerClasses = classNames(shared.tableHeader, style.header, { [style.admin]: isAdmin });

  const products = useMemo(() => {
    const arr = [];
    list.forEach((cat) => arr.push(...cat.items));
    return arr;
  }, [list]);

  const deleteSelectedElements = async (elements, resetHandler) => {
    try {
      await ListsApi.deleteProduct(uid, elements);
      notifyCommon([
        `${handleNumeralWords(
          ['Produkt został usunięty.', 'Produkty zostały usunięte', 'Produkty zostały usunięte'],
          elements.length
        )}`
      ]);
      dispatch(refreshListView());
      resetHandler();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteSelectedElements = (elements = [], resetHandler) => {
    handleOpenModalConfirm({
      handleConfirm: () => deleteSelectedElements(elements, resetHandler),
      message: `Czy na pewno chcesz usunąć ${elements.length} ${handleNumeralWords(
        ['product', 'products', 'products'],
        elements.length
      )} z listy?`
    });
  };

  const {
    BulkManagementModule,
    selectedElements,
    selectAllCheckbox,
    renderSingleCheckbox,
    renderCheckboxForGroup,
    resetHandler
  } = useManageMany({
    allElements: products,
    options: [
      { label: 'Add selected to list', value: '0', onClick: () => setIsModalAddToListOpen(true) },
      { label: 'Add a comment to the selected', value: '1', onClick: () => setIsCommentModalOpen(true) },
      { label: 'Delete selected', value: '2', onClick: (elements) => handleDeleteSelectedElements(elements, resetHandler) }
    ]
  });

  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={headerClasses}>
          <p>No.</p>
          <p>Name</p>
          <p>Producer</p>
          <p>Comment</p>
          <p>Quantity</p>
          {!!isAdmin && <p className={style.referencePrice}>Reference price</p>}
        </div>
        <ProductsSectionsPlaceholder />
      </section>
    );
  }

  if (!list.length > 0) {
    return (
      <section className={shared.tableWrapper}>
        <EmptyState type={'emptyList'} />
      </section>
    );
  }

  return (
    <>
      {BulkManagementModule}
      <section className={shared.tableWrapper}>
        <div className={headerClasses}>
          {selectAllCheckbox || <p>Lp.</p>}
          <p>Name</p>
          <p>Producer</p>
          <p>Comment</p>
          <p>Quantity</p>
          {!!isAdmin && <p className={style.referencePrice}>Reference price</p>}
        </div>
        {list.map((cat) => (
          <div
            className={shared.tableCategory}
            key={cat.name}
          >
            <header className={style.catHeader}>
              <div className={style.checkboxBox}>
                {renderCheckboxForGroup(
                  cat.items.map((item) => item.id),
                  style.checkbox
                )}
              </div>
              <h3 className={shared.tableCategoryTitle}>
                {cat.name} <span className={shared.counter}>({cat.items?.length})</span>
              </h3>
            </header>
            {cat.items.map((item) => (
              <ListViewItem
                key={String(item.id + item.name)}
                item={item}
                uid={uid}
                index={products.map((prod) => prod.id).indexOf(item.id)}
                recentlyOrdered={recentlyOrdered.find((prod) => prod.product_id === item.id)}
              >
                {renderSingleCheckbox(item.id)}
              </ListViewItem>
            ))}
          </div>
        ))}
      </section>
      {BulkManagementModule}
      <AddProductToList
        title={`Dodaj ${selectedElements.length} ${handleNumeralWords(
          ['product', 'products', 'products'],
          selectedElements.length
        )} do wybranej listy`}
        selectedProducts={selectedElements}
        visible={isModalAddToListOpen}
        products={products}
        onClose={() => setIsModalAddToListOpen(false)}
        onSuccess={resetHandler}
      />
      <Modal
        visible={isCommentModalOpen}
        onClose={() => setIsCommentModalOpen(false)}
        title={`Dodaj komentarz do ${selectedElements.length} ${handleNumeralWords(
          ['product', 'products', 'products'],
          selectedElements.length
        )}`}
      >
        <ProductCommentForm
          closeModal={() => setIsCommentModalOpen(false)}
          productIds={selectedElements}
          uid={uid}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default ListViewTable;
