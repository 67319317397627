import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, Card, LabelWithValue, Modal, RelativeTime, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import { refreshServices } from 'src/features/Services/actions';
import AddServiceForm from 'src/features/Services/components/AddServiceForm';
import { toDateNumber } from 'src/utils/dateTime';

import styles from './ServiceDetails.module.scss';

const ServiceDetails = (props) => {
  const { isLoading, service } = props;
  const { model, id, producer, name, serial_number, produced_at, first_used_at, purchased_at, next_service_at } = service;

  const history = useHistory();
  const dispatch = useDispatch();

  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteService = async () => {
    try {
      const { data } = await ServicesApi.deleteService(id);
      dispatch(refreshServices());
      history.push('/services');
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteWholesalerConfirm = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć serwis ${name}?`,
      handleConfirm: deleteService
    });
  };

  const saveHandler = () => {
    setIsVisibleEdit(false);
    dispatch(refreshServices());
  };

  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <LabelWithValue
          label={'Name'}
          value={<h2>{name}</h2>}
        />
        <div className={styles.buttons}>
          <Button
            label={'Delete'}
            onClick={() => deleteWholesalerConfirm()}
            gray
          />
          <Button
            label={'Edit'}
            onClick={() => setIsVisibleEdit(true)}
          />
        </div>
      </div>
      <div className={styles.container}>
        <LabelWithValue
          label={'Producer'}
          value={producer}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Model'}
          value={model}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Serial number'}
          value={serial_number}
          isLoading={isLoading}
        />
      </div>
      <div className={styles.container}>
        <LabelWithValue
          label={'Production date'}
          value={toDateNumber(produced_at)}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Purchase date'}
          value={toDateNumber(purchased_at)}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'First use date'}
          value={toDateNumber(first_used_at)}
          isLoading={isLoading}
        />
        <LabelWithValue
          label={'Next service'}
          value={
            next_service_at ? (
              <RelativeTime
                date={next_service_at}
                hideTime
              />
            ) : (
              '-'
            )
          }
          isLoading={isLoading}
        />
      </div>
      <Modal
        visible={isVisibleEdit}
        title={'Edytuj serwis'}
        onClose={() => setIsVisibleEdit(false)}
      >
        <AddServiceForm
          serviceData={service}
          refreshCallback={saveHandler}
          onClose={() => setIsVisibleEdit(false)}
        />
      </Modal>
      {renderedModalConfirm}
    </Card>
  );
};

export default ServiceDetails;
