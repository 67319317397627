import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { InfoWidget } from 'components';

import { priceTypes } from 'src/constants/enums';

import Product from '../Product';
import CategoryProductsWrapper from './components/CategoryProductsWrapper';

import style from './ProductsTable.module.scss';
import shared from 'styles/Shared.module.scss';

const COLLECTION_PRODUCTS_MESSAGE =
  'A list of products that are added to your offer because you have priced them in the past or added them manually.';
const NOT_COLLECTION_PRODUCTS_MESSAGE =
  'A list of products for which a customer has requested a quote but you have not added to your offer or quoted in the past.';

const ProductsTable = ({ inPublicPage, productsType }) => {
  const categories = useSelector((state) => state.supplierForm.categories);
  const priceType = useSelector((state) => state.supplierForm.form.price_type);

  const getProducts = useCallback((products = []) => {
    const arr = [];

    products.forEach((item) => {
      if (
        (productsType === 'in_collection' && item?.in_collection) ||
        (productsType === 'not_in_collection' && !item?.in_collection)
      ) {
        arr.push(
          <Product
            productData={item}
            key={String(item.id + item.name)}
          />
        );
      }
    });

    return arr;
  }, []);

  const categoriesElements = useMemo(() => {
    const arr = [];

    categories.forEach((cat) => {
      const products = getProducts(cat?.items);

      if (products.length) {
        arr.push(
          <CategoryProductsWrapper
            name={cat.name}
            key={cat.name + productsType}
          >
            {products}
          </CategoryProductsWrapper>
        );
      }
    });

    return arr;
  }, [categories]);

  const headerClasses = classNames(shared.tableHeader, style.header, {
    [style.headerNetto]: priceType.value === priceTypes.netto,
    [style.publicSticky]: inPublicPage
  });

  if (categoriesElements.length === 0) {
    return null;
  }

  return (
    <section className={classNames(shared.tableWrapper, style.container)}>
      <h2 className={classNames(style.title, { [style.notCollection]: productsType === 'not_in_collection' })}>
        {productsType === 'in_collection' ? 'Produkty z Twojej oferty' : 'Products you do not have on offer'}
        <InfoWidget place={'right'}>
          <p>{productsType === 'in_collection' ? COLLECTION_PRODUCTS_MESSAGE : NOT_COLLECTION_PRODUCTS_MESSAGE}</p>
        </InfoWidget>
      </h2>
      <div className={headerClasses}>
        <p>Name</p>
        <p>Price {priceType.label}</p>
        {priceType.value === priceTypes.netto && <p>VAT</p>}
        <p className={style.value}>Qty.</p>
        <p className={style.value}>Value</p>
        <p>Availability</p>
      </div>
      {categoriesElements}
    </section>
  );
};

export default ProductsTable;
