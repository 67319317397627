import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classnames from 'classnames';

import {
  LoaderGlobal,
  PageHeader,
  SeoHelmet,
  Tag,
  useModalConfirm,
  useRequestAbortController,
  useUnreadNotifications
} from 'components';
import { notifyApiError, notifyDanger } from 'components/layout/Toasts';

import { UserApi, ValuationsApi } from 'src/api';
import { valuationStatuses } from 'src/constants/enums';
import { valuationsNtfTypes } from 'src/constants/notifications/types';
import {
  getCategoriesAfterSelectionChangeFromCategories,
  getProducerColumnsFromCategories,
  getUserValuationStatusTag
} from 'src/utils/helpers';

import { setNotificationAsRead } from '../NotificationsStore/actions';
import HeaderContent from './components/HeaderContent';
import HorizontalValuation from './containers/HorizontalValuation';
import VerticalValuation from './containers/VerticalValuation';
import { clearProducts, updateSavings } from './actions';

import 'react-alice-carousel/lib/scss/alice-carousel.scss';
import style from './ValuationView.module.scss';
import shared from 'styles/Shared.module.scss';

const ValuationView = () => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [pageData, setPageData] = useState();
  const [columns, setColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderProceedLoading, setOrderProceedLoading] = useState(false);
  const [optimizationLoading, setOptimizationLoading] = useState(false);
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHidingModalOpen, setIsHidingModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [abortController, setNewController] = useRequestAbortController();
  const [isDisabled, setIsDisabled] = useState(false);
  const [showRefPrice, setShowRefPrice] = useState(false);
  const valuationsVersion = useSelector((state) => state.valuationView.valuationsVersion);
  const wasIsHorizontalChanged = useRef(false);
  const unreadNotifications = useUnreadNotifications(valuationsNtfTypes);
  const isAdminView = useSelector((state) => state.auth.isAdminView);

  const refreshData = useCallback(async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      const { data } = await ValuationsApi.getValuationProducts({ uid }, signal);
      dispatch(updateSavings(data?.savings));
      setPageData(data);
      if (!wasIsHorizontalChanged.current) {
        setIsHorizontal(!!data.is_horizontal);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        notifyDanger(['Wybrana wycena nie istnieje, lub nie masz do niej dostępu.']);
        history.push('/valuations');
      } else {
        notifyApiError(err);
      }
    }
  }, [uid, abortController]);

  const changeValuationSelections = (data) => {
    setPageData((prev) => ({
      ...prev,
      categories: getCategoriesAfterSelectionChangeFromCategories(data, prev?.categories)
    }));
  };

  const proceedOrder = async () => {
    try {
      setOrderProceedLoading(true);
      await ValuationsApi.saveOrderDataProducts(uid);
      history.push(`/valuations/${uid}/order`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setOrderProceedLoading(false);
    }
  };

  const handleProceedOrder = () => {
    pageData?.was_ordered
      ? handleOpenModalConfirm({
          message: 'Na podstawie tej wyceny zostało już złożone zamówienia, czy chcesz kontynuować?',
          handleConfirm: proceedOrder
        })
      : proceedOrder();
  };

  const optimizeSelect = async () => {
    try {
      setOptimizationLoading(true);
      await ValuationsApi.optimizeSelect({ uid });
      await refreshData();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setOptimizationLoading(false);
    }
  };

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      await refreshData();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const setAsRead = async (notification) => {
      try {
        await UserApi.setNotificationAsRead(notification.id);
        dispatch(setNotificationAsRead(notification.id));
      } catch (err) {
        notifyApiError(err);
      }
    };

    const readAll = async (items) => {
      const promises = items.map(setAsRead);
      await Promise.all(promises);
    };

    const currentValuationNotifications = unreadNotifications.filter((ntf) => ntf.uid === uid);
    if (!isAdminView) readAll(currentValuationNotifications);
  }, [unreadNotifications]);

  useEffect(() => {
    if (pageData) setColumns(getProducerColumnsFromCategories(pageData));
  }, [pageData]);

  useEffect(() => {
    getData();
    dispatch(clearProducts());
  }, [history.location.pathname]);

  useEffect(() => {
    if (valuationsVersion > 0) refreshData();
  }, [valuationsVersion]);

  useEffect(() => {
    setIsDisabled(pageData?.status !== valuationStatuses.pending && pageData?.status !== valuationStatuses.sent);
  }, [pageData]);

  const valuationText = useMemo(
    () => (
      <div className={shared.thumbnailContentWrapper}>
        {getUserValuationStatusTag({ status: pageData?.status })}
        {!!pageData?.was_ordered && (
          <Tag
            value={'Złożono zamówienie'}
            className={shared.thumbnailTag}
          />
        )}
      </div>
    ),
    [pageData]
  );

  const setIsHorizontalHandler = (state) => {
    if (!wasIsHorizontalChanged.current) wasIsHorizontalChanged.current = true;
    setIsHorizontal(state);
  };

  if (isLoading) {
    return <LoaderGlobal />;
  }

  if (!pageData) {
    return null;
  }

  return (
    <div
      className={classnames(style.container, {
        [style.smaller]: columns.length === 1
      })}
    >
      <SeoHelmet title={pageData?.name || 'Wycena'} />
      <PageHeader
        name={`Valuation from list: ${pageData?.name}`}
        text={valuationText}
        textCenter
        valuations
      >
        <HeaderContent
          setIsHidingModalOpen={setIsHidingModalOpen}
          isDisabled={isDisabled}
          setIsModalOpen={setIsModalOpen}
          columnsLength={columns.length}
          optimizeSelect={optimizeSelect}
          optimizationLoading={optimizationLoading}
          isLoading={isLoading}
          handleProceedOrder={handleProceedOrder}
          orderProceedLoading={orderProceedLoading}
          isHorizontal={isHorizontal}
          setIsHorizontal={setIsHorizontalHandler}
          setShowRefPrice={setShowRefPrice}
          showRefPrice={showRefPrice}
        />
      </PageHeader>
      {isHorizontal ? (
        <HorizontalValuation
          columns={columns}
          pageData={pageData}
          changeValuationSelections={changeValuationSelections}
          showRefPrice={showRefPrice}
        />
      ) : (
        <VerticalValuation
          pageData={pageData}
          columns={columns}
          changeValuationSelections={changeValuationSelections}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          uid={uid}
          isHidingModalOpen={isHidingModalOpen}
          setIsHidingModalOpen={setIsHidingModalOpen}
        />
      )}

      {renderedModalConfirm}
    </div>
  );
};

export default ValuationView;
