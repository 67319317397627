import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Button, FilterByCompany, Menu, Modal, useIsAdmin, useModalConfirm, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, OfferCatalogApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import FlatFileHandling from 'src/features/CatalogAndCartController/components/FlatFileHandling';

import { changeViewType, refreshCartOrCatalog } from '../../actions';

import style from '../../CatalogAndCartController.module.scss';

const HeaderContent = (props) => {
  const { isLoading, isDisabled, showRefPrice, isCatalogView, setShowRefPrice, selectedCompany, params } = props;

  const isAdmin = useIsAdmin();
  const history = useHistory();
  const dispatch = useDispatch();
  const { listUID } = useParams();

  const [isLocalLoading, setIsLocalLoading] = useState(false);
  const [isOptimizeLoading, setIsOptimizeLoading] = useState(false);
  const [isFlatFileModalOpen, setIsFlatFileModalOpen] = useState(false);

  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const Api = useMemo(() => (isCatalogView ? OfferCatalogApi : ListsApi), []);
  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);
  const [canCreateOrder] = usePermissions([userPermissions.orders.create]);
  const [canViewCatalog] = usePermissions([userPermissions.offer_catalog.read]);
  const [canMenageCatalog] = usePermissions([userPermissions.offer_catalog.menage]);

  const changeViewMode = async () => {
    await deleteSelectsHandler();
    dispatch(changeViewType(!isDefinedView));
  };

  const proceedOrder = () => history.push(`/lists/${listUID}/cart/proceed-order`);

  const changeViewModeHandler = async () => {
    if (!isDefinedView && !isCatalogView) {
      handleOpenModalConfirm({
        message: 'Przełączenie widoku na zdefiniowany spowoduje wyczyszczenie zaznaczenia w koszyku. Czy chcesz kontynuować?',
        handleConfirm: changeViewMode,
        cancelButtonName: 'Nie',
        confirmButtonName: 'Tak'
      });
    } else {
      await changeViewMode();
    }
  };

  const optimizeHandler = async () => {
    const queryData = {
      use_preferred: +isDefinedView,
      ...(isAdmin && { company_id: selectedCompany }),
      ...(listUID && { list_uid: listUID })
    };

    try {
      setIsOptimizeLoading(true);
      await Api.optimizeOfferCatalog(queryData);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsOptimizeLoading(false);
    }
  };

  const deleteSelectsHandler = async () => {
    const queryParams = isAdmin ? { company_id: selectedCompany } : {};

    try {
      setIsLocalLoading(true);
      await Api.removeAllSelects(queryParams);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLocalLoading(false);
    }
  };

  const selectSuppliers = async () => {
    const data = {
      list_uid: listUID,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLocalLoading(true);
      await Api.selectPreferred(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLocalLoading(false);
    }
  };

  const setFirstSecondSuppliers = async () => {
    const data = {
      list_uid: listUID,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      await Api.setPreferredSuppliers(data);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleOptimizeConfirm = () => {
    handleOpenModalConfirm({
      message: 'Optymalizacja koszyka automatycznie dobierze najlepszą konfigurację cen i dostawców. Czy chcesz kontynuować?',
      handleConfirm: optimizeHandler,
      cancelButtonName: 'Nie',
      confirmButtonName: 'Tak'
    });
  };

  const actions = [
    {
      title: 'Select preferred',
      action: selectSuppliers,
      icon: 'tick',
      roles: isDefinedView ? ['user', 'admin'] : ['admin']
    },
    {
      title: 'Przypisz oferty preferowane i alternatywne',
      action: setFirstSecondSuppliers,
      icon: 'edit',
      roles: isCatalogView ? (isDefinedView ? ['user', 'admin'] : ['admin']) : []
    },
    {
      title: isDefinedView ? 'Enable Full View' : 'Enable Defined View',
      action: changeViewModeHandler,
      icon: isDefinedView ? 'toggleOff' : 'toggleOn',
      color: 'blue'
    },
    {
      title: showRefPrice ? 'Ukryj ceny ref.' : 'Pokaż ceny ref.',
      action: () => setShowRefPrice((prev) => !prev),
      icon: isDefinedView ? 'toggleOff' : 'toggleOn',
      color: 'blue',
      roles: ['admin']
    },
    {
      title: 'Import & Export offers',
      action: () => setIsFlatFileModalOpen(true),
      icon: 'download',
      color: 'blue',
      roles: !isCatalogView && canViewCatalog ? ['admin', 'user'] : []
    }
  ];

  const selectCompanyHandler = (value) => {
    params.set('company', value);
  };

  const closeFlatFileModal = () => {
    setIsFlatFileModalOpen(false);
  };

  if (isCatalogView) {
    return (
      <>
        {isAdmin && (
          <FilterByCompany
            value={selectedCompany}
            onChange={selectCompanyHandler}
            className={style.companySelect}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Button
        label={'Clear selections'}
        onClick={deleteSelectsHandler}
        isLoading={isLocalLoading}
        gray
      />
      <Button
        label='Optimize'
        disabled={isDisabled}
        onClick={handleOptimizeConfirm}
        isLoading={isOptimizeLoading || isLoading}
        gray
      />
      {canCreateOrder && (
        <Button
          isLoading={isLoading}
          disabled={isDisabled}
          onClick={proceedOrder}
          label='Order summary'
        />
      )}
      {canMenageCatalog && (
        <Menu
          actions={actions}
          className={style.menu}
        />
      )}
      {renderedModalConfirm}
      <Modal
        visible={isFlatFileModalOpen}
        onClose={closeFlatFileModal}
        title={'Import & Export ofert'}
      >
        <FlatFileHandling
          type={'flatFile'}
          closeModal={closeFlatFileModal}
          selectedCompany={selectedCompany}
        />
      </Modal>
    </>
  );
};

export default HeaderContent;
