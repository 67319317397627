import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  Modal,
  PageHeader,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  SwitchButton,
  Tag,
  useAuthUser,
  useCompany,
  useRequestAbortController,
  useSearchParam
} from 'components';
import BarcodeScanner from 'components/layout/BarcodeScanner';
import { headerTextPlaceholder } from 'components/layout/PageHeader/placeholders';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi, ProductsApi } from 'src/api';
import { productBaseTypes, userRoles } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';
import { getCorrectFormOfResultsLabel } from 'src/utils/helpers';
import query from 'src/utils/query';

import ExportImportForm from './components/ExportImportForm';
import Filtering from './components/Filtering';
import ProductFormAdmin from './components/ProductForms/components/ProductFormAdmin';
import ProductFormUser from './components/ProductForms/components/ProductFormUser';
import ProductsList from './components/ProductsList';

import style from './ProductsBase.module.scss';

const ProductsBase = (props) => {
  const { type } = props || {};

  const params = query(props);
  const user = useAuthUser();
  const company = useCompany();

  const isSupplierPage = useMemo(() => type === productBaseTypes.supplier, [type]);
  const isAdmin = useMemo(() => user?.role === userRoles.admin, [user]);
  const isOfferCatalogEnabled = company?.offer_catalog_enabled;

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState();
  const [isImportExportOpen, setIsImportExportOpen] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(params.get('page'));
  const [querySearch, setQuerySearch] = useState(params.get('search'));
  const [queryCategory, setQueryCategory] = useState(params.get('category'));
  const [queryProducer, setQueryProducer] = useState(params.get('producer'));
  const [queryCompany, setQueryCompany] = useState(params.get('company'));
  const [queryOnlyCompany, setQueryOnlyCompany] = useState(params.get('onlyCompany'));
  const [sortBy, setSortBy] = useState(params.get('sort_by'));
  const [sortOrder, setSortOrder] = useState(params.get('sort_order'));
  const [showCompanyProducts, setShowCompanyProducts] = useState(false);
  const [showCatalogProducts, setShowCatalogProducts] = useState(false);
  const [isModalBarcodeOpen, setIsModalBarcodeOpen] = useState(false);

  const [pageQty, setPageQty] = useState(1);
  const [itemsQty, setItemsQty] = useState();
  const [perPage, setPerPage] = useState(50);

  const [abortController, setNewController] = useRequestAbortController();
  const listVersion = useSelector((state) => state.productsBase.version);
  const openNewProductModal = useSearchParam(params, 'new_element');

  useEffect(() => {
    params.set('page', 1);
  }, [queryOnlyCompany]);

  useEffect(() => {
    params.set('onlyCompany', +showCompanyProducts);
  }, [showCompanyProducts]);

  useEffect(() => {
    setShowCompanyProducts(!!queryOnlyCompany);
  }, []);

  const getData = async () => {
    let error;
    const queryProps = {
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      category_id: queryCategory,
      producer_id: queryProducer,
      company_id: queryCompany,
      onlyCompany: queryOnlyCompany,
      ...(sortBy && { sort_by: sortBy }),
      ...(sortOrder && { sort_order: sortOrder })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    const Api = showCatalogProducts ? OfferCatalogApi : ProductsApi;

    try {
      setIsLoading(true);
      const { data } = await Api.getProducts(queryProps, signal);
      setPageData(data.data);
      setItemsQty(data.total);
      setPageQty(data?.last_page);
      setIsLoading(false);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [
    listVersion,
    currentPage,
    perPage,
    querySearch,
    queryCategory,
    queryProducer,
    queryCompany,
    queryOnlyCompany,
    sortBy,
    sortOrder,
    showCatalogProducts,
    showCompanyProducts
  ]);

  useEffect(() => {
    if (openNewProductModal) setIsModalOpen(true);
  }, [openNewProductModal]);

  useEffect(() => {
    if (openNewProductModal) params.remove('new_element');
  }, [isModalOpen]);

  useEffect(() => {
    setQueryCompany(params.get('company'));
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQueryCategory(params.get('category'));
    setQueryProducer(params.get('producer'));
    setQueryOnlyCompany(params.get('onlyCompany'));
    setSortBy(params.get('sort_by'));
    setSortOrder(params.get('sort_order'));
  }, [params]);

  const actions = [
    {
      title: 'Dodaj produkt',
      icon: 'add',
      action: () => setIsModalOpen(true),
      roles: ['admin']
    },
    {
      title: showCompanyProducts ? 'Show all products' : 'Filter products by' + (isAdmin ? ' branches' : ' branch'),
      icon: showCompanyProducts ? 'toggleOn' : 'toggleOff',
      action: () => setShowCompanyProducts((prev) => !prev),
      disabled: showCatalogProducts
    },
    {
      title: 'Scan the barcode',
      icon: 'multiselect',
      action: () => setIsModalBarcodeOpen(true),
      color: 'blue',
      roles: ['admin', 'user']
    },
    {
      title: 'Import & Export',
      icon: 'download',
      action: () => setIsImportExportOpen(true),
      color: 'blue',
      roles: ['admin']
    }
  ];

  useEffect(() => {
    setShowCompanyProducts(false);
  }, [showCatalogProducts]);

  return (
    <>
      <SeoHelmet title={pages.productsBase.title} />
      <PageHeader
        name={pages.productsBase.title}
        wrapOnMobile
        actions={actions}
        text={
          <div className={style.rowWrapper}>
            {isLoading ? headerTextPlaceholder() : getCorrectFormOfResultsLabel(itemsQty)}
            {showCompanyProducts ? <Tag value={'Produkty oddziałów'} /> : null}
          </div>
        }
      >
        {!isSupplierPage && (
          <>
            {!isAdmin && isOfferCatalogEnabled && (
              <SwitchButton
                label={'Show only products with offer'}
                value={showCatalogProducts}
                setValue={setShowCatalogProducts}
              />
            )}
          </>
        )}
      </PageHeader>
      <div className={style.container}>
        <div
          className={classNames(style.header, {
            [style.headerExtended]: isAdmin
          })}
        >
          <Searchbar
            params={params}
            alwaysExpand
            fullWidth
            searchSize={'large'}
            className={style.search}
          />
          <Filtering
            params={params}
            isAdmin={isAdmin}
          />
        </div>
        <ProductsList
          type={type}
          params={params}
          list={pageData}
          isLoading={isLoading}
        />
        <PaginationNav
          onClick={(number) => setCurrentPage(number)}
          params={params}
          pagesQty={pageQty}
          setPerPage={setPerPage}
          defaultQty={perPage}
        />
        {isAdmin ? (
          <ProductFormAdmin
            title='Dodaj produkt'
            type='create-product'
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        ) : (
          <ProductFormUser
            title='Dodaj produkt'
            type='create-product'
            visible={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
        <Modal
          visible={isImportExportOpen}
          onClose={() => setIsImportExportOpen(false)}
          title={'Import & Export bazy produktów'}
        >
          <ExportImportForm />
        </Modal>
        <Modal
          visible={isModalBarcodeOpen}
          title={'Dodaj numer EAN do produktu'}
          onClose={() => setIsModalBarcodeOpen(false)}
        >
          <BarcodeScanner />
        </Modal>
      </div>
    </>
  );
};

export default ProductsBase;
