import React from 'react';

import ItemTag from '../ItemTag';
import AddTagForm from './components/AddTagForm';

import styles from './ItemTagsList.module.scss';

const ItemTagsList = ({ productId, tags = [], refreshCallback, refreshPageDataCallback, displayOnly }) => {
  return (
    <div className={styles.container}>
      {!displayOnly && <p className={styles.headline}>Tags:</p>}
      <div className={styles.list}>
        {tags.length > 0 ? (
          tags.map((tag) => (
            <ItemTag
              key={tag.id}
              name={tag.name}
              color={tag.color}
              tagId={tag.id}
              productId={productId}
              refreshCallback={refreshCallback}
              refreshPageDataCallback={refreshPageDataCallback}
            />
          ))
        ) : (
          <p>-</p>
        )}
      </div>
      {!displayOnly && (
        <div className={styles.form}>
          <AddTagForm
            assignedTags={tags}
            productId={productId}
            refreshCallback={refreshCallback}
            refreshPageDataCallback={refreshPageDataCallback}
          />
        </div>
      )}
    </div>
  );
};

export default ItemTagsList;
