import React, { useMemo, useRef } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { orderProductTypes } from 'src/constants/enums';

import Product from './components/Product';

import style from './NewQuantityProducts.module.scss';
import shared from 'styles/Shared.module.scss';

const NewQuantityProducts = ({ list = [], status, refreshDataCallback }) => {
  const productsSection = useRef();
  const params = useParams();
  const products = useMemo(() => list.filter((item) => typeof item.new_quantity === 'number' && !item.quantity_accepted), [list]);

  if (!products.length) {
    return null;
  }

  return (
    <div className={style.wrapper}>
      <header className={style.headerTop}>
        <h2 className={style.title}>Zmiana ilości produktów</h2>
        <p>
          Klient prosi o zwiększenie ilości poniższych produktów w zamówieniu. Jeśli nie posiadasz danej ilości, możesz ją
          zmniejszyć w kolumnie &quot;Do realizacji&quot;. Zatwierdź zielonym przyciskiem.
        </p>
      </header>
      <section
        ref={productsSection}
        className={classNames(shared.tableWrapper, style.container)}
      >
        <div className={classNames(shared.tableHeader, style.header)}>
          <p>Nazwa</p>
          <p>Producent</p>
          <p>Valuated</p>
          <p>Zamówiono</p>
          <p>Do realizacji</p>
          <p>Cena za szt.</p>
        </div>
        {products?.map((product) => (
          <Product
            uid={params.uid}
            type={orderProductTypes.supplier}
            product={product}
            key={product.id}
            onQtyChange={() => {}}
            status={status}
            refreshDataCallback={refreshDataCallback}
          />
        ))}
      </section>
    </div>
  );
};

export default NewQuantityProducts;
