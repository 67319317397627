import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, useAuthUser } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { compareArrays, formatUserOption } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByProducts = ({
  params,
  fullWidth,
  valueKey = 'id',
  labelKey = 'name',
  isMulti,
  queryParams = {},
  asUser,
  label,
  disabledElements,
  isOptionDisabled,
  ...rest
}) => {
  const [selectedProducts, setSelectedProducts] = useState(isMulti ? [] : null);

  const paramValue = params.get('product_id');

  const setSingleValue = (product_id) => {
    const areValueTheSame = product_id?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'product_id', value: product_id?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('product_id', product_id?.value);
      }
    }
  };

  const setMultiValue = (product_id) => {
    const idsArray = product_id?.map((product) => product.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'product_id', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('product_id', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedProducts(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  return (
    <AsyncSelect
      id={'select-product'}
      value={selectedProducts}
      onChange={onChangeHandler}
      apiCallback={ProductsApi.getProducts}
      valueKey={'id'}
      labelKey={'name'}
      placeholder={'Product'}
      productSearch
      isMulti={isMulti}
      isClearable
      {...rest}
    />
  );
};

export default FilterByProducts;
