export const orderProcessSteps = [
  {
    title: 'List',
    description: 'Create a list of products'
  },
  {
    title: 'Your cart',
    description: 'Select offers'
  },
  {
    title: 'Summary',
    description: 'Select shipping and payment options'
  },
  {
    title: 'Place an order',
    description: 'Sending for processing'
  }
];

export const cancelledOrderStatusProgress = [
  {
    title: 'Placed',
    description: 'The order has been created'
  },
  {
    title: 'Canceled',
    description: 'The order has been canceled',
    status: 'error'
  }
];

export const defaultOrderStatusProgress = [
  {
    title: 'Placed',
    description: 'The order has been created'
  },
  {
    title: 'Waiting',
    description: 'Waiting for confirmation'
  },
  {
    title: 'In progress',
    description: 'Suppliers confirm order fulfillment'
  },
  {
    title: 'Completed',
    description: 'The order has been received'
  }
];

export const orderWithAcceptStatusProgress = [
  {
    title: 'Utworzono',
    description: 'Zamówienie zostało stworzone'
  },
  {
    title: 'Akceptacja',
    description: 'Oczekuje na akceptację'
  },
  {
    title: 'Oczekuje',
    description: 'Oczekuje na potwierdzenie'
  },
  {
    title: 'In progress',
    description: 'Dostawcy potwierdzają realizacje zamówień'
  },
  {
    title: 'Zrealizowano',
    description: 'Zamówienie zostało odebrane'
  }
];
