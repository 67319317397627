import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover } from 'antd';
import classnames from 'classnames';

import { Icon, useCompany } from 'components';
import useOutsideClick from 'components/hooks/useOutsideClick';
import UserData from 'components/layout/UserData';

import logoPlaceholder from 'src/assets/img/profile_placeholder.png';
import { imageErrorHandler } from 'src/utils/helpers';

import styles from './User.module.scss';
import shared from 'styles/Shared.module.scss';

const items = [
  {
    title: 'Account',
    url: '/profile',
    icon: 'profile'
  },
  {
    title: 'Log out',
    color: true,
    url: '/logout',
    icon: 'logout'
  }
];

const User = () => {
  const company = useCompany();
  const elementTarget = useRef(null);
  const [isActive, setIsActive] = useOutsideClick(elementTarget);

  const content = (
    <div
      className={styles.body}
      ref={elementTarget}
    >
      <UserData />
      <div className={styles.menu}>
        {items.map((item, index) => (
          <NavLink
            className={classnames(styles.item, {
              [styles.color]: item.color
            })}
            onClick={() => setIsActive(false)}
            activeClassName={styles.active}
            to={item.url}
            key={index}
          >
            {item.icon && (
              <Icon
                name={item.icon}
                size='24'
              />
            )}
            {item.title}
          </NavLink>
        ))}
        <p className={styles.version}>{import.meta.env.VITE_APP_VERSION}</p>
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      placement={'bottomRight'}
      trigger={'click'}
      open={isActive}
    >
      <button
        className={styles.head}
        onClick={() => setIsActive(true)}
      >
        <img
          src={company?.logo_small || logoPlaceholder}
          className={shared.companyLogo}
          alt='Company logo'
          onError={imageErrorHandler}
        />
      </button>
    </Popover>
  );
};

export default User;
