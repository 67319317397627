import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import {
  Accordion,
  ChangeInvoiceStatus,
  Menu,
  Modal,
  OrderOrValuationId,
  SaveButton,
  Tag,
  useIsAdmin,
  useIsMobile,
  useModalConfirm
} from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { InvoicesApi, SupplierApi } from 'src/api';
import { invoiceStatuses } from 'src/constants/enums';
import InvoicePreviewWithProductsList from 'src/features/Invoices/components/InvoicePreviewWithProductsList';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, getInvoiceType } from 'src/utils/helpers';

import InvoiceForm from '../InvoiceForm';

import style from './InvoiceThumbnail.module.scss';

const InvoiceThumbnail = (props) => {
  const {
    number,
    payment_date,
    sale_date,
    order_public_id,
    sum,
    supplier_name,
    supplier_id,
    type,
    status,
    file_name,
    order_uid,
    item,
    refreshCallback,
    company_id,
    suborder_uid,
    forSupplier,
    inOrderView,
    company_name,
    created_at,
    comment,
    id,
    small,
    children,
    products,
    supplierView = false
  } = props;

  const isMobile = useIsMobile();
  const isAdmin = useIsAdmin();
  const isDateError = useMemo(() => new Date(payment_date) <= new Date() && status !== invoiceStatuses.COMPLETED, [payment_date]);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const [fileUrl, setFileUrl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const saveStatus = async (status) => {
    const editedInvoice = {
      suborder_uid,
      supplier_id,
      status,
      id
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(editedInvoice)) formData.append(key, value);
    const apiCallback = suborder_uid ? InvoicesApi.editUserInvoice : InvoicesApi.editUserInvoiceOther;

    try {
      await apiCallback(formData);
      await refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const downloadFile = (url, onlyFileView) => {
    const link = document.createElement('a');

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    if (!onlyFileView) {
      link.setAttribute('download', file_name);
    }

    document.body.appendChild(link);
    link.click();
  };

  const downloadInvoice = async (onlyFileView = false) => {
    const params = { suborder_uid, file_name, supplier_id, company_id };

    const apiCallback = forSupplier
      ? SupplierApi.downloadInvoice
      : order_public_id
      ? InvoicesApi.downloadInvoice
      : InvoicesApi.downloadInvoiceOther;

    if (fileUrl) {
      downloadFile(fileUrl, onlyFileView);
      return;
    }

    try {
      const { data, headers } = await apiCallback(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      setFileUrl(url);
      downloadFile(url, onlyFileView);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteInvoice = async () => {
    const params = { suborder_uid, file_name, supplier_id, company_id };
    const apiCallback = order_public_id ? InvoicesApi.deleteUserInvoice : InvoicesApi.deleteUserInvoiceOther;
    try {
      await apiCallback(params);
      refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteInvoiceHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć fakturę?',
      handleConfirm: deleteInvoice,
      confirmButtonName: 'Tak',
      cancelButtonName: 'Nie'
    });
  };

  const deleteInvoiceAsSupplier = async () => {
    const params = { suborder_uid, file_name, supplier_id, company_id };

    try {
      await SupplierApi.deleteSupplierInvoice(params);
      refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteInvoiceAsSupplierHandler = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć fakturę?',
      handleConfirm: deleteInvoiceAsSupplier,
      confirmButtonName: 'Tak',
      cancelButtonName: 'Nie'
    });
  };

  const actions = [
    {
      title: 'Edit',
      icon: 'edit',
      action: () => setVisible(true)
    },
    {
      title: 'Change status',
      icon: 'edit',
      action: () => setTimeout(() => setIsActive(true), 10)
    },
    {
      title: 'Open the file in a new tab',
      icon: 'search',
      action: () => downloadInvoice(true)
    },
    {
      title: 'Download file',
      icon: 'download',
      action: downloadInvoice,
      color: 'blue'
    },
    {
      title: 'Delete invoice',
      icon: 'trash',
      action: deleteInvoiceHandler,
      color: 'red'
    }
  ];

  const supplierActions = [
    {
      title: 'Edit',
      icon: 'edit',
      action: () => setVisible(true)
    },
    {
      title: 'Delete invoice',
      icon: 'trash',
      action: deleteInvoiceAsSupplierHandler,
      color: 'red'
    }
  ];

  const allowToFilePreview = !!products && isAdmin;

  const thumbnail = (
    <div className={cn(style.container, { [style.inOrder]: inOrderView, [style.small]: small })}>
      {!!children && <div className={style.checkboxWrapper}>{children}</div>}
      <div className={cn(style.wrapper, style.mobileRow)}>
        {getInvoiceType({ type, className: style.tag })}
        {(isMobile || small) && !supplierView && (
          <div className={style.wrapper}>
            <ChangeInvoiceStatus
              currentStatus={status}
              suborderUid={suborder_uid}
              onSave={saveStatus}
              isActive={isActive}
              disabled={supplierView}
              setIsActive={setIsActive}
              displayOver
            />
          </div>
        )}
      </div>
      {!inOrderView && (
        <>
          <div className={style.wrapper}>
            <p className={style.label}>Supplier:</p>
            <p className={style.text}>{supplier_name}</p>
          </div>
          <div className={style.wrapper}>
            <p className={style.label}>Branch:</p>
            <p
              title={company_name}
              className={cn(style.text, style.company)}
            >
              {company_name}
            </p>
          </div>
        </>
      )}
      <div className={style.wrapper}>
        <p className={style.label}>Invoice number:</p>
        {!supplierView && inOrderView ? (
          <Link to={`/invoices?search=${number}`}>
            <p
              className={cn(style.text, style.invoiceNumber)}
              title={number}
            >
              {number}
            </p>
          </Link>
        ) : (
          <p
            className={cn(style.text, style.invoiceNumber)}
            title={number}
          >
            {number}
          </p>
        )}
      </div>
      <div className={style.wrapper}>
        <p className={style.label}>Total:</p>
        <p className={style.text}>{getFormattedAmount(sum)}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.label}>Sale date:</p>
        {<p className={style.text}>{sale_date ? toDateNumber(sale_date) : '-'}</p>}
      </div>
      {!(isMobile || small) && !supplierView && (
        <div className={style.wrapper}>
          <ChangeInvoiceStatus
            currentStatus={status}
            suborderUid={suborder_uid}
            onSave={saveStatus}
            isActive={isActive}
            setIsActive={setIsActive}
            disabled={supplierView}
            displayOver
          />
        </div>
      )}
      {inOrderView ? (
        <div className={cn(style.wrapper, style.row)}>
          {allowToFilePreview && (
            <a
              style={{ marginRight: 6 }}
              onClick={() => setIsPreviewVisible(true)}
            >
              Porównaj z zamówieniem
            </a>
          )}
          <SaveButton
            onClick={downloadInvoice}
            type={'download'}
          />
          <SaveButton
            onClick={() => downloadInvoice(true)}
            type={'search'}
          />
          {supplierView && (
            <Menu
              actions={supplierActions}
              className={style.menu}
            />
          )}
        </div>
      ) : (
        <Menu
          actions={actions}
          className={style.menu}
          displayOver
        />
      )}
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        title={'Edytuj fakturę'}
      >
        <InvoiceForm
          refreshDataCallback={refreshCallback}
          supplierView={supplierView}
          closeModal={() => setVisible(false)}
          suborderUid={suborder_uid}
          invoiceData={item}
        />
      </Modal>
      {allowToFilePreview && (
        <Modal
          visible={isPreviewVisible}
          onClose={() => setIsPreviewVisible(false)}
          title={'Porównaj fakturę z listą produktów'}
        >
          <InvoicePreviewWithProductsList
            products={products}
            supplier_id={supplier_id}
            {...props}
          />
        </Modal>
      )}
      {renderedModalConfirm}
    </div>
  );

  if (inOrderView || small) {
    return thumbnail;
  }

  return (
    <Accordion
      title={thumbnail}
      defaultWrapped
      noPadding
      openOnArrowClick
    >
      <div className={style.contentWrapper}>
        <span />
        <div className={style.wrapper}>
          <p className={style.label}>Order number:</p>
          {order_public_id ? (
            <Link to={`/orders/${order_uid}`}>
              <OrderOrValuationId id={order_public_id} />
            </Link>
          ) : (
            <Tag value={'Brak'} />
          )}
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>Comment:</p>
          <p
            title={comment}
            className={cn(style.text, style.comment)}
          >
            {comment || '-'}
          </p>
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>File name:</p>
          <p
            title={file_name}
            className={cn(style.text, style.filename)}
          >
            {file_name}
          </p>
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>Added date:</p>
          {created_at && <p className={style.text}>{toDateNumber(created_at)}</p>}
        </div>
        <div className={style.wrapper}>
          <p className={style.label}>Payment date:</p>
          <p className={cn(style.text, { [style.error]: isDateError })}>{payment_date}</p>
        </div>
      </div>
    </Accordion>
  );
};

export default InvoiceThumbnail;
