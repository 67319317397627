import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { AsyncSelect, Button, Input, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi, ListsApi } from 'src/api';

import style from './MergeLists.module.scss';

const MergeLists = ({ closeModal }) => {
  const [listName, setListName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const validator = useValidator();
  const history = useHistory();
  const isAdmin = useIsAdmin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryData = {
      title: listName,
      lists_uid: selectedLists.map((l) => l.uid),
      ...(isAdmin && { company_id: selectedCompanyId.value })
    };

    try {
      setIsLoading(true);
      const { data } = await ListsApi.mergeLists(queryData);
      notifyCommon([`The list ${listName} has been created!`]);
      closeModal();
      history.push(`/lists/${data}`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      {isAdmin && (
        <AsyncSelect
          label={'Firma - właściciel'}
          apiCallback={AdminApi.getCompanies}
          queryParams={{ show_deactivated: 0 }}
          onChange={setSelectedCompanyId}
          value={selectedCompanyId}
          placeholder={'Firma'}
          labelKey={'name'}
          validator={validator}
          name={'company-select'}
          id={'company-select'}
          rule={'required'}
          valueKey={'id'}
          isClearable
        />
      )}
      <AsyncSelect
        label={'The lists you want to merge'}
        placeholder={'Select a lists...'}
        labelFormat={(list) => list.name}
        apiCallback={ListsApi.getLists}
        onChange={setSelectedLists}
        value={selectedLists}
        validator={validator}
        name={'list-select'}
        id={'list-select'}
        rule={'required'}
        valueKey={'uid'}
        isMulti
      />
      <Input
        id={'list-name'}
        name={'list-name'}
        label={'The name of the new list'}
        placeholder={'Orthodontics list'}
        value={listName}
        onChange={(e) => setListName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Create list'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default MergeLists;
