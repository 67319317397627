import React from 'react';

import styles from './AnalyticsSummaryTable.module.scss';

const AnalyticsSummaryTable = ({
  all_products,
  products_only_with_ref_price,
  products_only_with_volume,
  products_with_ref_and_volume,
  products_without_ref_and_volume
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.tableHeader}>
        <p className={styles.title}>All products</p>
        <p className={styles.title}>Products with ref. price</p>
        <p className={styles.title}>Products with volume</p>
        <p className={styles.title}>Products with ref. price and volume</p>
        <p className={styles.title}>Products without ref. price and volume</p>
      </div>
      <div className={styles.table}>
        <p>{all_products}</p>
        <p>{products_only_with_ref_price}</p>
        <p>{products_only_with_volume}</p>
        <p>{products_with_ref_and_volume}</p>
        <p>{products_without_ref_and_volume}</p>
      </div>
    </div>
  );
};

export default AnalyticsSummaryTable;
