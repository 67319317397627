import React from 'react';
import classNames from 'classnames';

import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './CompanyDetails.module.scss';

const CompanyDetails = ({ company, areDataHidden }) => {
  const { name, company_name, nip, phone, address } = company || {};
  const { apartment_number, building_number, zip_code, street, city } = address || {};

  return (
    <div className={classNames(style.container, { [style.blurred]: areDataHidden })}>
      <div className={style.textWrapper}>
        <p className={style.label}>Company name</p>
        <p className={style.text}>{name || company_name}</p>
      </div>
      <div className={style.textWrapper}>
        <p className={style.label}>Address</p>
        <span>
          <p className={style.text}>
            ul. {street} {building_number}
            {apartment_number ? `/${apartment_number}` : null}
            {zip_code ? `, ${zip_code}` : null} {city}
          </p>
        </span>
      </div>
      <div className={style.textWrapper}>
        <p className={style.label}>NIP</p>
        <p className={style.text}>{nip}</p>
      </div>
      <div className={style.textWrapper}>
        <p className={style.label}>Phone</p>
        <p className={style.text}>{getFormattedPhoneNumber(phone)}</p>
      </div>
    </div>
  );
};

export default CompanyDetails;
