import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Menu, Modal, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ExpensesApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import { refreshExpenses } from '../../actions';
import AddInvoiceForm from '../AddInvoiceForm';

import styles from './ExpenseItem.module.scss';

const ExpenseItem = ({ item, expenseId, refreshCallback }) => {
  const dispatch = useDispatch();

  const [editInvoiceVisible, setEditInvoiceVisible] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [fileUrl, setFileUrl] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const { comment, files, id, name, payment_date, price, nip, company_name } = item;

  const invoiceData = {
    id: id,
    nip: nip,
    name: name,
    price: price,
    payment_date: payment_date,
    comment: comment,
    existing_files: files,
    files: []
  };

  const actions = [
    {
      title: 'Download document',
      icon: 'download',
      action: () => openExpensePaymentFile(files[0], true, true),
      disabled: files.length > 0 ? false : true
    },
    {
      title: 'Edit payment',
      icon: 'edit',
      action: () => setEditInvoiceVisible(true)
    },
    {
      title: 'Delete payment',
      icon: 'trash',
      action: () => deleteExpensePaymentConfirm(),
      color: 'red'
    }
  ];

  const deleteExpensePaymentHandler = async () => {
    const params = {
      id: id
    };

    try {
      await ExpensesApi.deleteUserExpensePayment(params);
      notifyCommon(['Płatność usunięta.']);
      if (refreshCallback) refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteExpensePaymentConfirm = () => {
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć płatność?',
      handleConfirm: deleteExpensePaymentHandler,
      confirmButtonName: 'Tak',
      cancelButtonName: 'Nie'
    });
  };

  const handleViewExpensePaymentFile = (url, download = false) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');

    if (download) {
      link.setAttribute('download', files[0].file_name);
    }

    document.body.appendChild(link);
    link.click();
  };

  const openExpensePaymentFile = async (file, download = false, actionFromSubmenu = false) => {
    !actionFromSubmenu && setIsDownloading(true);

    const params = {
      expense_id: expenseId,
      expense_payment_id: id,
      expense_payment_file_id: file.id,
      file_name: file.file_name
    };

    if (fileUrl && download) {
      handleViewExpensePaymentFile(fileUrl, true);
      setIsDownloading(false);
      return;
    }

    try {
      const { data, headers } = await ExpensesApi.downloadExpensePaymentFile(params);
      const url = window.URL.createObjectURL(new Blob([data], { type: headers['content-type'] }));
      setFileUrl(url);

      if (download) {
        handleViewExpensePaymentFile(url, true);
      } else {
        handleViewExpensePaymentFile(url);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsDownloading(false);
    }
  };

  const saveEditExpensePaymentHandler = () => {
    setEditInvoiceVisible(false);
    dispatch(refreshExpenses());
  };

  return (
    <>
      <div
        className={classNames(styles.expense, [
          isDownloading && styles.expensePaymentWrapperLoading,
          company_name && styles.withCompanyName
        ])}
      >
        <div className={styles.wrapper}>
          <p className={styles.text}>
            <span className={styles.mobileLabel}>Name</span>
            {name}
          </p>
        </div>
        {company_name && (
          <div className={styles.wrapper}>
            <p className={styles.text}>
              <span className={styles.mobileLabel}>Branch</span>
              <span
                className={styles.textOverflow}
                title={company_name}
              >
                {company_name}
              </span>
            </p>
          </div>
        )}
        <div className={styles.wrapper}>
          <p className={styles.text}>
            <span className={styles.mobileLabel}>Value</span>
            {getFormattedAmount(price)}
          </p>
        </div>
        <div className={styles.wrapper}>
          <p className={styles.text}>
            <span className={styles.mobileLabel}>Payment date</span>
            {payment_date}
          </p>
        </div>
        <div className={styles.wrapper}>
          <span className={styles.mobileLabel}>Document</span>
          {files?.length > 0 ? (
            files.map((file) => (
              <a
                className={styles.text}
                key={file.id}
                onClick={() => openExpensePaymentFile(file, false)}
              >
                {file.file_name}
              </a>
            ))
          ) : (
            <span>-</span>
          )}
        </div>
        <div className={styles.wrapper}>
          <p className={styles.text}>
            <span className={styles.mobileLabel}>Comment</span>
            {comment || '-'}
          </p>
        </div>
        <div className={styles.wrapper}>
          <Menu
            actions={actions}
            className={styles.menu}
            displayOver
          />
        </div>
      </div>
      <Modal
        visible={editInvoiceVisible}
        onClose={() => setEditInvoiceVisible(false)}
        title={'Edit payment'}
      >
        <AddInvoiceForm
          invoiceData={invoiceData}
          closeModal={saveEditExpensePaymentHandler}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default ExpenseItem;
