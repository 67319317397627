export const priceTypes = {
  netto: 'net',
  brutto: 'gross'
};

export const availabilities = {
  available: 'AVAILABLE',
  availableIn: 'AVAILABLE_IN',
  availableSince: 'AVAILABLE_AFTER',
  notAvailable: 'UNAVAILABLE'
};

export const valuationStatuses = {
  pending: 'PENDING',
  sent: 'SENT',
  cancelled: 'CANCELLED',
  expired: 'EXPIRED'
};

export const orderStatuses = {
  draft: 'DRAFT',
  waiting_for_approval: 'WAITING_FOR_APPROVAL',
  ordered: 'ORDERED',
  pending: 'PENDING',
  processing: 'PROCESSING',
  sent: 'SENT',
  completed: 'COMPLETED',
  cancelled: 'CANCELLED'
};

export const productStatuses = {
  draft: 'DRAFT',
  waiting_for_approval: 'WAITING_FOR_APPROVAL',
  pending: 'PENDING',
  processing: 'PROCESSING',
  sent: 'SENT',
  completed: 'COMPLETED',
  complaint: 'COMPLAINT',
  refunded: 'REFUNDED',
  cancelled: 'CANCELLED'
};

export const orderApprovalStatuses = {
  waiting_for_approval: 'WAITING_FOR_APPROVAL',
  declined: 'DECLINED',
  approved: 'APPROVED'
};

export const userRoles = {
  user: 'user',
  admin: 'admin',
  supplier: 'supplier',
  distributor: 'distributor'
};

export const productBaseTypes = {
  user: 'user',
  supplier: 'supplier'
};

export const valuationsTypes = {
  supplier: 'supplier-valuations',
  user: 'user-valuations'
};

export const orderTypes = {
  supplier: 'supplier',
  distributor: 'distributor',
  user: 'user'
};

export const orderProductTypes = {
  supplier: 'supplier',
  user: 'user'
};

export const productTypes = {
  product: 'PRODUCT',
  replacement: 'REPLACEMENT',
  offer: 'OFFER'
};

export const valuationTypes = {
  horizontal: 'HORIZONTAL',
  vertical: 'VERTICAL'
};

export const userPermissions = {
  lists: {
    read: 'lists.read',
    create: 'lists.create',
    delete: 'lists.delete'
  },
  valuations: {
    read: 'valuations.read',
    create: 'valuations.create',
    cancel: 'valuations.cancel'
  },
  orders: {
    read: 'orders.read',
    create: 'orders.create',
    cancel: 'orders.cancel'
  },
  company: {
    admin: 'company.admin'
  },
  invoices: {
    read: 'invoices.read',
    write: 'invoices.write',
    delete: 'invoices.delete'
  },
  products: {
    read: 'products.read',
    write: 'products.write',
    delete: 'products.delete'
  },
  suppliers: {
    read: 'suppliers.read',
    write: 'suppliers.write',
    delete: 'suppliers.delete'
  },
  warehouse: {
    read: 'warehouse.read',
    write: 'warehouse.write',
    delete: 'warehouse.delete'
  },
  analytics: {
    read: 'analytics.read'
  },
  offer_catalog: {
    read: 'offer_catalog.read',
    menage: 'offer_catalog.manage'
  }
};

export const userPermissionsList = [
  'lists.create',
  'lists.read',
  'lists.delete',
  'valuations.read',
  'valuations.create',
  'valuations.cancel',
  'orders.create',
  'orders.read',
  'orders.cancel',
  'analytics.read',
  'warehouse.read',
  'invoices.read',
  'invoices.write',
  'invoices.delete',
  'products.read',
  'products.write',
  'products.delete',
  'suppliers.read',
  'suppliers.write',
  'suppliers.delete',
  'warehouse.write',
  'warehouse.delete',
  'offer_catalog.read',
  'offer_catalog.manage',
  'company.admin'
];

export const supplierTypes = {
  primary: 'first',
  secondary: 'second',
  third: 'third',
  normal: 'normal'
};

export const orderEvents = {
  STATUS_CHANGED_TO_DRAFT: 'STATUS_CHANGED_TO_DRAFT',
  STATUS_CHANGED_TO_WAITING_FOR_APPROVAL: 'STATUS_CHANGED_TO_WAITING_FOR_APPROVAL',
  ORDER_APPROVED: 'ORDER_APPROVED',
  ORDER_DECLINED: 'ORDER_DECLINED',
  STATUS_CHANGED_TO_CANCELLED: 'STATUS_CHANGED_TO_CANCELLED', //Zamówienie zostało anulowane
  STATUS_LINK_CHANGED_TO_CANCELLED: 'STATUS_LINK_CHANGED_TO_CANCELLED', //Zamówienie zostało anulowane
  ORDER_CANCELLED_BY_SUPPLIER: 'ORDER_CANCELLED_BY_SUPPLIER', //Zamówienie zostało anulowane
  STATUS_CHANGED_TO_PENDING: 'STATUS_CHANGED_TO_PENDING', //Zamówienie zostało przyjęte do realizacji. Realizowane jest 5/10 produktów [pending] (informacja ile produktów na ile poszło do realizacji)
  STATUS_CHANGED_TO_PROCESSING: 'STATUS_CHANGED_TO_PROCESSING', //Zamówienie zostało złożone
  STATUS_CHANGED_TO_SENT: 'STATUS_CHANGED_TO_SENT', //Zamówienie zostało wysłane [sent]
  STATUS_CHANGED_TO_COMPLETED: 'STATUS_CHANGED_TO_COMPLETED', //Zamówienie zostało odebrane
  PRODUCT_PARTIAL_RECEIVED: 'PRODUCT_PARTIAL_RECEIVED', //Odebrano produkt częściowo
  SUPPLIER_OPENED_NEW_ORDER_EMAIL: 'SUPPLIER_OPENED_NEW_ORDER_EMAIL', //Dostawca otworzył email z zamówieniem
  SUPPLIER_REOPENED_NEW_ORDER_EMAIL: 'SUPPLIER_REOPENED_NEW_ORDER_EMAIL', //Dostawca otworzył ponownie email z zamówieniem
  PRODUCT_PRICE_CHANGED: 'PRODUCT_PRICE_CHANGED', //Cena produktu (nazwa) została zmieniona z (stara cena) na (nowa cena)
  PRODUCT_PRICE_CHANGED_BY_ADMIN: 'PRODUCT_PRICE_CHANGED_BY_ADMIN', //Cena produktu (nazwa) została zmieniona z (stara cena) na (nowa cena) przez admina
  PRODUCT_QUANTITY_CHANGE: 'PRODUCT_QUANTITY_CHANGE', //Liczba produktu (nazwa) została zmieniona z (stara ilość) na (nowa ilość)
  USER_SEND_MESSAGE: 'USER_SEND_MESSAGE', //Użytkownik (nazwa) wysłał wiadomość (wiadomość)
  SUPPLIER_SEND_MESSAGE: 'SUPPLIER_SEND_MESSAGE', //Dostawca (nazwa) wysłał wiadomość (wiadomość)
  USER_ADDED_ATTACHMENT: 'USER_ADDED_ATTACHMENT', //Użytkownik (nazwa) dodał załącznik (nazwa pliku)
  USER_DELETED_ATTACHMENT: 'USER_DELETED_ATTACHMENT', //Użytkownik (nazwa) usunął załącznik (nazwa pliku)
  SUPPLIER_ADDED_ATTACHMENT: 'SUPPLIER_ADDED_ATTACHMENT', //Dostawca (nazwa) dodał załącznik (nazwa pliku)
  SUPPLIER_DELETED_ATTACHMENT: 'SUPPLIER_DELETED_ATTACHMENT', //Dostawca (nazwa) usunął załącznik (nazwa pliku)
  SUPPLIER_ADDED_INVOICE: 'SUPPLIER_ADDED_INVOICE', //Dostawca załączył fakturę do zamówienia
  PRODUCT_REFUNDED: 'PRODUCT_REFUNDED',
  PRODUCT_CANCELLED: 'PRODUCT_CANCELLED',
  PRODUCT_COMPLAINT: 'PRODUCT_COMPLAINT',
  PRODUCT_COMPLETED: 'PRODUCT_COMPLETED',
  PRODUCT_CANCELLED_BY_USER: 'PRODUCT_CANCELLED_BY_USER', //Produkt/produkty (ilość) został/y anulowany
  PRODUCT_COMPLETED_BY_USER: 'PRODUCT_COMPLETED_BY_USER',
  PRODUCT_REFUNDED_BY_USER: 'PRODUCT_REFUNDED_BY_USER',
  PRODUCT_COMPLAINT_BY_USER: 'PRODUCT_COMPLAINT_BY_USER',
  PRODUCT_COMPLAINT_BY_USER_REVERSE: 'PRODUCT_COMPLAINT_BY_USER_REVERSE',
  PRODUCT_PENDING_BY_USER_REVERSE: 'PRODUCT_PENDING_BY_USER_REVERSE',
  PRODUCT_PROCESSING_BY_USER_REVERSE: 'PRODUCT_PROCESSING_BY_USER_REVERSE',
  PRODUCT_SENT_BY_USER_REVERSE: 'PRODUCT_SENT_BY_USER_REVERSE'
};

export const listEvents = {
  USER_CREATED_LIST: 'USER_CREATED_LIST',
  USER_EDITED_LIST_COMMENT: 'USER_EDITED_LIST_COMMENT',
  USER_ADDED_PRODUCT_TO_LIST: 'USER_ADDED_PRODUCT_TO_LIST',
  USER_DELETED_PRODUCT_FROM_LIST: 'USER_DELETED_PRODUCT_FROM_LIST',
  USER_CHANGED_LIST_PRODUCT_QUANTITY: 'USER_CHANGED_LIST_PRODUCT_QUANTITY',
  USER_SENT_LIST_TO_VALUATION: 'USER_SENT_LIST_TO_VALUATION',
  USER_PLACED_ORDER_FROM_LIST: 'USER_PLACED_ORDER_FROM_LIST',
  USER_EDITED_LIST_PRODUCT_COMMENT: 'USER_EDITED_LIST_PRODUCT_COMMENT',
  USER_MERGED_LIST: 'USER_MERGED_LIST'
};
export const invoiceEvents = {
  USER_ADDED_INVOICE: 'USER_ADDED_INVOICE',
  USER_EDITED_INVOICE: 'USER_EDITED_INVOICE',
  USER_DELETED_INVOICE: 'USER_DELETED_INVOICE',
  USER_CHANGED_INVOICE_STATUS: 'USER_CHANGED_INVOICE_STATUS',
  USER_ADDED_INVOICE_OTHER: 'USER_ADDED_INVOICE_OTHER',
  USER_CHANGED_INVOICE_OTHER_STATUS: 'USER_CHANGED_INVOICE_OTHER_STATUS',
  USER_EDITED_INVOICE_OTHER: 'USER_EDITED_INVOICE_OTHER',
  USER_DELETED_INVOICE_OTHER: 'USER_DELETED_INVOICE_OTHER'
};
export const expensesEvents = {
  NEW_EXPENSE: 'NEW_EXPENSE',
  EDITED_EXPENSE: 'EDITED_EXPENSE',
  DELETED_EXPENSE: 'DELETED_EXPENSE',
  NEW_EXPENSE_FILE: 'NEW_EXPENSE_FILE',
  DELETED_EXPENSE_FILE: 'DELETED_EXPENSE_FILE',
  NEW_EXPENSE_PAYMENT: 'NEW_EXPENSE_PAYMENT',
  EDITED_EXPENSE_PAYMENT: 'EDITED_EXPENSE_PAYMENT',
  DELETED_EXPENSE_PAYMENT: 'DELETED_EXPENSE_PAYMENT',
  NEW_EXPENSE_PAYMENT_FILE: 'NEW_EXPENSE_PAYMENT_FILE',
  DELETED_EXPENSE_PAYMENT_FILE: 'DELETED_EXPENSE_PAYMENT_FILE'
};
export const warehouseEvents = {
  ORDER_SUPPLY: 'ORDER_SUPPLY',
  MANUAL_SUPPLY: 'MANUAL_SUPPLY',
  INVENTORY_SUPPLY: 'INVENTORY_SUPPLY',
  MANUAL_RELEASE: 'MANUAL_RELEASE',
  INVENTORY_RELEASE: 'INVENTORY_RELEASE'
};

export const tagsEvents = {
  NEW_TAG: 'NEW_TAG',
  EDITED_TAG: 'EDITED_TAG',
  DELETED_TAG: 'DELETED_TAG'
};

export const attachmentType = {
  file: 'file',
  invoice: 'invoice'
};

export const invoiceStatuses = {
  TO_PAY: 'TO_PAY',
  COMPLETED: 'COMPLETED',
  DRAFT: 'DRAFT'
};

export const OCRInvoiceStatuses = {
  processing: 'PROCESSING',
  parsed: 'PARSED',
  added: 'ADDED',
  error: 'ERROR'
};

export const expensesPaymentMethods = {
  CARD: 'Karta',
  TRANSFER: 'Przelew',
  CASH: 'Gotówka'
};
export const expensesBillingPeriods = {
  MONTHLY: 'Miesięczny',
  BIMONTHLY: 'Dwumiesięczny',
  QUARTERLY: 'Kwartalny',
  HALFYEARLY: 'Półroczny',
  YEARLY: 'Roczny'
};

export const OfferCatalogTypes = {
  cart: 'CART',
  catalog: 'CATALOG'
};

export const invoiceTypes = {
  invoice: 'INVOICE',
  correction: 'CORRECTION',
  wz: 'WZ',
  other: 'OTHER'
};

export const NotificationTypes = {
  PricingCompleted: 'App\\Notifications\\PricingCompleted',
  OrderAccepted: 'App\\Notifications\\OrderAccepted',
  OrderCompleted: 'App\\Notifications\\OrderCompleted',
  PricingCreated: 'App\\Notifications\\PricingCreated',
  OrderCreated: 'App\\Notifications\\OrderCreated',
  OrderCancelledBySupplier: 'App\\Notifications\\OrderCancelledBySupplier',
  OrderCancelled: 'App\\Notifications\\OrderCancelled',
  MessageSent: 'App\\Notifications\\MessageSent',
  MessageSentToSupplier: 'App\\Notifications\\MessageSentToSupplier',
  PriceChanged: 'App\\Notifications\\PriceChanged'
};

export const AddedPriceTypes = {
  adminValuation: 'ADMIN_VALUATION',
  supplierValuation: 'SUPPLIER_VALUATION',
  userAcceptNewPrice: 'USER_ACCEPT_NEW_PRICE',
  supplierOrder: 'SUPPLIER_ORDER',
  adminAcceptNewPrice: 'ADMIN_ACCEPT_NEW_PRICE',
  adminImportCatalogPrice: 'ADMIN_IMPORT_CATALOG_PRICE',
  adminClientLoginImportCatalogPrice: 'ADMIN_CLIENT_LOGIN_IMPORT_CATALOG_PRICE',
  userImportCatalogPrice: 'USER_IMPORT_CATALOG_PRICE',
  userAddPriceOfc: 'USER_ADD_PRICE_OFC',
  adminAddPriceOfc: 'ADMIN_ADD_PRICE_OFC',
  adminClientLoginAddPriceOfc: 'ADMIN_CLIENT_LOGIN_ADD_PRICE_OFC'
};

export const supplyTypes = {
  orderSupply: 'ORDER_SUPPLY',
  manualSupply: 'MANUAL_SUPPLY',
  inventorySupply: 'INVENTORY_SUPPLY'
};

export const releaseTypes = {
  manualRelease: 'MANUAL_RELEASE',
  inventoryRelease: 'INVENTORY_RELEASE'
};
