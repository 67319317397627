import React, { useMemo } from 'react';

import { Modal } from 'components';

import { modalTypes } from '../../index';
import ProductRelease from '../ProductRelease';
import ProductSupply from '../ProductSupply';
import WarehouseAddProduct from '../WarehouseAddProduct';

const ProductModal = ({ isOpen, setIsOpen, modalType, refreshData }) => {
  const modalContent = useMemo(() => {
    switch (modalType) {
      case modalTypes.addNew:
        return {
          title: 'Add product',
          content: (
            <WarehouseAddProduct
              refreshData={refreshData}
              closeModal={() => setIsOpen(false)}
            />
          )
        };
      case modalTypes.increase:
        return {
          title: 'Delivery to warehouse',
          content: (
            <ProductSupply
              closeModal={() => setIsOpen(false)}
              refreshData={refreshData}
            />
          )
        };
      case 'decrease':
        return {
          title: 'Release from warehouse',
          content: (
            <ProductRelease
              closeModal={() => setIsOpen(false)}
              refreshData={refreshData}
            />
          )
        };
      default:
        return { title: '', content: null };
    }
  }, [modalType]);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      visible={isOpen}
      onClose={handleCloseModal}
      title={modalContent.title}
    >
      {modalContent.content}
    </Modal>
  );
};

export default ProductModal;
