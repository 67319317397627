import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import { Menu } from 'components';

import style from './Section.module.scss';

const Section = ({ title, link, href, children, id, className, actions }) => {
  return (
    <div
      className={cn(style.container, className)}
      id={id}
    >
      <div className={style.header}>
        {title && <h2 className={style.title}>{title}</h2>}
        {link && (
          <Link
            to={link}
            className={style.link}
          >
            View all
          </Link>
        )}
        {href && (
          <a
            href={href}
            target={'_blank'}
            rel={'noreferrer'}
            className={style.link}
          >
            Zobacz wszystkie
          </a>
        )}
        {actions && (
          <Menu
            actions={actions}
            className={style.menu}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default Section;
