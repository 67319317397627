import React from 'react';

import { ReactComponent as LogoAvalio } from 'assets/img/logo-avalio.svg';

import { KATE_PHONE_NUMBER, KATE_PHONE_NUMBER_FORMATTED } from 'src/constants/misc';

import style from './PublicHeader.module.scss';

const PublicHeader = () => {
  return (
    <div className={style.container}>
      <LogoAvalio className={style.logo} />
      <div className={style.phone}>
        <span className={style.phoneLabel}>You need help?</span>
        <a
          className={style.link}
          href={`tel:${KATE_PHONE_NUMBER}`}
        >
          {KATE_PHONE_NUMBER_FORMATTED}
        </a>
      </div>
    </div>
  );
};

export default PublicHeader;
