import React from 'react';
import classNames from 'classnames';

import { AdditionalShipping, Card, Tag } from 'components';

import Accordion from 'src/components/layout/Accordion';
import { orderStatuses } from 'src/constants/enums';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './Summary.module.scss';
import shared from 'styles/Shared.module.scss';

const Summary = ({ sup }) => {
  const additionalShippingPrice = sup?.additional_shipping_price || 0;

  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Summary</h3>;

  if (sup.status && sup.status === orderStatuses.cancelled) {
    return (
      <Accordion
        section
        title={title}
        className={shared.tableCategory}
        defaultWrapped
      >
        <Card className={style.container}>
          <h2 className={style.cancelledTitle}>Zamówienie zostało anulowane</h2>
        </Card>
      </Accordion>
    );
  }

  return (
    <Accordion
      section
      title={title}
      className={classNames(shared.tableCategory, style.table)}
    >
      <div className={style.container}>
        <p className={style.sumText}>Payment method:</p>
        <p className={style.sumAmount}>{sup?.pay_method ? sup?.pay_method : <Tag value={'None'} />}</p>
      </div>
      <div className={style.container}>
        <p className={style.sumText}>Shipping method</p>
        <p className={style.sumAmount}>{sup?.ship_method ? sup?.ship_method : <Tag value={'None'} />}</p>
      </div>
      <div className={style.container}>
        <p className={style.sumText}>Products value:</p>
        <p className={style.sumAmount}>{getFormattedAmount(sup.amount)}</p>
      </div>
      <div className={style.container}>
        <p className={style.sumText}>Shipping costs:</p>
        <p className={style.sumAmount}>{getFormattedAmount(sup.shipping_price)}</p>
      </div>
      {additionalShippingPrice !== 0 && (
        <div className={style.container}>
          <p className={style.sumText}>Additional shipping costs</p>
          <p className={classNames(style.sumAmount, style.gapRemove)}>
            <AdditionalShipping
              price={additionalShippingPrice}
              comment={sup?.supplier_comment}
              tooltipFirst
              noPlus
            />
          </p>
        </div>
      )}
      <div className={style.container}>
        <p className={classNames(style.sumText, style.final)}>Order total:</p>
        <p className={classNames(style.sumAmount, style.final)}>
          {getFormattedAmount(sup.shipping_price + additionalShippingPrice + sup.amount)}
        </p>
      </div>
    </Accordion>
  );
};

export default Summary;
