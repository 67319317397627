import React, { useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

import { Menu, Modal, Tag, useAuthUser, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, CompanyApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import NewMemberForm from 'src/features/Profile/features/MembersManagement/components/MemberForm/components/NewMemberForm';
import { toDateNumber } from 'src/utils/dateTime';

import style from '../thumbnails.module.scss';

export const MemberSkeleton = () => {
  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <Skeleton
          width={100}
          height={10}
        />
      </div>
      <div className={style.wrapper}>
        <Skeleton
          width={140}
          height={10}
        />
      </div>
      <div className={style.wrapper} />
    </div>
  );
};

const MemberThumbnail = ({ member, isOwner, companyId, refreshCallback }) => {
  const user = useAuthUser();
  const { name, email, id, position, created_at, welcome_email_sent_at } = member || {};
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [isOpen, setIsOpen] = useState();

  const ApiClass = useMemo(() => (user?.role === userRoles.admin ? AdminApi : CompanyApi), [user]);

  const handleDeleteFromCompany = async () => {
    const params = {
      email,
      ...(user.role === userRoles.admin && {
        user_id: id,
        company_id: companyId
      })
    };
    try {
      await ApiClass.deleteCompanyMember(params);
      if (refreshCallback) await refreshCallback();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const sentWelcomeMessage = async () => {
    try {
      await CompanyApi.sendWelcomeMessage(id);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const actions = [
    {
      title: welcome_email_sent_at
        ? `Wyślij wiad. powitalną (wysłano ${toDateNumber(welcome_email_sent_at)})`
        : 'Wyślij wiad. powitalną',
      icon: 'send',
      action: sentWelcomeMessage
    },
    {
      title: 'Edit',
      icon: 'edit',
      color: 'blue',
      action: () => setIsOpen(true)
    },
    {
      title: 'Usuń członka z oddziału',
      icon: 'close',
      color: 'red',
      action: () =>
        handleOpenModalConfirm({
          message: `Czy na pewno chcesz usunąć użytkownika ${name} z oddziału?`,
          handleConfirm: handleDeleteFromCompany
        })
    }
  ];

  const closeModal = () => setIsOpen(false);

  return (
    <div
      className={classNames(style.container, {
        [style.owner]: isOwner
      })}
    >
      <div className={style.wrapper}>
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{email}</p>
      </div>
      <div className={style.wrapper}>
        {isOwner ? <Tag value={'Wlaściciel konta'} /> : position ? <Tag value={position} /> : '-'}
      </div>
      <div className={style.wrapper}>
        <p>{created_at ? toDateNumber(created_at) : '-'}</p>
      </div>
      <div className={classNames(style.wrapper, style.reverse)}>
        {!isOwner && (
          <Menu
            actions={actions}
            className={style.menu}
          />
        )}
      </div>
      {renderedModalConfirm}
      <Modal
        visible={isOpen}
        onClose={closeModal}
        title={'Edytuj dane pracownika'}
      >
        <NewMemberForm
          member={member}
          closeModal={closeModal}
          changeVersion={refreshCallback}
        />
      </Modal>
    </div>
  );
};

export default MemberThumbnail;
