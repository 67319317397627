import React, { forwardRef, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Modal } from 'components';

import placeholder from 'src/assets/img/product-thumbnail.png';
import { imageErrorHandler } from 'src/utils/helpers';

import ProductDetails from './components/ProductDetails';

import shared from 'styles/Shared.module.scss';

const ProductThumbnailImage = forwardRef(({ thumbnail, image, title, size = 'small', id, clickable = true }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const isProduction = useMemo(() => import.meta.env.VITE_NODE_ENV === 'production', []);

  const handleClick = () => {
    if (image && clickable) {
      setIsOpen(true);
    }
  };

  return (
    <>
      <img
        src={thumbnail || placeholder}
        alt={title}
        className={classNames(shared.defaultThumbnail, {
          [shared.withPointer]: !!image,
          [shared[size]]: !!size
        })}
        onClick={handleClick}
        loading={'lazy'}
        onError={imageErrorHandler}
      />
      {!isProduction && <span className={shared.prodId}>id: {id}</span>}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={'Product details'}
      >
        <ProductDetails
          image={image}
          title={title}
          id={id}
          ref={ref}
        />
      </Modal>
    </>
  );
});

export default ProductThumbnailImage;
