import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Input, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';

import { refreshOrder } from '../../../OrdersView/actions';

import style from './CreateList.module.scss';

const CreateList = ({ closeModal, selectedProducts, products, orderID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listName, setListName] = useState('');
  const validator = useValidator();
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedProductsData = useMemo(
    () =>
      selectedProducts?.map((id) => {
        const product = products?.find(({ product_id }) => product_id === id);
        return {
          id,
          name: product?.name,
          quantity: product?.qty
        };
      }),
    [selectedProducts]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const productsData = {
      order_id: orderID,
      title: listName,
      products: selectedProductsData?.map((product) => ({ id: product.id, quantity: product.quantity }))
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      const { data } = await ListsApi.createList(productsData);
      notifyCommon([`The list ${listName} has been created!`]);
      dispatch(refreshOrder());
      closeModal();
      history.push(`/lists/${data}`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      <Input
        id={'list-name'}
        name={'list-name'}
        label={'List name'}
        placeholder={'Orthodontics list'}
        value={listName}
        onChange={(e) => setListName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Create list'}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default CreateList;
