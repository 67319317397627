import React, { useEffect, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import { useAtom } from 'jotai/index';
import moment from 'moment';

import { AsyncSelect, Button, Card, Input, QtySelector, SwitchButton, useIsAdmin, useValidator } from 'components';
import Scanner from 'components/layout/BarcodeScanner/components/Scanner';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { ReactComponent as ScannerIcon } from 'src/assets/icons/scanner-gun.svg';
import { selectedCompanyAtom } from 'src/features/Warehouse';

import style from './ProductRelease.module.scss';

const defaultRelease = () => ({
  releaseDate: moment().format('YYYY-MM-DD'),
  department: null,
  product: null,
  quantity: 0
});

const ProductRelease = ({ refreshData, closeModal }) => {
  const isAdmin = useIsAdmin();
  const validator = useValidator();
  const containerRef = useRef(null);

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [data, setData] = useState(defaultRelease());
  const [version, setVersion] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [letModalOpen, setLetModalOpen] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [departmentsTotal, setDepartmentsTotal] = useState(0);

  useEffect(() => {
    disableBodyScroll(containerRef.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  const changeData = (value, key) => {
    setData((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const clearFormHandler = () => {
    setData(defaultRelease());
    validator.purgeFields();
    setVersion((prev) => prev + 1);
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const { product, quantity, releaseDate } = data;
    const payload = {
      quantity,
      product_id: product.value,
      release_date: releaseDate,
      ...(!!departmentsTotal && { department_id: data.department.id }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addRelease(payload);
      if (refreshData) refreshData();
      if (letModalOpen) clearFormHandler();
      else if (closeModal) closeModal();
      notifyCommon([`${quantity} pcs. of the product "${data.product.label}" have been released from warehouse.`]);
    } catch (error) {
      notifyApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onScanHandler = async (ean) => {
    const queryParams = {
      page: 1,
      perPage: 1,
      ean,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      const { data } = await WarehouseApi.getProducts(queryParams);
      const product = data.data[0];
      if (product) {
        changeData({ value: product.product_id, label: product.name, ...product }, 'product');
      } else {
        notifyCommon([`Nie znaleziono produktu. Kod EAN: ${ean}`]);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsScannerOpen(false);
    }
  };

  if (isScannerOpen) {
    return (
      <div className={style.scannerWrapper}>
        <Scanner onScan={onScanHandler} />
        <Button
          label={'Zakończ skanowanie'}
          onClick={() => setIsScannerOpen(false)}
          className={style.stopScanButton}
        />
      </div>
    );
  }

  return (
    <form
      className={style.container}
      onSubmit={formSubmitHandler}
      ref={containerRef}
    >
      <div className={style.inputWrapper}>
        <Button
          onClick={() => setIsScannerOpen(true)}
          label={<ScannerIcon className={style.icon} />}
          className={style.buttonScanner}
          disabled={!!data.product}
          type={'button'}
        />
        <AsyncSelect
          onChange={(val) => changeData(val, 'product')}
          apiCallback={WarehouseApi.getProducts}
          placeholder='Select...'
          validator={validator}
          valueKey='product_id'
          value={data.product}
          queryParams={{ version, ...(isAdmin && { company_id: selectedCompany }) }}
          id='select-product'
          rule='required'
          labelKey='name'
          label='Product'
          productSearch
          isClearable
        />
      </div>
      {data.product && data.product.unit_name !== 'pcs.' && (
        <Card yellow>
          <p className={style.text}>
            <strong>Attention!</strong> the selected product is available in collective packaging{' '}
            <strong>of {data.product?.collection_amount ?? '?'} pcs.</strong>
          </p>
        </Card>
      )}
      <div className={style.wrapper}>
        <AsyncSelect
          onChange={(val) => changeData(val, 'department')}
          apiCallback={WarehouseApi.getDepartments}
          label='Organizational unit'
          setTotal={setDepartmentsTotal}
          isDisabled={!departmentsTotal}
          queryParams={{ ...(isAdmin && { company_id: selectedCompany }) }}
          placeholder='Select...'
          value={data.department}
          id='select-department'
          validator={validator}
          rule={departmentsTotal ? 'required' : ''}
          labelKey='name'
          valueKey='id'
          isClearable
        />
        <span className={style.divider} />
        <div className={style.box}>
          <QtySelector
            setState={(val) => changeData(val, 'quantity')}
            wrapperStyle={style.quantityInput}
            rule={`required|only_positive${data.product ? `|max:${data.product?.quantity},num` : ''}`}
            state={data.quantity}
            validator={validator}
            label='Quantity'
            hideValueWhenZero
            id='quantity'
            suffix={'pcs.'}
          />
          <p className={style.currentQuantity}>/ {data.product ? data.product.quantity : '?'} pcs.</p>
        </div>
        <span className={style.divider} />
        <Input
          onChange={(e) => changeData(e.target.value, 'releaseDate')}
          wrapperStyle={style.quantityInput}
          value={data.releaseDate}
          validator={validator}
          label='Release date'
          name='expired_date'
          id='expired_date'
          rule='required'
          type='date'
        />
      </div>
      <Button
        className={style.button}
        label={'Save products release'}
        isLoading={isLoading}
      />
      <div className={style.switchWrapper}>
        <SwitchButton
          setValue={setLetModalOpen}
          value={letModalOpen}
          label={'Clear the form and leave the window open'}
          reverse
        />
      </div>
    </form>
  );
};

export default ProductRelease;
