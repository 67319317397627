import React from 'react';
import { Popover, Tooltip } from 'antd';
import classnames from 'classnames';

import ProcessStepper from 'components/layout/ProcessStepper';

import { ReactComponent as TickIcon } from 'src/assets/icons/tick.svg';
import { getCartCurrentStep, getFormattedAmount, suppliersCancelledProductsSum } from 'src/utils/helpers';

import style from '../../OrderSuppliersTable.module.scss';

const SupplierThumbnail = ({ supplier, orderApproval }) => {
  const supplierProductsCount = supplier.products.length;
  const cancelledProductsCount = suppliersCancelledProductsSum(supplier);

  return (
    <div className={classnames(style.wrapper)}>
      <p className={style.text}>{supplier.name}</p>
      <p className={style.amount}>{getFormattedAmount(supplier.amount)}</p>
      <p className={style.amount}>{supplierProductsCount}</p>
      <p className={style.amount}>{supplierProductsCount - cancelledProductsCount}</p>
      <div className={style.tick}>{supplier?.has_invoice ? <TickIcon /> : '-'}</div>
      <div className={style.statusWrapper}>
        <div className={style.statusLabelsWrapper}>
          <div className={style.statusLabel}>
            <Tooltip
              placement='top'
              title='Placed'
              destroyTooltipOnHide
            >
              <span className={style.statusLabelItem}></span>
            </Tooltip>
          </div>
          <div className={style.statusLabel}>
            <Tooltip
              placement='top'
              title='Waiting'
              destroyTooltipOnHide
            >
              <span className={style.statusLabelItem}></span>
            </Tooltip>
          </div>
          <div className={style.statusLabel}>
            <Tooltip
              placement='top'
              title='In progress'
              destroyTooltipOnHide
            >
              <span className={style.statusLabelItem}></span>
            </Tooltip>
          </div>
          <div className={style.statusLabel}>
            <Tooltip
              placement='top'
              title='Completed'
              destroyTooltipOnHide
            >
              <span className={style.statusLabelItem}></span>
            </Tooltip>
          </div>
        </div>
        <ProcessStepper
          {...getCartCurrentStep(supplier, orderApproval)}
          inline
          smaller
          responsive={false}
        />
      </div>
    </div>
  );
};

export default SupplierThumbnail;
