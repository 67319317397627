import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Input, PhoneInput, useAuthUser, useCompany, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi, CompanyApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { changeCompanyAddressesVersion } from 'src/features/CompaniesList/actions';
import { refreshUserData } from 'src/features/onboarding/actions';

import style from '../../../details.module.scss';

const defaultAddress = {
  street: '',
  building_number: '',
  apartment_number: '',
  zip_code: '',
  city: ''
};

const AddressForm = ({ addressData, closeModal, companyId }) => {
  const dispatch = useDispatch();
  const validator = useValidator();
  const user = useAuthUser();
  const company = useCompany();
  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState(addressData?.address || defaultAddress);
  const [name, setName] = useState(addressData?.name || '');
  const [email, setEmail] = useState(addressData?.email || '');
  const [phone, setPhone] = useState(addressData?.phone || '');

  const handleAddressChange = (e) => {
    setAddress((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const ApiClass = useMemo(() => (user?.role === userRoles.admin ? AdminApi : CompanyApi), [user]);

  const refreshCallbackAction = useMemo(() => (companyId ? changeCompanyAddressesVersion : refreshUserData), [companyId]);

  const saveFormData = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const hasOtherAddresses = !!company?.shipping_details?.length > 0;

    const data = {
      ...(addressData && { shipping_id: addressData.id }),
      name,
      email,
      phone,
      address,
      is_default: !hasOtherAddresses,
      ...(addressData?.is_default && { is_default: addressData.is_default }),
      ...(!!companyId && { company_id: companyId })
    };

    setIsLoading(true);
    try {
      await ApiClass.updateShippingDetails(data);
      notifyCommon(['Dane zostały zapisane.']);
      await dispatch(refreshCallbackAction());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.container}>
      <Input
        id='name'
        name='name'
        label='Nazwa odbiorcy'
        value={name}
        onChange={(e) => setName(e.target.value)}
        validator={validator}
        rule={'required'}
      />
      <div className={style.wrapper}>
        <Input
          id='email'
          name='email'
          label='E-mail'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          validator={validator}
          rule={'required'}
        />
        <PhoneInput
          id='phone'
          name='phone'
          label='Phone'
          value={phone}
          onChange={setPhone}
          validator={validator}
          rule={'required|phone'}
        />
      </div>
      <div className={classNames(style.wrapper, style.street)}>
        <Input
          id='street'
          name='street'
          label='Ulica'
          value={address.street}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='building_number'
          name='building_number'
          label='Numer'
          value={address.building_number}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='apartment_number'
          name='apartment_number'
          label='Lokal'
          value={address.apartment_number}
          onChange={handleAddressChange}
        />
      </div>
      <div className={classNames(style.wrapper, style.zipCode)}>
        <Input
          id='zip_code'
          name='zip_code'
          label='Kod pocztowy'
          value={address['zip_code']}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='city'
          name='city'
          label='Miejscowość'
          value={address.city}
          onChange={handleAddressChange}
          validator={validator}
          rule={'required'}
        />
      </div>
      <Button
        label={'Zapisz'}
        className={style.button}
        onClick={saveFormData}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AddressForm;
