import React from 'react';

import { Card, Gifts, Textarea } from 'components';

import shared from 'styles/Shared.module.scss';

const UserCommentAndGifts = ({ comment, setComment, gifts = [] }) => {
  return (
    <>
      <Card className={shared.defaultWrapper}>
        <h2 className={shared.cardTitle}>Your order comment</h2>
        <Textarea
          value={comment}
          setValue={setComment}
          placeholder={'Comment:...'}
        />
      </Card>
      {!!gifts.length && (
        <Card className={shared.defaultWrapper}>
          <h2 className={shared.cardTitle}>Gratisy do zamówienia</h2>
          <Gifts gifts={gifts} />
        </Card>
      )}
    </>
  );
};

export default UserCommentAndGifts;
