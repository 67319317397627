import React from 'react';
import classnames from 'classnames';

import { CustomTooltip, ProductStatus, Tag, useIsMobile } from 'components';
import Radio from 'components/layout/Radio';

import { getFormattedAmount } from 'src/utils/helpers';

import style from './ValuationModalProduct.module.scss';

const ValuationModalProduct = ({ product, setValuation, valuation, withProducer }) => {
  const { id, replacement_name, replacement_producer, name, price, producer, quantity, offer_quantity, availability, type } =
    product;
  const isMobile = useIsMobile(1240);

  const itemName = type === 'replacement' ? replacement_name : name;

  return (
    <div
      className={classnames(style.container, {
        [style.selected]: valuation?.id === id,
        [style.withProducer]: withProducer
      })}
      onClick={() => setValuation({ ...product, type: 'Dentametr' })}
    >
      <Radio
        value={valuation?.id === id}
        onChange={() => {}}
        className={style.radio}
      />
      <div
        className={classnames(style.wrapper, style.productName, {
          [style.withProducer]: withProducer
        })}
      >
        <CustomTooltip title={itemName}>
          <p
            className={classnames(style.text, style.name)}
            title={itemName}
          >
            {itemName}
          </p>
        </CustomTooltip>
      </div>
      {withProducer && (
        <div className={classnames(style.wrapper, style.producer)}>
          {isMobile && <p>Producer: </p>}
          <p className={style.text}>{type === 'replacement' ? replacement_producer : producer}</p>
        </div>
      )}
      <div className={classnames(style.wrapper, style.availability)}>
        <ProductStatus availability={availability} />
      </div>
      <div className={style.wrapper}>
        {isMobile && <p>Ilość: </p>}
        <p className={style.text}>{offer_quantity ?? quantity}</p>
      </div>
      <div className={classnames(style.wrapper, style.price)}>
        {isMobile && <p>Cena (za szt.): </p>}
        <p className={style.text}>{getFormattedAmount(price)}</p>
      </div>
      <div className={classnames(style.wrapper, style.value)}>
        {isMobile && <p>Wartość: </p>}
        <p className={style.text}>{getFormattedAmount(price * (offer_quantity ?? quantity))}</p>
      </div>
      <div className={classnames(style.wrapper, style.status)}>
        <Tag
          className={style.tag}
          color={type === 'offer' ? 'yellow' : type === 'replacement' ? 'blue' : ''}
          value={type === 'offer' ? 'Oferta dostawcy' : type === 'replacement' ? 'Zamiennik' : 'Produkt z listy'}
        />
      </div>
    </div>
  );
};

export default ValuationModalProduct;
