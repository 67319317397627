import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { CustomTooltip, Menu, Modal, Tag, useIsMobile, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';

import { refreshCategories } from '../../actions';
import ChangeParent from '../ChangeParent';
import EditNode from '../EditNode';
import MoveProducts from '../MoveProducts';
import MoveSelectedProducts from '../MoveSelectedProducts';
import OpenCloseButton from '../OpenCloseButton';

import style from './SingleNode.module.scss';

const SingleNode = ({ depth = 0, treeData = [], category, params }) => {
  const { name, id, isActive, count } = category;

  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState('');
  const [areChildrenVisible, setAreChildrenVisible] = useState(depth > 0);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const hasChildren = useMemo(() => treeData.filter((cat) => cat.parent === id).length > 0, [treeData]);

  const deleteCategory = async () => {
    try {
      await AdminApi.deleteCategory(id);
      notifyCommon([`Kategoria ${name} została usunięta.`]);
      dispatch(refreshCategories());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteCategoryHandler = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć kategorię ${name}?`,
      handleConfirm: deleteCategory
    });
  };

  const categoryStatusHandler = async () => {
    const apiCallback = isActive ? AdminApi.hideCategory : AdminApi.showCategory;

    try {
      await apiCallback(id);
      dispatch(refreshCategories());
      notifyCommon([`Kategoria ${name} ${isActive ? 'została ukryta' : 'jest ponownie dostępna'}.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const menu = [
    {
      title: 'Zmień nazwę',
      icon: 'edit',
      action: () => openModalHandler('EDIT_NAME')
    },
    {
      title: 'Przenieś kategorię',
      icon: 'move',
      action: () => openModalHandler('CHANGE_PARENT')
    },
    {
      title: 'Przenieś całą zawartość',
      icon: 'move',
      action: () => openModalHandler('MOVE_PRODUCTS'),
      disabled: count === 0
    },
    {
      title: isActive ? 'Ukryj kategorię' : 'Włącz kategorię',
      icon: 'share',
      color: 'blue',
      action: categoryStatusHandler
    },
    {
      title: 'Przenieś wybrane produkty',
      icon: 'move',
      color: 'blue',
      action: () => openModalHandler('CATEGORY_DETAILS'),
      disabled: count === 0
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: deleteCategoryHandler
    }
  ];

  const openModalHandler = (type) => {
    setContentType(type);
    setIsModalOpen(true);
  };

  const modalContent = useMemo(() => {
    switch (contentType) {
      case 'EDIT_NAME':
        return (
          <EditNode
            closeModal={() => setIsModalOpen(false)}
            name={name}
            id={id}
          />
        );
      case 'CHANGE_PARENT':
        return (
          <ChangeParent
            closeModal={() => setIsModalOpen(false)}
            name={name}
            parent={parent}
            categoryTree={treeData}
            id={id}
          />
        );
      case 'MOVE_PRODUCTS':
        return (
          <MoveProducts
            name={name}
            id={id}
            categoryTree={treeData}
            closeModal={() => setIsModalOpen(false)}
          />
        );
      case 'CATEGORY_DETAILS':
        return (
          <MoveSelectedProducts
            closeModal={() => setIsModalOpen(false)}
            params={params}
            id={id}
          />
        );
      default:
        return null;
    }
  }, [contentType, id, name, parent, treeData]);

  const modalTitle = useMemo(() => {
    switch (contentType) {
      case 'EDIT_NAME':
        return 'Zmień nazwę';
      case 'CHANGE_PARENT':
        return 'Zmień kategorię nadrzędną';
      case 'CATEGORY_DETAILS':
      case 'MOVE_PRODUCTS':
        return `Przenieś produkty z ${name} (${count || 0})`;
      default:
        return null;
    }
  }, [contentType]);

  const getChildrenCountHandler = (id) => {
    let counter = 0;
    const children1 = treeData.filter((cat) => cat.parent === id);

    if (children1.length === 0) return 0;

    children1.forEach((child1) => {
      counter += child1.count;
      counter += getChildrenCountHandler(child1.id);
    });

    return counter;
  };

  const childProductCount = useMemo(() => getChildrenCountHandler(id), [treeData]);

  return (
    <>
      <div
        style={{ marginLeft: (isMobile ? 20 : 30) * depth }}
        className={cn(style.label, { [style.notMaster]: depth > 0, [style.hidden]: !isActive })}
      >
        <div className={style.labelButtons}>
          {name}
          <CustomTooltip title={childProductCount > 0 && `${count} w kategorii + ${childProductCount} w podkategoriach`}>
            <span className={style.counter}>({count + childProductCount || 0})</span>
          </CustomTooltip>
          {!isActive && (
            <Tag
              value={'Ukryta'}
              className={style.tag}
              color={'red'}
            />
          )}
        </div>
        {hasChildren && (
          <OpenCloseButton
            setState={setAreChildrenVisible}
            state={areChildrenVisible}
          />
        )}
        <Menu
          actions={menu}
          className={style.menu}
        />
        {renderedModalConfirm}
        <Modal
          visible={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={modalTitle}
        >
          {modalContent}
        </Modal>
      </div>
      {areChildrenVisible &&
        treeData?.map((cat) => {
          if (cat.parent === id) {
            return (
              <SingleNode
                key={cat.id}
                category={cat}
                treeData={treeData}
                className={style.label}
                depth={depth + 1}
              />
            );
          }
        })}
    </>
  );
};

export default SingleNode;
