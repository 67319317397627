import React, { useEffect, useMemo, useState } from 'react';

import { Card, Loader, Table } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { getFormattedAmount, translateMonths } from 'src/utils/helpers';

import style from './AdminSummary.module.scss';

const AdminSummary = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  const columns = useMemo(
    () =>
      data?.months
        ? data.months.map((month) => ({
            name: translateMonths(month)
          }))
        : [],
    [data]
  );

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const { data } = await StatisticsApi.getOrdersDataForAdmin();
        setData(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <Card className={style.container}>
      <h2 className={style.title}>Globalne statystyki aplikacji</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          columns={[{ name: '' }, ...columns]}
          headerClasses={style.header}
          className={style.table}
        >
          <div className={style.singleRow}>
            <p>Total orders value (total)</p>
            {data?.total_sum?.map((sum) => (
              <p key={sum}>{getFormattedAmount(sum)}</p>
            ))}
          </div>
          <div className={style.singleRow}>
            <p>Ilość zamówień</p>
            {data?.orders_count?.map((count, index) => (
              <p key={count + '-order-' + index}>{count}</p>
            ))}
          </div>
          <div className={style.singleRow}>
            <p>Ilość koszyków</p>
            {data?.basket_count?.map((count, index) => (
              <p key={count + '-basket-' + index}>{count}</p>
            ))}
          </div>
        </Table>
      )}
    </Card>
  );
};

export default AdminSummary;
