import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getFormattedAmount } from 'src/utils/helpers';

import style from '../../SupplierForm.module.scss';

const ValuationSum = () => {
  const products = useSelector((state) => state.supplierForm.products_pricing);
  const sum = useMemo(
    () =>
      getFormattedAmount(
        products.reduce((accumulator, currentValue) => accumulator + +currentValue.price * +currentValue.quantity, 0)
      ),
    [products]
  );

  return <p className={style.sum}>Valuation total: {sum}</p>;
};

export default ValuationSum;
