import React, { useMemo, useState } from 'react';

import { Button, QtySelector } from 'components';
import ChangeMenu from 'components/layout/changeMenu';

import { getFormattedAmount } from 'src/utils/helpers';

import style from '../ChangeMenu.module.scss';

const ChangePrice = ({
  price,
  setPrice,
  productId,
  wasPriceChange,
  isActive,
  setIsActive,
  min,
  max,
  tagClassName,
  displayValueFormatter,
  label
}) => {
  const [priceValue, setPriceValue] = useState(+price);
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    setIsLoading(true);
    if (setPrice) await setPrice(priceValue);
    setIsActive(false);
    setIsLoading(false);
  };

  const isNotDisabled = useMemo(() => {
    if (min?.toString() && max?.toString()) {
      return +priceValue >= +min && +priceValue <= +max;
    } else if (min?.toString()) {
      return +priceValue >= +min;
    } else if (max?.toString()) {
      return +priceValue <= +max;
    } else {
      return true;
    }
  }, [priceValue, min, max]);

  return (
    <ChangeMenu
      value={getFormattedAmount(price)}
      isActive={isActive}
      setIsActive={setIsActive}
      wasPriceChange={wasPriceChange}
      tagClassName={tagClassName}
      displayValueFormatter={displayValueFormatter}
      width={'auto'}
    >
      <QtySelector
        label={label || 'Change price'}
        id={productId + '-new-price'}
        name={productId + '-new-price'}
        state={priceValue}
        setState={setPriceValue}
        width={130}
        suffix={'PLN'}
        priceInput
        hideArrows
      />
      <Button
        label={'Save'}
        onClick={handleSave}
        className={style.save}
        disabled={!isNotDisabled}
        isLoading={isLoading}
      />
    </ChangeMenu>
  );
};

export default ChangePrice;
