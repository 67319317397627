import React, { useEffect, useState } from 'react';

import { Button, Modal, PaginationNav, Searchbar, useIsAdmin, useRequestAbortController, useSearchParam } from 'components';
import FilterByOptions from 'components/layout/filtering/FilterByOptions';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';

import EventForm from '../EventForm';
import EventsList from '../EventsList';

import styles from './ServiceEvents.module.scss';

const options = [
  { label: 'Repair', value: 'FIX' },
  { label: 'Service', value: 'SERVICE' }
];

const optionsDictionary = {
  FIX: options[0],
  SERVICE: options[1]
};

const valueToOption = (value) => optionsDictionary[value];

const ServiceEvents = ({ serviceId, params }) => {
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const selectedCompany = useSearchParam(params, 'company');
  const isAdmin = useIsAdmin();

  const search = useSearchParam(params, 'search');
  const type = useSearchParam(params, 'type');

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      service_id: serviceId,
      page: currentPage,
      perPage,
      search,
      type
    };

    try {
      const { data } = await ServicesApi.getEventsList(params, signal);
      setPageQty(data.last_page);
      setPageData(data.data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  useEffect(() => {
    const getDataHandler = async () => {
      try {
        setLoading(true);
        await getData();
      } finally {
        setLoading(false);
      }
    };

    getDataHandler();
  }, [currentPage, perPage, search, serviceId, type]);

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <div className={styles.buttons}>
          <Button
            iconName={'add'}
            label='Add event'
            onClick={() => setAddProductVisible(true)}
          />
          <FilterByOptions
            valueToOption={valueToOption}
            placeholder={'Filter events...'}
            optionKey={'type'}
            options={options}
            params={params}
          />
          <Searchbar params={params} />
        </div>
      </div>
      <EventsList
        list={pageData}
        params={params}
        isLoading={loading}
        refreshCallback={getData}
      />
      <PaginationNav
        hideSelect
        pagesQty={pageQty}
        page={currentPage}
        defaultQty={perPage}
        setPerPage={setPerPage}
        setPage={setCurrentPage}
      />
      <Modal
        visible={addProductVisible}
        title={'Dodaj zdarzenie'}
        onClose={() => setAddProductVisible(false)}
      >
        <EventForm
          serviceId={serviceId}
          refreshCallback={getData}
          onClose={() => setAddProductVisible(false)}
          selectedCompany={selectedCompany}
        />
      </Modal>
    </div>
  );
};

export default ServiceEvents;
