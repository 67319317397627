import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import {
  Button,
  LoaderGlobal,
  OrderSuppliersTable,
  PageHeader,
  SeoHelmet,
  useCompany,
  useIsAdmin,
  usePermissions
} from 'components';
import AllOrdersSumBar from 'components/layout/AllOrdersSumBar';
import { notifyApiError, notifyCommon, notifyDanger } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { clearOrderDetails } from 'src/features/ValuationView/actions';
import { scrollToElementAndReturnPromise } from 'src/utils/helpers';

import Address from './components/Address';
import OrdersList from './components/OrdersList';

import style from './ValuationsOrder.module.scss';

const ValuationsOrder = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const addressesWrapperRef = useRef();
  const isAdmin = useIsAdmin();
  const company = useCompany();

  const [pageData, setPageData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shippingAddressId, setShippingAddressId] = useState();
  const [canCreateOrders] = usePermissions([userPermissions.orders.create]);

  const orderDetails = useSelector((state) => state.valuationView.orderDetails);
  const newQuantityProducts = useSelector((state) => state.valuationView.newQuantityProducts);
  const valuationsVersion = useSelector((state) => state.valuationView.valuationsVersion);
  const addressesVersion = useSelector((state) => state.companiesList.addressesVersion);

  const getData = async () => {
    try {
      const { data } = await ValuationsApi.resolveOrderData(params);
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
      notifyDanger(['Wystąpił błąd. Skontaktuj się z nami.']);
      history.goBack();
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      setIsLoading(true);
      await getData();
      setIsLoading(false);
    };

    dispatch(clearOrderDetails());
    getInitialData();
  }, []);

  useEffect(() => {
    if (valuationsVersion > 0 || addressesVersion > 0) getData();
  }, [valuationsVersion, addressesVersion]);

  const createOrder = async (needApproval = false) => {
    if (!shippingAddressId) {
      notifyCommon(['Wybierz adres wysyłki zamówienia.']);
      if (addressesWrapperRef?.current) {
        scrollToElementAndReturnPromise(addressesWrapperRef.current).then(() => addressesWrapperRef.current?.focus());
      }
      return;
    }

    const data = {
      need_approval: company?.is_order_approval_enabled || needApproval,
      shipping_address_id: shippingAddressId,
      products: newQuantityProducts,
      orderData: orderDetails,
      uid: params.uid
    };

    try {
      setIsLoading(true);
      const response = await ValuationsApi.createOrder(data);
      history.push({
        pathname: `/valuations/${params.uid}/order/thanks`,
        state: response.data
      });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderGlobal />;
  }

  if (!pageData || !pageData?.suppliers?.length) {
    return null;
  }

  return (
    <>
      <SeoHelmet title={'Order summary'} />
      <PageHeader name={'Order summary'} />
      <div className={style.container}>
        <OrderSuppliersTable order={pageData} />
        <Address
          ref={addressesWrapperRef}
          setShippingAddressId={setShippingAddressId}
          company={pageData?.company}
        />
        <form
          className={style.form}
          onSubmit={(e) => e.preventDefault()}
        >
          <OrdersList suppliers={pageData?.suppliers} />
          <AllOrdersSumBar
            suppliersNumber={pageData?.suppliers?.length}
            totalSum={pageData?.total_sum}
            shipPrice={pageData?.shipping_sum}
            status={pageData?.status}
          >
            {canCreateOrders && (
              <>
                {isAdmin && (
                  <Button
                    label={'Akceptacja zamówienia'}
                    onClick={() => createOrder(true)}
                    disabled={pageData?.suppliers?.length === 0}
                    gray
                  />
                )}
                <Button
                  onClick={() => createOrder()}
                  label={'Place order'}
                  disabled={pageData?.suppliers?.length === 0}
                />
              </>
            )}
          </AllOrdersSumBar>
        </form>
      </div>
    </>
  );
};

export default ValuationsOrder;
