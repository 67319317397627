import React, { useState } from 'react';
import classNames from 'classnames';

import { InfoWidget, QtySelector, useStateTimeout } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import style from '../ProductComponents.module.scss';

const Price = (props) => {
  const { isMobile, id, disabled, validator, price, setPrice, fixedPrice, isFixed } = props;
  const [localPrice, setLocalPrice] = useState(price);

  useStateTimeout({
    localState: localPrice,
    setLocalState: setLocalPrice,
    state: price,
    setState: setPrice,
    timeoutValue: 400
  });

  return (
    <div
      className={classNames(style.wrapper, style.priceInputWrapper)}
      id={'input-price'}
    >
      <QtySelector
        state={localPrice}
        setState={setLocalPrice}
        suffix={'PLN'}
        hideArrows
        id={'priceInput' + id}
        width={isMobile ? undefined : 140}
        wrapperStyle={style.priceInput}
        label={isMobile ? 'Cena' : null}
        priceInput
        disabled={disabled || isFixed}
        validator={!disabled && validator}
        rule={'required|min_price'}
      >
        {isFixed ? (
          <div
            className={classNames(style.fixedPriceIndicator, {
              [style.active]: +fixedPrice !== +price
            })}
          >
            Cena zablokowana
            <InfoWidget className={style.info}>
              <p>Ten produkt posiada zablokowaną cenę. Aby ją zmienić, skontaktuj się z nami.</p>
            </InfoWidget>
          </div>
        ) : (
          !!fixedPrice && (
            <div
              className={classNames(style.fixedPriceIndicator, {
                [style.active]: +fixedPrice !== +price
              })}
            >
              {+fixedPrice !== +price ? 'Cena została zmieniona' : 'Twoja ostatnia cena'}
              <InfoWidget className={style.info}>
                <p>
                  Ten produkt został przez Ciebie poprzednio wyceniony dla tego klienta na:&nbsp;
                  <strong>{getFormattedAmount(fixedPrice)}</strong>.
                </p>
              </InfoWidget>
            </div>
          )
        )}
      </QtySelector>
    </div>
  );
};

export default Price;
