import { KATE_PHONE_NUMBER, KATE_PHONE_NUMBER_FORMATTED } from 'src/constants/misc';

import style from './ContactInfo.module.scss';

const ContactInfo = () => {
  return (
    <div className={style.phone}>
      <span className={style.phoneLabel}>You need help? Call</span>
      <a
        className={style.link}
        href={`tel:${KATE_PHONE_NUMBER}`}
      >
        {KATE_PHONE_NUMBER_FORMATTED}
      </a>
    </div>
  );
};

export default ContactInfo;
