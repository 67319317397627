import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { Card } from 'components';
import { Accordion } from 'components';
import OrderHeader from 'components/layout/OrderHeader';

import { getFormattedAmount } from 'src/utils/helpers';

import { addCartOrderDetails } from '../../actions';
import OrderOptions from '../OrderOptions';
import ProductsList from '../ProductsList';
import OrderComment from '../SupplierComment';
import UserCommentAndGifts from '../UserCommentAndGifts';

import style from './OrderDetails.module.scss';
import shared from 'styles/Shared.module.scss';

const OrderDetails = ({ order }) => {
  const dispatch = useDispatch();

  const { name, payment, shipping, amount, shipping_price } = order;
  const [paymentId, setPaymentId] = useState(payment[0]?.id);
  const [shippingId, setShippingId] = useState(shipping[0]?.id);
  const [userComment, setUserComment] = useState('');

  const titleProductsList = <h3 className={cn(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Products list</h3>;
  const titlePaymentDelivery = (
    <h3 className={cn(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Shipping and delivery</h3>
  );
  const titleComment = <h3 className={cn(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Comments</h3>;
  const titleSummary = <h3 className={cn(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Summary</h3>;

  const handleDispatchOrderDetails = () =>
    dispatch(
      addCartOrderDetails({
        supplier_id: order.id,
        shipping_id: shippingId,
        payment_id: paymentId,
        user_comment: userComment
      })
    );

  useEffect(() => {
    if (paymentId && shippingId) {
      handleDispatchOrderDetails();
    }
  }, [paymentId, shippingId, order]);

  useEffect(() => {
    const timeoutId = setTimeout(handleDispatchOrderDetails, 500);
    return () => clearTimeout(timeoutId);
  }, [userComment]);

  return (
    <div
      className={style.supplier}
      id={name}
    >
      <OrderHeader supplier={order} />
      <Accordion
        section
        title={titleProductsList}
        id={`order-details-${order.id}`}
        extraDeps={[order.products]}
      >
        <ProductsList
          products={order.products}
          showAll
        />
      </Accordion>

      <OrderComment comment={order.comment} />
      <Accordion
        section
        title={titlePaymentDelivery}
        id={`order-payment-delivery-${order.id}`}
        extraDeps={[payment, shipping]}
      >
        <div className={style.container}>
          <OrderOptions
            groupName={`${order.name}-payment`}
            options={payment}
            setCurrentOption={setPaymentId}
            currentOption={paymentId}
            errorMessage={'Musisz wybrać sposób płatności'}
            sectionTitle={'Payment method'}
          />
          <OrderOptions
            groupName={`${order.name}-shipping`}
            options={shipping}
            setCurrentOption={setShippingId}
            currentOption={shippingId}
            errorMessage={'Musisz wybrać sposób dostawy'}
            sectionTitle={'Shipping method'}
          />
        </div>
      </Accordion>
      <Accordion
        section
        title={titleComment}
        id={`order-comment-${order.id}`}
        extraDeps={[userComment]}
      >
        <UserCommentAndGifts
          setComment={setUserComment}
          comment={userComment}
          gifts={order.gifts}
        />
      </Accordion>
      <Accordion
        section
        title={titleSummary}
        id={`order-summary-${order.id}`}
        extraDeps={[amount, shipping_price]}
      >
        <Card className={cn(shared.defaultWrapper, style.card)}>
          <h2 className={style.partOfAmount}>Products value: {getFormattedAmount(amount)}</h2>
          <h2 className={style.partOfAmount}>Shipping costs: {getFormattedAmount(shipping_price)}</h2>
          <h2 className={style.amount}>Order total: {getFormattedAmount(amount + shipping_price)}</h2>
        </Card>
      </Accordion>
    </div>
  );
};

export default OrderDetails;
