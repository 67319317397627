import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ProgressLine } from 'components';

import style from './FormProgressBar.module.scss';

const FormProgressBar = () => {
  const store = useSelector((state) => state.supplierForm);

  const { categories, products_pricing } = store;

  const allProductsCount = useMemo(() => {
    let counter = 0;
    categories.forEach((item) => (counter += item.count));
    return counter;
  }, [categories]);

  return (
    <div className={style.container}>
      <ProgressLine
        fullWidth
        maxValue={allProductsCount}
        currentValue={products_pricing.length}
        prefix={'Valuated '}
        className={style.progress}
        alignToLeft
      />
    </div>
  );
};

export default FormProgressBar;
