import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, Input, PhoneInput, Select, useAuthUser, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { changeUsersVersion } from 'src/features/Users/actions';
import { getRoleColorCode, getUserRoleLabel } from 'src/utils/helpers';

import style from './AddUser.module.scss';

const AddUser = ({ onClose }) => {
  const user = useAuthUser();
  const validator = useValidator();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roles, setRoles] = useState([]);
  const [phone, setPhone] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [nip, setNip] = useState();
  const [clinicsName, setClinicsName] = useState();
  const [password, setPassword] = useState();

  const showPhone = [userRoles.user, userRoles.supplier].includes(selectedRole?.value);
  const showCompanyDetails = userRoles.user === selectedRole?.value;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name,
      email,
      password,
      nip,
      phone,
      clinics_name: clinicsName,
      role: selectedRole?.value
    };

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    setIsLoading(true);
    try {
      await AdminApi.addUser(data);
      dispatch(changeUsersVersion());
      onClose();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    validator.purgeFields();
  }, [selectedRole]);

  const formatRolesToOptions = (roles) => {
    const filteredRoles = roles.filter((role) => role !== 'distributor');

    return filteredRoles.map((role) => ({
      value: role,
      label: getUserRoleLabel(role),
      color: getRoleColorCode(role)
    }));
  };

  const getRoles = async () => {
    setIsLoading(true);
    try {
      const { data } = await AdminApi.getUserRoles();
      setRoles(formatRolesToOptions(data));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (roles.length) {
      setSelectedRole(roles.find((role) => role.value === user.role));
    }
  }, [roles]);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <div className={style.container}>
        <Select
          wrapperStyle={style.selectSmall}
          options={roles}
          value={selectedRole}
          onChange={setSelectedRole}
          placeholder={'Rola'}
          label={'Wybierz rolę'}
          isDisabled={isLoading}
          validator={validator}
          rule={'required'}
          isSearchable={false}
        />
        <Input
          id='name'
          name='name'
          label='Imię i nazwisko'
          type='text'
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          disabled={isLoading}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='email'
          name='email'
          label='E-mail'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          validator={validator}
          rule={'required|email'}
        />
        {showCompanyDetails && (
          <>
            <Input
              id='office-name'
              name='office-name'
              label='Nazwa gabinetu'
              value={clinicsName}
              onChange={(e) => setClinicsName(e.target.value)}
              validator={validator}
              rule={'required'}
            />
            <Input
              id='nip'
              name='nip'
              label='NIP'
              type='number'
              value={nip}
              onChange={(e) => setNip(e.target.value)}
              fullWidth
              disabled={isLoading}
              validator={validator}
              rule={'required'}
            />
          </>
        )}
        {showPhone && (
          <PhoneInput
            id='phone'
            name='phone'
            label='Phone'
            value={phone}
            onChange={setPhone}
            disabled={isLoading}
            validator={validator}
            rule={'required|phone'}
          />
        )}
        <Input
          id='password'
          name='password'
          label='Hasło'
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          validator={validator}
          rule={'required|min_password'}
        />
        <Button
          label={'Zapisz'}
          type={'submit'}
          className={style.button}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default AddUser;
