import React from 'react';

import { Card, EmptyState, Table } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import EventThumbnail from '../EventThumbnail';

import thumbnailStyles from '../EventThumbnail/EventThumbnail.module.scss';

const columns = [
  { name: 'Type' },
  { name: 'Serviced by' },
  { name: 'Servicer' },
  { name: 'Phone' },
  { name: 'Reason' },
  { name: 'Attachments' },
  { name: 'Date' }
];

const EventsList = ({ list = [], isLoading, params, refreshCallback }) => {
  if (list.length === 0 && !isLoading) {
    return (
      <Card>
        <EmptyState type={params.keys.search ? 'search' : params.keys.category ? 'search' : 'serviceService'} />
      </Card>
    );
  }

  return (
    <Table
      columns={columns}
      headerClasses={thumbnailStyles.gridRow}
    >
      {isLoading ? (
        <ProductPlaceholder />
      ) : (
        list.map((product, i) => (
          <EventThumbnail
            key={`${product.product_id}-${i}`}
            refreshCallback={refreshCallback}
            event={product}
            params={params}
          />
        ))
      )}
    </Table>
  );
};

export default EventsList;
