import React from 'react';

import { Modal, Tag } from 'components';

import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './ContactWithClient.module.scss';

const ContactWithClient = ({ isOpen, setIsOpen, details, title }) => {
  const { phone, name, email } = details || {};
  return (
    <Modal
      visible={isOpen}
      onClose={() => setIsOpen(false)}
      title={title || 'Contact the customer'}
    >
      <div className={style.container}>
        <h2 className={style.name}>{name}</h2>
        <p className={style.data}>
          Telefon: {phone ? <a href={`tel:${phone}`}>{getFormattedPhoneNumber(phone)}</a> : <Tag value={'Brak'} />}
        </p>
        <p className={style.data}>E-mail: {email ? <a href={`mailto:${email}`}>{email}</a> : <Tag value={'Brak'} />}</p>
      </div>
    </Modal>
  );
};

export default ContactWithClient;
