import SingleProduct from '../../../ProductsWithoutPrice/components/SingleProduct';

import style from '../../../ProductsWithoutPrice/ProductsWithoutPrice.module.scss';

const OrderViewProductsList = ({ products, productsWithoutAssignedOffer, isDefinedView }) => {
  return (
    <div className={style.elementsWrapper}>
      {products.length > 0 && (
        <div className={style.valuationsHeader}>
          <p>Product</p>
          <p>Category</p>
          <p>Producer</p>
        </div>
      )}
      {products?.map((prod) => (
        <SingleProduct
          product={prod}
          key={prod.id}
        />
      ))}
      {isDefinedView && productsWithoutAssignedOffer.length > 0 ? (
        <div className={style.notAssignedOffersWrapper}>
          {products.length > 0 && (
            <p className={style.notAssignedOffersWrapperTitle}>
              Produkty bez przypisanych ofert <strong>({productsWithoutAssignedOffer.length})</strong>:
            </p>
          )}
          <div className={style.valuationsHeader}>
            <p>Product</p>
            <p>Category</p>
            <p>Producer</p>
          </div>
          {productsWithoutAssignedOffer?.map((prod) => {
            return (
              <SingleProduct
                product={prod}
                key={prod.id}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default OrderViewProductsList;
