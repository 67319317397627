import React, { useMemo, useState } from 'react';

import { AsyncSelect, Select, useAuthUser, useIsAdmin, useValidator } from 'components';
import { Button } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { userRoles } from 'src/constants/enums';

import style from './MoveElementForm.module.scss';

const MoveElementForm = ({ closeModal, uid, apiCallback, buttonLabel, refreshCallback, isCompanyAdmin }) => {
  const user = useAuthUser();
  const isAdmin = useIsAdmin();

  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const validator = useValidator();

  if (!isCompanyAdmin && user?.role !== userRoles.admin) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await apiCallback({ uid, company_id: !isCompanyAdmin ? selectedUser.id : selectedUser.value });
      refreshCallback();
      notifyCommon([`Element został przeniesiony do firmy ${selectedUser.label}`]);
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const options = useMemo(() => {
    if (isAdmin) return;
    return user.user_companies.map((company) => ({ label: company.name, value: company.id }));
  }, [user]);

  return (
    <form
      className={style.container}
      onSubmit={handleSubmit}
    >
      {isAdmin ? (
        <AsyncSelect
          value={selectedUser}
          onChange={setSelectedUser}
          apiCallback={AdminApi.getCompanies}
          queryParams={{ show_deactivated: 0 }}
          className={style.select}
          valueKey={'id'}
          labelKey={'name'}
          placeholder={'Firma'}
          label={'Company name'}
          isClearable
          validator={validator}
          rule={'required'}
        />
      ) : (
        <Select
          options={options}
          value={selectedUser}
          onChange={setSelectedUser}
          placeholder={'Select a branch'}
          label={'Branch'}
          isSearchable={false}
          id={'status'}
          name={'status'}
          rule={'required'}
        />
      )}
      <Button
        label={buttonLabel}
        title={buttonLabel}
        type={'submit'}
        isLoading={isLoading}
      />
    </form>
  );
};

export default MoveElementForm;
