import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import { Chart, Select } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { suppliersCountAtom } from 'src/features/Analytics';
import { toDateNumber } from 'src/utils/dateTime';
import { getFormattedAmount, translateMonthsWithInfo } from 'src/utils/helpers';

import style from '../Charts.module.scss';

const options = [
  { label: 'Total orders value', value: 'series_realisation_prices_sum' },
  { label: 'Number of SKUs', value: 'series_realisation_count' }
];

const SuppliersPartOfBasket = ({ selectedCompanyId, filters }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useAtom(suppliersCountAtom);
  const [selectedDataType, setSelectedDataType] = useState(options[0]);

  const getData = async () => {
    const queryData = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };
    try {
      setIsLoading(true);
      const { data } = await StatisticsApi.getProductsRealisationBySupplierMonthly(queryData);
      setChartData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const categories = useMemo(() => {
    const series = chartData ? chartData[selectedDataType.value] : [];
    return series?.length > 0 ? series[0].data.map(([date]) => date) : [];
  }, [chartData, selectedDataType]);

  const chartConfig = useMemo(() => {
    const seriesFormatter = (series = []) =>
      series?.map((s) => ({
        ...s,
        data: s.data?.map(([_, value]) => value)
      }));

    const getPercentages = (data = []) => {
      const monthlySum = Array(13).fill(0);

      data.forEach((supplier) => {
        supplier.data.forEach((value, index) => {
          monthlySum[index] += value;
        });
      });

      return data.map((supplier) => ({
        name: supplier.name,
        data: supplier.data.map((value, index) => (monthlySum[index] === 0 ? 0 : (value / monthlySum[index]) * 100))
      }));
    };

    const data = seriesFormatter(chartData ? chartData[selectedDataType.value] : []);
    const suppliersPercentages = getPercentages(data);
    let formatter;

    switch (selectedDataType.value) {
      case 'series_realisation_prices_sum': {
        formatter = (value) => getFormattedAmount(value);
        break;
      }
      default: {
        formatter = (value) => value;
        break;
      }
    }

    const defaultFormatter = (val, opts) => {
      const index = opts.dataPointIndex;
      const supplier = suppliersPercentages[opts.seriesIndex];
      const percentages = supplier.data[index];
      return `${formatter(val)} (${percentages.toFixed(2)}%)`;
    };

    return { data, formatter, percentagesFormatter: defaultFormatter };
  }, [chartData, selectedDataType, selectedCompanyId]);

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  return (
    <>
      <Chart
        tooltipFormatter={chartConfig.percentagesFormatter}
        dataLabelsFormatter={chartConfig.formatter}
        title={'Suppliers share in your cart'}
        yAxisFormatter={chartConfig.formatter}
        categoriesFormatter={(value) => translateMonthsWithInfo(value, filters)}
        configRefreshVariable={chartConfig}
        customSeries={chartConfig.data}
        categories={categories}
        isLoading={isLoading}
        chartTypes={['bar']}
        tooltipShared
        height={500}
        headerMargin
        fullWidth
        haveData
        stacked
      >
        <Select
          onChange={setSelectedDataType}
          wrapperStyle={style.select}
          className={style.select}
          value={selectedDataType}
          isSearchable={false}
          options={options}
          width={200}
        />
      </Chart>
      <p>* - Data as of {toDateNumber(filters.endDate)}. The data is aggregated after the order is received.</p>
    </>
  );
};

export default SuppliersPartOfBasket;
