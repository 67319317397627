import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { atom, useSetAtom } from 'jotai';

import {
  Button,
  Card,
  EmptyState,
  FilterByCompany,
  Modal,
  PageHeader,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  useIsAdmin,
  useRequestAbortController,
  useSearchParam
} from 'components';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { TagsApi } from 'src/api';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import AddTagForm from './components/AddTagForm';
import TagsTable from './components/TagsTable';
import { refreshTags } from './actions';

import styles from './TagsManagement.module.scss';

export const selectedCompanyAtom = atom(undefined);

const TagsManagement = (props) => {
  const params = query(props);
  const dispatch = useDispatch();
  const version = useSelector((state) => state.tags.version);
  const isAdmin = useIsAdmin();
  const setSelectedCompany = useSetAtom(selectedCompanyAtom);

  const search = useSearchParam(params, 'search');
  const selectedCompany = useSearchParam(params, 'company');

  const [pageData, setPageData] = useState([]);
  const [history, setHistory] = useState([]);
  const [addTagVisible, setAddTagVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);

  const [abortController, setNewController] = useRequestAbortController();

  const getData = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();
    const params = {
      ...(search && { search: search }),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setLoading(true);
      const { data } = await TagsApi.getTags(params, signal);
      setPageData(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setLoading(false);
    }
  };

  const getHistory = async () => {
    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      page: 1,
      perPage: 100
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsHistoryLoading(true);
      const { data } = await TagsApi.getTagsHistory(params, signal);
      setHistory(data.data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsHistoryLoading(false);
    }
  };

  const saveHandler = () => {
    setAddTagVisible(false);
    dispatch(refreshTags());
  };

  useEffect(() => {
    getData();
  }, [version, search, selectedCompany]);

  useEffect(() => {
    getHistory();
  }, [version, selectedCompany]);

  useEffect(() => {
    setSelectedCompany(selectedCompany);
  }, [selectedCompany]);

  if (isAdmin && !selectedCompany) {
    return (
      <>
        <PageHeader
          name={pages.tags.title}
          text={'Wybierz firmę'}
        />
        <Card className={styles.centerContent}>
          <EmptyState type={'selectCompanyTags'} />
          <FilterByCompany
            value={selectedCompany}
            params={params}
          />
        </Card>
      </>
    );
  }

  return (
    <div className={styles.container}>
      <SeoHelmet title={pages.tags.title} />
      <StickyPageHeader name={pages.tags.title}>
        <Searchbar params={params} />
        <Button
          label={'Define Tag'}
          onClick={() => setAddTagVisible(true)}
          iconName={'add'}
        />
        <TimelineHistory
          history={history}
          buttonLabel={'History'}
          title={'Historia tagów'}
          isLoading={isHistoryLoading}
          emptyStateType='tagsHistory'
        />
      </StickyPageHeader>
      <TagsTable
        list={pageData}
        isLoading={loading}
        params={params}
        refreshCallback={saveHandler}
      />
      <Modal
        visible={addTagVisible}
        onClose={() => setAddTagVisible(false)}
        title={'Define Tag'}
      >
        <AddTagForm refreshCallback={saveHandler} />
      </Modal>
    </div>
  );
};

export default TagsManagement;
