import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Menu, Modal, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import { toDateNumber } from 'src/utils/dateTime';

import { refreshWholesalers } from '../../actions';
import AddWholesalerForm from '../AddWholesalerForm';

import styles from './WholesalerThumbnail.module.scss';

const WholesalerThumbnail = ({ wholesaler }) => {
  const dispatch = useDispatch();
  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const { id, logo, name, nip, domain, created_at } = wholesaler;

  const deleteWholesaler = async () => {
    try {
      const { data } = await WholesalersApi.deleteWholesaler(id);
      dispatch(refreshWholesalers());
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteWholesalerConfirm = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć hurtownię ${name}?`,
      handleConfirm: deleteWholesaler
    });
  };

  const actions = [
    {
      title: 'Edit',
      icon: 'edit',
      action: () => setIsVisibleEdit(true)
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: deleteWholesalerConfirm
    }
  ];

  const saveHandler = () => {
    setIsVisibleEdit(false);
    dispatch(refreshWholesalers());
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Link
            to={`/wholesalers/${id}`}
            className={styles.link}
          >
            <img
              src={logo}
              alt={name}
            />
          </Link>
        </div>
        <div>
          <Link
            to={`/wholesalers/${id}`}
            className={styles.link}
          >
            {name}
          </Link>
        </div>
        <div>{nip}</div>
        <div>{domain}</div>
        <div>{toDateNumber(created_at)}</div>
        <div className={styles.menuWrapper}>
          <Menu
            className={styles.menu}
            actions={actions}
          />
        </div>
      </div>
      <Modal
        visible={isVisibleEdit}
        onClose={() => setIsVisibleEdit(false)}
        title={'Edytuj hurtownię'}
      >
        <AddWholesalerForm
          wholesalerData={wholesaler}
          onClose={saveHandler}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default WholesalerThumbnail;
