import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { Button, Card, ProcessStepper, SeoHelmet } from 'components';

import { ReactComponent as HappyTooth } from 'src/assets/icons/cheering_tooth.svg';
import { orderStatuses } from 'src/constants/enums';
import { orderProcessSteps } from 'src/constants/steps';
import pages from 'src/dictionaries/pages.json';

import style from './OrderThanksPage.module.scss';

const OrderThanksPage = () => {
  const location = useLocation();
  const orderUid = location.state?.UID || location.state?.uid;
  const orderStatus = location.state?.status;

  if (orderStatus === orderStatuses.draft) {
    return <Redirect to={`/orders${orderUid ? `/${orderUid}` : ''}`} />;
  }

  return (
    <>
      <SeoHelmet title={pages.orderThanksPage.title} />
      <ProcessStepper
        steps={orderProcessSteps}
        current={3}
      />
      <Card className={style.container}>
        <HappyTooth className={style.icon} />
        <h2>Congratulations!</h2>
        <p>Your order has been placed.</p>
        <Button
          label={`Go to ${orderUid ? 'order' : 'order list'}`}
          to={`/orders${orderUid ? `/${orderUid}` : ''}`}
        />
      </Card>
    </>
  );
};

export default OrderThanksPage;
