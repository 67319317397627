import React, { useState } from 'react';

import { Button, Modal } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import InvoiceForm from 'src/features/Invoices/components/InvoiceForm';
import UploadAttachment from 'src/features/orderConfirmations/components/UploadAttachment';
import { toDateNumber } from 'src/utils/dateTime';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from '../../OrderCart.module.scss';

const TableHeader = ({ suborderUid, supplierId, refreshDataCallback, orderValue, publicId, createdAt, clientName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const downloadFileHandler = async () => {
    const queryData = {
      suborder_uid: suborderUid,
      file_format: 'XLSX'
    };

    try {
      setIsLoading(true);
      const { data } = await SupplierApi.exportSuborderProducts(queryData);
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, `Zamówienie #${publicId} - ${clientName} ${toDateNumber(createdAt)}.xlsx`);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className={style.sectionTop}>
        <h2 className={style.title}>Order details</h2>
        <Button
          onClick={() => setIsModalOpen(true)}
          label='Add attachment'
          empty
        />
        <Button
          onClick={downloadFileHandler}
          label='Download order'
          isLoading={isLoading}
          gray
        />
        <Button
          onClick={() => setIsInvoiceModalOpen(true)}
          label='Add invoice'
        />
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title='Dodaj załączniki'
      >
        <UploadAttachment
          uid={suborderUid}
          supplierId={supplierId}
          refreshDataCallback={refreshDataCallback}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
      <Modal
        visible={isInvoiceModalOpen}
        onClose={() => setIsInvoiceModalOpen(false)}
        title='Dodaj fakturę'
      >
        <InvoiceForm
          orderValue={orderValue}
          refreshDataCallback={refreshDataCallback}
          apiHandler={SupplierApi.uploadSupplierInvoice}
          closeModal={() => setIsInvoiceModalOpen(false)}
          suborderUid={suborderUid}
          supplierId={supplierId}
        />
      </Modal>
    </>
  );
};

export default TableHeader;
