import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  ColumnList,
  EmptyState,
  FilterByCompany,
  PaginationNav,
  Searchbar,
  SeoHelmet,
  StickyPageHeader,
  SwitchButton,
  useIsAdmin,
  useIsMobile,
  useRequestAbortController
} from 'components';
import FilterBySupplier from 'components/layout/filtering/FilterBySupplier';
import { notifyApiError } from 'components/layout/Toasts';

import { ValuationsApi } from 'src/api';
import { SupplierApi } from 'src/api';
import { valuationsTypes } from 'src/constants/enums';
import pages from 'src/dictionaries/pages.json';
import query from 'src/utils/query';

import SupplierValuationThumbnail from './components/SupplierValuationThumbnail';
import ValuationThumbnail from './components/ValuationThumbnail';

import style from './Valuations.module.scss';

const Valuations = (props) => {
  const params = query(props);
  const isAdmin = useIsAdmin();
  const location = useLocation();
  const isMobile = useIsMobile();
  const [pageData, setPageData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [querySearch, setQuerySearch] = useState();
  const [queryCompany, serQueryCompany] = useState();
  const [querySupplier, setQuerySupplier] = useState();
  const [abortController, setNewController] = useRequestAbortController();
  const [areCancelledHidden, setAreCancelledHidden] = useState(props.type === valuationsTypes.user);

  const valuationsVersion = useSelector((state) => state.valuationView.valuationsVersion);

  const getCorrectRequest = useCallback(
    async (params, signal) =>
      props.type === valuationsTypes.supplier
        ? await SupplierApi.getValuations(params, signal)
        : await ValuationsApi.getValuations(params, signal),
    [props.type]
  );

  const getData = async () => {
    let error;
    const params = {
      page: currentPage || 1,
      hide: +areCancelledHidden,
      perPage: perPage,
      search: querySearch,
      company_id: queryCompany,
      supplier_user_id: querySupplier
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await getCorrectRequest(params, signal);
      setPageData(data);
      setPageQty(data?.last_page);
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  const getEmptyStateType = () => {
    if (params.get('search')) {
      return 'search';
    } else if (props.type === valuationsTypes.supplier) {
      return 'supplierValuations';
    } else {
      return 'valuations';
    }
  };

  useEffect(() => {
    setAreCancelledHidden(props.type === valuationsTypes.user);
  }, [props.type]);

  useEffect(() => {
    getData();
  }, [currentPage, perPage, valuationsVersion, querySearch, queryCompany, querySupplier, areCancelledHidden, location.pathname]);

  useEffect(() => {
    setCurrentPage(params.get('page'));
    setQuerySearch(params.get('search'));
    setQuerySupplier(params.get('supplier'));
    serQueryCompany(params.get('company'));
  }, [params]);

  const thumbnailComponent = useMemo(() => {
    switch (props.type) {
      case valuationsTypes.user:
        return ValuationThumbnail;
      case valuationsTypes.supplier:
        return SupplierValuationThumbnail;
      default:
        return ValuationThumbnail;
    }
  }, [props.type]);

  const pageName = useMemo(
    () => (props.type === valuationsTypes.supplier && isAdmin ? pages.supplierValuations.title : pages.valuations.title),
    [isAdmin, props]
  );

  return (
    <div className={style.container}>
      <SeoHelmet title={pageName} />
      <StickyPageHeader name={pageName}>
        <>
          {props.type === valuationsTypes.user && (
            <SwitchButton
              value={areCancelledHidden}
              setValue={setAreCancelledHidden}
              label={'Hide canceled'}
            />
          )}
          <Searchbar params={params} />
          <FilterByCompany params={params} />
          {props.type === valuationsTypes.supplier && <FilterBySupplier params={params} />}
        </>
      </StickyPageHeader>
      <ColumnList
        isLoading={isLoading}
        list={pageData?.data}
        component={thumbnailComponent}
        emptyState={<EmptyState type={getEmptyStateType()} />}
      />
      <PaginationNav
        onClick={(number) => setCurrentPage(number)}
        params={params}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
      />
    </div>
  );
};

export default Valuations;
