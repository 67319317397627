import React from 'react';

import { supplierTypes } from 'src/constants/enums';

import SupplierDropzone from '../SupplierDropzone';

import style from '../../SuppliersList.module.scss';

const DropzoneArea = ({ product, isDragged, setIsDragged }) => {
  const primary = product.prices.find((sup) => sup.priority === supplierTypes.primary);
  const secondary = product.prices.find((sup) => sup.priority === supplierTypes.secondary);
  const third = product.prices.find((sup) => sup.priority === supplierTypes.third);

  return (
    <div className={style.header}>
      <div className={style.box}>
        <h4>Preferred offer</h4>
        <SupplierDropzone
          priority={supplierTypes.primary}
          setIsDragged={setIsDragged}
          selectedSupplier={primary}
          isDragged={isDragged}
          product={product}
        />
      </div>
      <div className={style.box}>
        <h4>Alternative offer</h4>
        <SupplierDropzone
          disabled={!primary}
          priority={supplierTypes.secondary}
          selectedSupplier={secondary}
          setIsDragged={setIsDragged}
          isDragged={isDragged}
          product={product}
        />
      </div>
      <div className={style.box}>
        <h4>Backup offer</h4>
        <SupplierDropzone
          disabled={!secondary}
          priority={supplierTypes.third}
          selectedSupplier={third}
          setIsDragged={setIsDragged}
          isDragged={isDragged}
          product={product}
        />
      </div>
    </div>
  );
};

export default DropzoneArea;
