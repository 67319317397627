import React, { forwardRef, useState } from 'react';
import cn from 'classnames';
import classnames from 'classnames';

import { CustomTooltip, Icon, PricesCounter, QtySelector } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { ReactComponent as Plus } from 'src/assets/icons/add_to_list.svg';
import { scrollToElementAndReturnPromise } from 'src/utils/helpers';

import style from './SearchThumbnail.module.scss';

const SearchThumbnail = React.memo(
  forwardRef(
    (
      {
        thumbnail,
        title,
        subtitle,
        onClick,
        productId,
        onClear,
        hideElement,
        isLoading,
        closeSearch,
        hideQty = false,
        image,
        unitName,
        pricesCount
      },
      ref
    ) => {
      const [qty, setQty] = useState(1);

      const handleClick = () => {
        if (onClear) {
          onClear();
        } else if (onClick) {
          onClick(qty);
        }
      };

      const handleScrollToProduct = () => {
        if (productId) {
          const product = document.getElementById(`list-product-${productId}`);
          if (product) {
            if (closeSearch) closeSearch();
            scrollToElementAndReturnPromise(product).then(() => product.focus());
          }
        }
      };

      return (
        <div
          className={classnames(style.container, {
            [style.hidden]: hideElement,
            [style.loading]: isLoading
          })}
        >
          <div className={style.box}>
            <ProductThumbnailImage
              thumbnail={thumbnail}
              image={image}
              title={title}
              id={productId}
              ref={ref}
            />
            <div className={style.wrapper}>
              <h2 className={style.title}>{title}</h2>
              <h3 className={style.subtitle}>{subtitle}</h3>
            </div>
            <PricesCounter count={pricesCount} />
          </div>
          {hideElement ? (
            <button
              className={style.hiddenInfo}
              onClick={handleScrollToProduct}
            >
              Show product on list
            </button>
          ) : (
            <>
              {!hideQty ? (
                <QtySelector
                  className={style.qtySelect}
                  setState={setQty}
                  suffix={unitName}
                  state={qty}
                  width={120}
                  wrapperStyle={style.qtySelector}
                />
              ) : (
                <span />
              )}
              <CustomTooltip title={Number(qty) === 0 ? 'Nie można zapisać przedmiotu z ilością równą 0!' : undefined}>
                <button
                  onClick={handleClick}
                  disabled={Number(qty) === 0}
                  className={cn(style.button, style.visible, {
                    [style.disabled]: Number(qty) === 0,
                    [style.onClear]: !!onClear
                  })}
                >
                  {onClear ? (
                    <Icon
                      name={'trash'}
                      fill={'#fff'}
                      size={20}
                    />
                  ) : (
                    <Plus />
                  )}
                </button>
              </CustomTooltip>
            </>
          )}
        </div>
      );
    }
  )
);

export default SearchThumbnail;
