import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { LabelWithValue, Menu } from 'components';

import style from './ThumbnailPlaceholder.module.scss';

const ThumbnailPlaceholder = () => (
  <div className={style.container}>
    <Skeleton
      width={130}
      height={130}
      className={style.placeholderImg}
    />
    <div className={style.box}>
      <div className={style.header}>
        <Skeleton
          width={90}
          height={10}
        />
        <Skeleton
          width={170}
          height={20}
        />
      </div>
      <div className={style.content}>
        <LabelWithValue
          label={'Purchase date'}
          value={
            <Skeleton
              width={100}
              height={20}
            />
          }
          labelClasses={style.label}
        />
        <LabelWithValue
          label={'Next service'}
          value={
            <Skeleton
              width={100}
              height={20}
            />
          }
          labelClasses={style.label}
        />
      </div>
    </div>
    <Menu
      actions={[]}
      disabled
      className={style.menu}
    />
  </div>
);

export default ThumbnailPlaceholder;
