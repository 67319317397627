import React, { useState } from 'react';
import { Popover } from 'antd';

import { Button, Select } from 'components';

import style from './ManageManyElements.module.scss';

const ManageManyElements = (props) => {
  const {
    setAreManagementEnabled,
    areManagementEnabled,
    executeButtonLabel,
    openButtonLabel,
    selectedElements,
    options,
    resetHandler
  } = props;
  const [selectedValue, setSelectedValue] = useState(options[0] || null);
  const selectedCount = selectedElements.length;

  if (!areManagementEnabled) {
    return (
      <div className={style.container}>
        <Button
          onClick={() => setAreManagementEnabled(true)}
          label={openButtonLabel}
        />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <Select
        placeholder={'Select option'}
        wrapperStyle={style.select}
        value={selectedValue}
        onChange={setSelectedValue}
        isSearchable={false}
        isClearable={false}
        options={options}
      />
      <Popover
        content={selectedCount ? undefined : <p>Select items to perform an action</p>}
        trigger={'hover'}
      >
        <div>
          <Button
            onClick={selectedValue ? () => selectedValue.onClick(selectedElements) : undefined}
            label={executeButtonLabel + ` ${selectedCount}`}
            disabled={selectedCount === 0}
          />
        </div>
      </Popover>
      <Button
        onClick={resetHandler}
        label={'Close'}
        gray
      />
    </div>
  );
};

export default ManageManyElements;
