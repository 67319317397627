import { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { getFormattedAmount } from 'src/utils/helpers';

const PaymentsSummary = ({ data, isLoading }) => {
  const [amount, setAmount] = useState(0);

  const sum = useMemo(() => {
    if (data.length < 1) return;
    const amounts = data.map((item) => item.payment_sum);
    return amounts.reduce((a, b) => a + b, 0);
  }, [data]);

  useEffect(() => {
    setAmount(sum);
  }, [sum]);

  return (
    <>
      <p>Total payments</p>
      {isLoading ? <Skeleton /> : <h3>{amount > 0 ? getFormattedAmount(amount) : '-'}</h3>}
    </>
  );
};

export default PaymentsSummary;
