import { useState } from 'react';

import Button from 'components/layout/Button';
import Modal from 'components/layout/Modal';

import style from './ModalConfirm.module.scss';

export default function ModalConfirm({
  message = 'Czy chcesz wykonać akcję?',
  cancelButtonName = 'Cancel',
  confirmButtonName = 'Zatwierdź',
  content = null,
  visible,
  setVisible,
  onConfirm,
  onCancel
}) {
  const [isLoading, setIsLoading] = useState(false);
  const confirmHandler = async () => {
    if (onConfirm) {
      setIsLoading(true);
      await onConfirm();
      setIsLoading(false);
    }
    setVisible(false);
  };

  const cancelHandler = async () => {
    if (onCancel) {
      setIsLoading(true);
      await onCancel();
      setIsLoading(false);
    }
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title={message}
      onClose={() => setVisible(false)}
      className={style.modal}
    >
      {content}
      <div className={style.buttonsWrapper}>
        <Button
          onClick={cancelHandler}
          label={cancelButtonName}
          gray
        />
        <Button
          onClick={confirmHandler}
          label={confirmButtonName}
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
}
