import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAtomValue } from 'jotai';

import { LabelWithValue, Menu, Modal, RelativeTime, useIsAdmin, useModalConfirm } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import imagePlaceholder from 'src/assets/img/product-thumbnail.png';
import { selectedCompanyAtom } from 'src/features/Services';
import { toDateNumber } from 'src/utils/dateTime';
import { imageErrorHandler } from 'src/utils/helpers';

import { refreshServices } from '../../actions';
import AddServiceForm from '../AddServiceForm';

import style from './ServiceThumbnail.module.scss';

export const getTimeDiff = (date) => {
  const parsed = new Date(date);
  return Math.floor((Date.now() - parsed.getTime()) / 1000);
};

const ServiceThumbnail = ({ service }) => {
  const isAdmin = useIsAdmin();
  const selectedCompany = useAtomValue(selectedCompanyAtom);

  const dispatch = useDispatch();
  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const { id, model, name, purchased_at, next_service_at, logo } = service;

  const deleteService = async () => {
    try {
      const { data } = await ServicesApi.deleteService(id);
      dispatch(refreshServices());
      notifyCommon([data.message]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteServiceConfirm = () => {
    handleOpenModalConfirm({
      message: `Na pewno chcesz usunąć ${name}?`,
      handleConfirm: deleteService
    });
  };

  const actions = [
    {
      title: 'Edit',
      icon: 'edit',
      action: () => setIsVisibleEdit(true)
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: deleteServiceConfirm
    }
  ];

  const saveHandler = () => {
    setIsVisibleEdit(false);
    dispatch(refreshServices());
  };

  return (
    <div className={style.container}>
      <img
        src={logo || imagePlaceholder}
        alt={'alt'}
        className={style.img}
        loading={'lazy'}
        onError={imageErrorHandler}
      />
      <Link
        className={style.box}
        to={isAdmin && selectedCompany ? `/services/${id}?company=123` : `/services/${id}`}
      >
        <div className={style.header}>
          <p className={style.id}>{model}</p>
          <h3 className={style.title}>{name}</h3>
        </div>
        <div className={style.content}>
          <LabelWithValue
            label={'Purchase date'}
            value={purchased_at ? toDateNumber(purchased_at) : '-'}
            labelClasses={style.label}
          />
          <LabelWithValue
            label={'Next service'}
            labelClasses={style.label}
            value={
              <RelativeTime
                date={next_service_at}
                hideTime
              />
            }
            dangerValue={getTimeDiff(next_service_at) < 0}
            dangerWhenFalsy
          />
        </div>
      </Link>
      <Menu
        actions={actions}
        className={style.menu}
      />
      <Modal
        visible={isVisibleEdit}
        onClose={() => setIsVisibleEdit(false)}
        title={'Edytuj serwis'}
      >
        <AddServiceForm
          serviceData={service}
          onClose={saveHandler}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default ServiceThumbnail;
