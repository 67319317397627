import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import {
  AsyncSelect,
  Button,
  Checkbox,
  EmptyState,
  Loader,
  PaginationNav,
  ProductListThumbnail,
  StateSearchbar,
  useRequestAbortController
} from 'components';
import { notifyApiError, notifyCommon, notifyDanger } from 'components/layout/Toasts';

import { AdminApi, ProductsApi } from 'src/api';
import { resetWrapperScrollPosition } from 'src/utils/helpers';

import { refreshCategories } from '../../actions';

import style from './MoveSelectedProducts.module.scss';

const MoveSelectedProducts = ({ id }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isMoveLoading, setIsMoveLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [querySearch, setQuerySearch] = useState('');
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [abortController, setNewController] = useRequestAbortController();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const getData = async () => {
    let error;
    const queryProps = {
      recursive: 0,
      page: currentPage || 1,
      perPage: perPage,
      search: querySearch,
      category_id: id
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await ProductsApi.getProducts(queryProps, signal);
      setProducts(data.data);
      setSelectedProducts([]);
      setPageQty(data.last_page);
      setIsLoading(false);
      resetWrapperScrollPosition();
    } catch (err) {
      notifyApiError(err);
      error = err;
    } finally {
      setIsLoading(error?.message === 'canceled');
    }
  };

  useEffect(() => {
    getData();
  }, [currentPage, querySearch, perPage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedProducts.length === 0) return notifyDanger(['Wybierz produkty aby je przenieść.']);
    if (!selectedCategory) return notifyDanger(['Wybierz kategorię aby przenieść produkty']);

    const data = {
      category_id_from: id,
      category_id_to: selectedCategory?.value,
      product_ids: selectedProducts.map((product) => product.id)
    };

    try {
      setIsMoveLoading(true);
      await AdminApi.moveCategoryProducts(data);
      notifyCommon([`Przeniesiono ${selectedProducts.length} produktów do ${selectedCategory.label}`]);
      dispatch(refreshCategories());
      getData();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsMoveLoading(false);
    }
  };

  const onClickAll = () => {
    setSelectedProducts(selectedProducts.length === products.length ? [] : products);
  };

  const selectProduct = (prod) => {
    if (selectedProducts.find((product) => product.id === prod.id)) {
      setSelectedProducts((prev) => prev.filter((product) => product.id !== prod.id));
    } else {
      setSelectedProducts((prev) => [...prev, prod]);
    }
  };

  if (!isLoading && !querySearch && products.length === 0) {
    return (
      <div className={style.container}>
        <EmptyState
          type={'categoryProducts'}
          className={style.empty}
        />
      </div>
    );
  }

  return (
    <div className={style.container}>
      <form
        className={style.header}
        onSubmit={handleSubmit}
      >
        <AsyncSelect
          value={selectedCategory}
          onChange={setSelectedCategory}
          apiCallback={ProductsApi.getCategories}
          valueKey={'value'}
          labelKey={'label'}
          placeholder={'Kategoria'}
          label={'Wybierz kategorię do której chcesz przenieść produkty'}
          isClearable
          showAsTree={false}
          queryParams={{ pagination: 1 }}
        />
        <Button
          type={'submit'}
          label={`Przenieś produkty (${selectedProducts.length})`}
          isLoading={isMoveLoading}
        />
      </form>
      <div
        className={classNames(style.wrapper, { [style.loading]: isLoading })}
        id={'category-product-wrapper'}
      >
        <div className={style.checkbox}>
          <StateSearchbar
            state={querySearch}
            setState={setQuerySearch}
          />
          <Checkbox
            value={selectedProducts.length === products.length}
            onChange={onClickAll}
            content={'Select all'}
            reverse
          />
        </div>
        {products.map((prod) => (
          <ProductListThumbnail
            product={prod}
            key={prod.id}
            isSelected={!!selectedProducts.find((product) => product.id === prod.id)}
            onChangeSelect={() => selectProduct(prod)}
          />
        ))}
        {!isLoading && products.length === 0 && <EmptyState type={'search'} />}
        {isLoading && <Loader className={style.loader} />}
      </div>
      <PaginationNav
        className={style.pagination}
        setPage={setCurrentPage}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
        page={currentPage}
        hideSelect
      />
    </div>
  );
};

export default MoveSelectedProducts;
