import React, { useState } from 'react';
import classNames from 'classnames';

import { QtySelector, SaveButton, useIsMobile, useValidator } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';
import { notifyApiError } from 'components/layout/Toasts';

import { SupplierApi } from 'src/api';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning.svg';
import replacementThumbnail from 'src/assets/img/replacement-thumbnail.png';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './Product.module.scss';

const Product = ({ product, type, hideStatusColumn, uid, refreshDataCallback }) => {
  const {
    name,
    producer,
    unit_name,
    old_quantity,
    new_quantity,
    price_for_one,
    thumbnail,
    image,
    status,
    id,
    type: productType
  } = product || {};

  const [isCancelLoading, setIsCancelLoading] = useState(false);
  const [newQuantity, setNewQuantity] = useState(new_quantity);
  const [isLoading, setIsLoading] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const isLgScreen = useIsMobile(1500);
  const validator = useValidator();

  if (isHide) {
    return null;
  }

  const handleSaveQty = async (finally_quantity, type) => {
    const query = {
      uid,
      products: [{ id: product.id, quantity: finally_quantity }]
    };

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsCancelLoading(type === 'cancel');
      setIsLoading(type !== 'cancel');
      await SupplierApi.acceptProductQuantity(query);
      setIsHide(true);
      refreshDataCallback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      setIsCancelLoading(false);
    }
  };

  const wrapperClasses = classNames(style.wrapper, style.productName, {
    [style.hideStatusColumn]: hideStatusColumn
  });

  return (
    <div
      className={classNames(style.container, {
        [style.hideStatusColumn]: hideStatusColumn,
        [style.cancelled]: status === 'CANCELLED',
        [style.replacement]: productType === 'replacement',
        [style.offer]: productType === 'offer'
      })}
    >
      <div className={wrapperClasses}>
        <ProductThumbnailImage
          thumbnail={type === 'replacement' ? replacementThumbnail : thumbnail}
          image={type === 'replacement' ? null : image}
          title={name}
          id={id}
        />
        <p className={classNames(style.text, style.trim)}>
          {type === 'replacement' && (
            <span className={classNames(style.info, style.replacement)}>
              <WarningIcon />
              Zamiennik
            </span>
          )}
          {type === 'offer' && <span className={classNames(style.info, style.offer)}>Oferta dostawcy</span>}
          {name}
        </p>
      </div>
      <div className={classNames(style.wrapper, style.producer)}>
        {isLgScreen && <p>Producer: </p>}
        <p className={style.text}>{producer}</p>
      </div>
      <div className={classNames(style.wrapper, style.valuationQty)}>
        {isLgScreen && <p>Valuated: </p>}
        <p className={style.text}>{`${old_quantity} ${unit_name || ''}`}</p>
      </div>
      <div className={classNames(style.wrapper, style.orderedQty)}>
        {isLgScreen && <p>Zamówiono: </p>}
        <p className={style.text}>{`${new_quantity} ${unit_name || ''}`}</p>
      </div>
      <div className={classNames(style.wrapper, style.finallyQty)}>
        {isLgScreen && <p>Do realizacji: </p>}
        <QtySelector
          id={product.id + '-new-qty'}
          name={product.id + '-new-qty'}
          state={newQuantity}
          setState={setNewQuantity}
          suffix={unit_name}
          width={110}
          validator={validator}
          rule={`required|min:${old_quantity},num|max:${new_quantity},num`}
        />
      </div>
      <div className={classNames(style.wrapper, style.forOne)}>
        {isLgScreen && <p>Cena za szt.: </p>}
        <p className={style.text}>{getFormattedAmount(price_for_one)}</p>
      </div>
      <div className={classNames(style.wrapper, style.save)}>
        <SaveButton
          isLoading={isLoading}
          onClick={() => handleSaveQty(newQuantity, 'accept')}
        />
      </div>
      <div className={classNames(style.wrapper, style.cancel)}>
        <SaveButton
          isLoading={isCancelLoading}
          type={'cancel'}
          onClick={() => handleSaveQty(old_quantity, 'cancel')}
        />
      </div>
    </div>
  );
};

export default Product;
