import React, { useEffect, useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plus-blue.svg';
import { AddressDetails, AddressForm, Card, Modal } from 'components';

import Invoice from '../../../Profile/features/Company';

import style from './Address.module.scss';
import shared from 'styles/Shared.module.scss';

const Address = React.forwardRef(({ setShippingAddressId, company }, ref) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddressFormOpen, setIsAddressFormOpen] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState(null);

  const addNewAddressButton = (
    <button
      className={style.addNew}
      onClick={() => setIsAddressFormOpen(true)}
    >
      <PlusIcon />
      Dodaj nowy adres
    </button>
  );

  useEffect(() => {
    setShippingAddressId(deliveryAddress?.id);
  }, [deliveryAddress]);

  useEffect(() => {
    if (!deliveryAddress && company?.shipping_details) {
      const defaultAddress = company?.shipping_details?.find((address) => address.is_default);
      setDeliveryAddress(defaultAddress || company?.shipping_details[0]);
    }
  }, [company]);

  if (!company) {
    return (
      <Card className={shared.defaultWrapper}>
        <h2>Przepraszamy,</h2>
        <p>Aktualnie administrator nie może składać zamówień.</p>
      </Card>
    );
  }

  return (
    <div className={style.container}>
      <div
        className={style.column}
        ref={ref}
      >
        <h2>Shipping address</h2>
        <p>Select the address where the products are to be shipped.</p>
        {deliveryAddress ? (
          <AddressDetails
            details={deliveryAddress}
            companyId={company?.id}
          />
        ) : (
          addNewAddressButton
        )}
        {company?.shipping_details?.length > 0 && (
          <a
            onClick={() => setIsModalOpen(true)}
            className={style.chooseAddress}
          >
            Select another address
          </a>
        )}
      </div>
      <div className={style.column}>
        <h2>Invoice data</h2>
        <p>Orders will be placed on behalf of the company for which the valuation was prepared.</p>
        <Invoice company={company} />
      </div>
      <Modal
        title={'Wybierz adres dostawy'}
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <div className={style.wrapper}>
          {company?.shipping_details?.map((address) => (
            <AddressDetails
              details={address}
              companyId={company?.id}
              key={address.id}
              onClick={() => {
                setDeliveryAddress(address);
                setIsModalOpen(false);
              }}
            />
          ))}
          {addNewAddressButton}
        </div>
      </Modal>
      <Modal
        visible={isAddressFormOpen}
        onClose={() => setIsAddressFormOpen(false)}
        title={'Dodaj nowy adres'}
      >
        <AddressForm
          closeModal={() => setIsAddressFormOpen(false)}
          companyId={company?.id}
        />
      </Modal>
    </div>
  );
});

export default Address;
