import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { OrderOrValuationId, ProgressLine, Select, Tag } from 'components';
import InfoWidget from 'components/layout/InfoWidget';
import ShipmentPriceInfo from 'components/layout/ShipmentPriceInfo';

import { getSumOfProducts } from 'src/features/ValuationView/helpers';
import { getFormattedAmount } from 'src/utils/helpers';

import CartSummary from '../CartSummary';
import ProductsList from './components/ProductsList';

import style from '../../ValuationView.module.scss';
import shared from 'styles/Shared.module.scss';

const HorizontalList = ({ columns, pageData, changeValuationSelections, showRefPrice }) => {
  const savings = useSelector((state) => state.valuationView.savings);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const { sum } = savings;

  const getOrderSummary = useMemo(() => {
    let productCount = 0;
    let totalSumCount = 0;
    let shippingPrice = 0;
    let supplierSum = {};

    pageData.categories.forEach((cat) => {
      cat.products.forEach((prod) => {
        prod?.prices.forEach((price) => {
          if (price.selected) {
            productCount += 1;
            totalSumCount += price?.price;
            if (supplierSum[price.supplier_id]) {
              supplierSum[price.supplier_id] = supplierSum[price.supplier_id] + price?.price;
            } else {
              supplierSum[price.supplier_id] = price?.price;
            }
          }
        });
      });
    });

    pageData.suppliers.forEach((sup) => {
      if (supplierSum[sup.id] && sup.free_shipment_price > supplierSum[sup.id]) {
        shippingPrice += sup.shipment_price;
      }
    });

    return {
      productCount,
      totalSumCount,
      shippingPrice
    };
  });

  const savingsPercentages = useMemo(
    () => ((+sum / (+getOrderSummary.totalSumCount + +sum)) * 100).toFixed(2),
    [sum, getOrderSummary]
  );

  const refPriceSummary = useMemo(() => {
    let sum = 0;
    let percentages;

    pageData.categories.forEach((cat) => {
      cat.products.forEach((prod) => {
        const x = prod?.prices.find((x) => x.selected);
        const y = +prod.ref_price - +x?.price;
        sum += y > 0 ? y : 0;
      });
    });

    percentages = ((+sum / (+getOrderSummary.totalSumCount + +sum)) * 100).toFixed(2);

    return { percentages, sum };
  }, [pageData, getOrderSummary]);

  const filterOptions = useMemo(
    () =>
      pageData?.categories.map((cat) => ({
        label: cat.name,
        value: cat.id
      })) || [],
    [pageData]
  );

  return (
    <div className={classNames(style.leftColumn, style.horizontal)}>
      <div className={classNames(style.optionsWrapper, style.horizontal)}>
        <div className={style.optionsInfo}>
          <span className={style.listCommentWrapper}>
            <OrderOrValuationId
              id={pageData.public_id}
              withPrefix={'Valuation summary'}
              className={style.text}
            />
            {!!pageData?.list_comment && (
              <InfoWidget>
                <p>Dodano komentarz do wyceny:</p>
                <p>{pageData.list_comment}</p>
              </InfoWidget>
            )}
          </span>
          <span className={style.priceWrapper}>
            <h2 className={style.orderPrice}>{getFormattedAmount(getOrderSummary.totalSumCount)}</h2>
            <ShipmentPriceInfo
              shippingPrice={getOrderSummary.shippingPrice}
              className={style.shippingPrice}
            />
            {((!showRefPrice && !!+sum) || (showRefPrice && !!refPriceSummary.sum)) && (
              <div className={style.savings}>
                <Tag
                  className={style.tag}
                  color={!showRefPrice ? 'green' : showRefPrice ? 'yellow' : ''}
                  value={
                    showRefPrice
                      ? `Savings against the list: ${getFormattedAmount(refPriceSummary.sum)} (${refPriceSummary.percentages}%)`
                      : `Total savings: ${getFormattedAmount(sum)} (${savingsPercentages}%)`
                  }
                />
              </div>
            )}
          </span>
          <ProgressLine
            className={style.progress}
            maxValue={getSumOfProducts(columns)}
            currentValue={getOrderSummary.productCount}
            prefix={'selected'}
            alignToLeft
            fullWidth
          />
        </div>
        <CartSummary
          columns={columns}
          isHorizontal
        />
      </div>
      <section
        className={classNames(shared.tableWrapper, style.tableWrapper, style.horizontal)}
        id={'valuation-table'}
      >
        <div className={classNames(shared.tableHeader, style.header)}>
          {isFilterEnabled ? (
            <Select
              options={filterOptions}
              value={selectedCategories}
              label={'Filtruj kategorie'}
              placeholder={'Wybierz kategorie, które chcesz przeglądać...'}
              onChange={setSelectedCategories}
              isMulti
            />
          ) : (
            <a
              className={style.link}
              onClick={() => setIsFilterEnabled(true)}
            >
              Filtruj kategorie
            </a>
          )}
        </div>
        <ProductsList
          pageData={pageData}
          productClasses={style.product}
          changeValuationSelections={changeValuationSelections}
          status={pageData?.status}
          showRefPrice={showRefPrice}
          selectedCategories={selectedCategories}
        />
      </section>
    </div>
  );
};

export default HorizontalList;
