import React, { useMemo, useState } from 'react';

import { Button, Input, PhoneInput, QtySelector, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import style from './ThresholdForm.module.scss';

const defaultThreshold = {
  first_name: '',
  last_name: '',
  threshold: 0,
  email: '',
  phone: ''
};

const ThresholdForm = ({ threshold, thresholds = [], closeModal, refreshData }) => {
  const validator = useValidator();

  const [data, setData] = useState(threshold || defaultThreshold);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onThresholdChange = (value) => {
    setData((prev) => ({
      ...prev,
      threshold: value
    }));
  };
  const onPhoneChange = (value) => {
    setData((prev) => ({
      ...prev,
      phone: value
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const ApiCallback = data?.id ? CompanyApi.editThreshold : CompanyApi.addThreshold;

    try {
      setIsLoading(true);
      await ApiCallback({ ...data, threshold: +data.threshold });
      await refreshData();
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const thresholdRules = useMemo(() => {
    const range = thresholds
      .filter((item) => (data?.id ? item.id !== data.id : true))
      .map((i) => i.threshold.toFixed(2))
      .join(',');
    return `required|not_negative|not_in:${range}`;
  }, [data]);

  return (
    <form
      className={style.container}
      onSubmit={submitHandler}
    >
      <div className={style.row}>
        <Input
          value={data.first_name}
          onChange={onChange}
          label={'Imię'}
          name={'first_name'}
          id={'first_name'}
          validator={validator}
          rule={'required'}
        />
        <Input
          value={data.last_name}
          onChange={onChange}
          label={'Nazwisko'}
          name={'last_name'}
          id={'last_name'}
          validator={validator}
          rule={'required'}
        />
      </div>
      <Input
        value={data.email}
        onChange={onChange}
        label={'E-mail'}
        name={'email'}
        id={'email'}
        validator={validator}
        rule={'required|email'}
      />
      <PhoneInput
        id='phone'
        name='phone'
        label='Telefon kontaktowy'
        value={data.phone}
        onChange={onPhoneChange}
        validator={validator}
        rule={'phone'}
      />
      <QtySelector
        id={'threshold'}
        name={'threshold'}
        label={'Minimalna wartość zamówienia'}
        suffix={'PLN'}
        state={data.threshold}
        setState={onThresholdChange}
        validator={validator}
        rule={thresholdRules}
        width={'100%'}
        hideArrows
        priceInput
      />
      <Button
        label={'Zapisz'}
        className={style.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default ThresholdForm;
