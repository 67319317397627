import React from 'react';

import { Modal } from 'components';

import DistributorsModal from '../../components/DistributorsModal';
import HideValuationsModal from '../../components/HideValuationsModal';
import VerticalValuationWrapper from '../../components/VerticalValuationWrapper';

const VerticalValuation = (props) => {
  const {
    pageData,
    columns,
    changeValuationSelections,
    isModalOpen,
    setIsModalOpen,
    uid,
    isHidingModalOpen,
    setIsHidingModalOpen
  } = props;

  return (
    <>
      <VerticalValuationWrapper
        pageData={pageData}
        columns={columns}
        changeValuationSelections={changeValuationSelections}
      />
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Add supplier'}
      >
        <DistributorsModal
          closeModal={() => setIsModalOpen(false)}
          excludedSuppliers={pageData.suppliers}
          uid={uid}
        />
      </Modal>
      <Modal
        visible={isHidingModalOpen}
        onClose={() => setIsHidingModalOpen(false)}
        title={'Ukryj kolumny'}
      >
        <HideValuationsModal
          distributors={pageData.available_columns}
          uid={uid}
        />
      </Modal>
    </>
  );
};

export default VerticalValuation;
