import React from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import cn from 'classnames';

import { Icon, useIsMobile } from 'components';

import { ReactComponent as Menu } from 'src/assets/icons/sidebar/menu.svg';

import style from './StickyPageHeader.module.scss';

const StickyPageHeader = ({
  name,
  children,
  filtering,
  filteringOnlyPopover = false,
  noWrap = false,
  className,
  containerClasses,
  buttonsWrapperClasses,
  additionalFiltering
}) => {
  const isMobile = useIsMobile();
  const { isAdminView } = useSelector((state) => state.auth);

  const childrenWrapper = (children) => <div className={style.content}>{children}</div>;

  const mobileChildren = children ? (
    <Popover
      content={childrenWrapper(children)}
      placement={'bottomRight'}
      trigger={'click'}
    >
      <div>
        <Menu />
      </div>
    </Popover>
  ) : null;

  const mobileFiltering = filtering ? (
    <Popover
      content={childrenWrapper(filtering)}
      placement={'bottom'}
      trigger={'click'}
    >
      <button>
        <Icon
          name={'filter'}
          size={24}
          fill={'#6A798B'}
        />
      </button>
    </Popover>
  ) : null;

  return (
    <>
      <section className={cn(style.container, containerClasses, isAdminView && style.container_adminView)}>
        <header className={cn(style.header, className)}>
          <h2>{name}</h2>
          <div className={cn(style.buttonWrapper, buttonsWrapperClasses)}>
            {isMobile && !noWrap && mobileFiltering}
            {isMobile && !noWrap ? mobileChildren : children}
          </div>
        </header>
        {!isMobile && !filteringOnlyPopover && filtering}
      </section>
      {additionalFiltering && <div className={style.additionalActions}>{additionalFiltering}</div>}
    </>
  );
};

export default StickyPageHeader;
