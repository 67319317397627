import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAtomValue } from 'jotai';

import { Button, EmptyState, Icon, Modal, PaginationNav, Table, useCompany, useIsAdmin, useLocalStorage } from 'components';
import styles from 'components/layout/Modal/Modal.module.scss';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Services';

import CreateServiceForm from './components/CreateServiceForm';
import ServicerThumbnail from './components/ServicerThumbnail';

import thumbnailStyles from './components/ServicerThumbnail/ServicerThumbnail.module.scss';
import style from './ServicersList.module.scss';

const ServicersList = ({ onClose }) => {
  const isAdmin = useIsAdmin();
  const selectedCompany = useAtomValue(selectedCompanyAtom);

  const company = useCompany();
  const [total, setTotal] = useState(1);
  const [pageQty, setPageQty] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [servicers, setServicers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [servicersCount, setServicersCount] = useLocalStorage(`servicersCount-${company?.id ?? 'admin'}`, 0);

  const getServicers = async () => {
    const params = {
      page: currentPage ?? 1,
      perPage,
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      const { data } = await ServicesApi.getServicersList(params);
      setServicers(data.data);
      setServicersCount(data.data.length);
      setPageQty(data.last_page);
      setTotal(data.total);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServicers();
  }, [perPage, currentPage]);

  const columns = [{ name: 'Nazwa' }, { name: 'Przedstawiciel' }, { name: 'E-mail' }, { name: 'Phone' }, { name: 'NIP' }];

  return (
    <div className={style.container}>
      <div className={style.header}>
        <h3>Lista serwisantów ({total})</h3>
        <div className={style.wrapper}>
          <Button
            onClick={() => setIsCreateOpen(true)}
            label={'Dodaj nowego'}
          />
          <button
            className={styles.close}
            onClick={onClose}
          >
            <Icon
              name='close'
              size='20'
            />
          </button>
        </div>
      </div>
      <Table
        columns={columns}
        headerClasses={thumbnailStyles.grid}
      >
        {isLoading ? (
          <Skeleton
            height={41}
            count={servicersCount || 4}
            style={{ borderRadius: '10px' }}
          />
        ) : servicers.length > 0 ? (
          <div className={style.table}>
            {servicers.map((servicer) => (
              <ServicerThumbnail
                key={servicer.id}
                servicer={servicer}
                refreshCallback={getServicers}
              />
            ))}
          </div>
        ) : (
          <EmptyState
            type={'servicers'}
            tiny
          />
        )}
      </Table>
      <PaginationNav
        setPage={setCurrentPage}
        pagesQty={pageQty}
        setPerPage={setPerPage}
        defaultQty={perPage}
        page={currentPage}
        hideSelect
      />
      <Modal
        onClose={() => setIsCreateOpen(false)}
        visible={isCreateOpen}
        title={'Dodaj serwisanta'}
      >
        <CreateServiceForm
          onClose={() => setIsCreateOpen(false)}
          refreshCallback={getServicers}
        />
      </Modal>
    </div>
  );
};

export default ServicersList;
