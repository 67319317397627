import { Accordion } from 'components';

import styles from '../../SystemMessages.module.scss';

const MessageThumbnail = (props) => {
  const { title, content } = props;

  return (
    <Accordion
      title={'Dear Users, the Collect Products functionality has been modified!'}
      defaultWrapped
      borderLeftDecorator
    >
      <div
        className={styles.content}
        dangerouslySetInnerHTML={{
          __html: `
                    <p>Due to changes introduced in the order form on the supplier's side (improving and enabling the user to be informed about partial shipment of products), the <strong>"Collect products"</strong> functionality has been modified. From now on, when collecting products, a form is displayed in which you must specify the number of products collected. If a given product item is not included in the package, its pickup value should be changed to 0. The number of products for pickup is set by default in relation to the number declared in the shipment.</p>
                    <div class="wp-block-image">
                      <figure class="aligncenter size-large is-resized"><img loading="lazy" decoding="async" width="1024" height="815" src="https://support.avalio.io/wp-content/uploads/2024/03/image-1024x815.png" alt="" class="wp-image-336" style="width:538px;height:auto" srcset="https://support.avalio.io/wp-content/uploads/2024/03/image-1024x815.png 1024w, https://support.avalio.io/wp-content/uploads/2024/03/image-300x239.png 300w, https://support.avalio.io/wp-content/uploads/2024/03/image-768x611.png 768w, https://support.avalio.io/wp-content/uploads/2024/03/image-1536x1222.png 1536w, https://support.avalio.io/wp-content/uploads/2024/03/image.png 1879w" sizes="(max-width: 1024px) 100vw, 1024px" /></figure>
                    </div>
                    <p>
                    Thank you for being with us!<br><br>Avalio Team</p>
                  `
        }}
      />
    </Accordion>
  );
};

export default MessageThumbnail;
