import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { TagsApi } from 'src/api';
import { compareArrays, formatTagOption } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterByTag = ({ params, fullWidth, valueKey = 'value', labelKey = 'label', queryParams, label, apiCallback, isMulti }) => {
  const [selectedTag, setSelectedTag] = useState(isMulti ? [] : null);

  const paramValue = params.get('tag');

  const setSingleValue = (tag) => {
    const areValueTheSame = tag?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'tag', value: tag?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('tag', tag?.value);
      }
    }
  };

  const setMultiValue = (tag) => {
    const idsArray = tag?.map((cat) => cat.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'tag', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('tag', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedTag(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  useEffect(() => {
    const fetchCategory = () => {
      return TagsApi.getTags(queryParams);
    };

    const getSelectedElement = async () => {
      try {
        const { data } = await fetchCategory();
        const selected = data.find((tag) => Number(tag.id) === Number(paramValue));
        setSelectedTag(formatTagOption(selected));
      } catch (err) {
        notifyApiError(err);
      }
    };

    const getDataForAllCategories = async () => {
      try {
        const { data } = await fetchCategory();
        const paramsToFilter = paramValue.map((id) => parseInt(id));
        const selectedTags = data.filter((tag) => paramsToFilter.includes(tag.id));
        setSelectedTag(selectedTags.map((tag) => formatTagOption(tag)));
      } catch (err) {
        notifyApiError(err);
      }
    };

    if (paramValue) {
      if (Array.isArray(paramValue)) getDataForAllCategories();
      else getSelectedElement();
    }
  }, [queryParams]);

  return (
    <AsyncSelect
      value={selectedTag}
      onChange={onChangeHandler}
      apiCallback={apiCallback}
      valueKey={valueKey}
      label={label}
      labelKey={labelKey}
      placeholder={'Tags'}
      isClearable
      isMulti={isMulti}
      queryParams={{ pagination: 1, ...queryParams }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedTag,
        [style.fullWidth]: fullWidth
      })}
    />
  );
};

export default FilterByTag;
