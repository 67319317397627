import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Card } from 'components';

import Gift from './components/Gift';
import GiftForm from './components/GiftForm';

import style from './Freebies.module.scss';
import shared from 'styles/Shared.module.scss';

const Freebies = () => {
  const gifts = useSelector((state) => state.supplierForm.form.gifts);
  const disabled = useSelector((state) => state.supplierForm.isDisabled);

  if (disabled && gifts.length === 0) {
    return null;
  }

  return (
    <Card
      className={shared.defaultWrapper}
      id={'freebies'}
    >
      <h2 className={style.title}>Offer freebies</h2>
      <section className={classNames(shared.tableWrapper, style.table)}>
        <div className={classNames(shared.tableHeader, style.extrasHeader)}>
          <p>Name</p>
          <p>Qty.</p>
          <p>Producer</p>
          <p>Gross value</p>
        </div>
        {gifts.map((gift, index) => (
          <Gift
            key={index}
            gift={gift}
            disabled={disabled}
            added
          />
        ))}
        {!disabled && <GiftForm />}
      </section>
    </Card>
  );
};

export default Freebies;
