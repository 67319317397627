import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { AsyncSelect, Button, Input, QtySelector, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi, ProductsApi, UserApi } from 'src/api';

import { refreshCartOrCatalog } from '../../../../actions';

import style from '../../AddNewPrice.module.scss';

const Replacement = ({ productId, selectedCompany, closeModal }) => {
  const validator = useValidator();
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [replacementName, setReplacementName] = useState('');
  const [replacementProducer, setReplacementProducer] = useState('');
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);

  const addReplacement = async () => {
    const queryData = {
      ...(isAdmin && { company_id: selectedCompany }),
      product_id: productId || selectedProduct?.value,
      supplier_id: selectedSupplier?.value,
      type: 'replacement',
      price: +price,
      replacement_name: replacementName,
      replacement_producer: replacementProducer
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await OfferCatalogApi.addPrice(queryData);
      notifyCommon(['Zamiennik został dodany']);
      dispatch(refreshCartOrCatalog());
      setSelectedSupplier(null);
      setPrice(0);
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={style.wrapper}>
      {!productId && (
        <AsyncSelect
          value={selectedProduct}
          onChange={setSelectedProduct}
          apiCallback={ProductsApi.getProducts}
          valueKey={'id'}
          labelKey={'name'}
          label={'Produkt'}
          placeholder={'Produkt'}
          validator={validator}
          rule={'required'}
          productSearch
          isClearable
        />
      )}
      <AsyncSelect
        value={selectedSupplier}
        onChange={setSelectedSupplier}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        label={'Dostawca'}
        placeholder={'Dostawca'}
        queryParams={{ pagination: 1, ...(isAdmin && { company_id: selectedCompany }) }}
        validator={validator}
        rule={'required'}
        isClearable
      />
      <div className={style.box}>
        <Input
          value={replacementName}
          onChange={(e) => setReplacementName(e.target.value)}
          name={'replacement-name'}
          label={'Nazwa zamiennika'}
          validator={validator}
          rule={'required'}
        />
        <QtySelector
          state={quantity}
          setState={setQuantity}
          suffix={'szt.'}
          hideArrows
          width={'100%'}
          label={'Ilość'}
          validator={validator}
          id={`offerQty-${parent.id}`}
          rule={'required|only_positive'}
        />
      </div>
      <div className={style.box}>
        <Input
          value={replacementProducer}
          onChange={(e) => setReplacementProducer(e.target.value)}
          name={'replacement-name'}
          label={'Producent zamiennika'}
          validator={validator}
          rule={'required'}
        />
        <QtySelector
          state={price}
          setState={setPrice}
          suffix={'PLN'}
          hideArrows
          width={'100%'}
          id={'priceInput'}
          wrapperStyle={style.priceInput}
          label={'Cena'}
          priceInput
          validator={validator}
          rule={'required|min_price'}
        />
      </div>
      <Button
        isLoading={isLoading}
        label={'Dodaj ofertę'}
        onClick={addReplacement}
      />
    </div>
  );
};

export default Replacement;
