import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Input, QtySelector, useIsMobile, useValidator } from 'components';
import SaveButton from 'components/layout/SaveButton';

import { addGift } from 'src/features/SupplierForm/actions';

import style from './GiftForm.module.scss';

const initialValue = {
  name: '',
  quantity: 1,
  producer: '',
  price: 0
};

const GiftForm = () => {
  const [value, setValue] = useState(initialValue);
  const dispatch = useDispatch();
  const validator = useValidator();
  const isMobile = useIsMobile(1240);

  const onChangeValue = (name, value) => {
    setValue((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddGift = () => {
    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    dispatch(addGift(value));
    setValue(initialValue);
    validator.hideMessages();
  };

  return (
    <div className={classNames(style.container, style.form)}>
      <Input
        placeholder={'Nazwa produktu'}
        value={value.name}
        onChange={(e) => onChangeValue('name', e.target.value)}
        validator={validator}
        label={isMobile ? 'Nazwa produktu' : undefined}
        rule={'required'}
      />
      <QtySelector
        wrapperStyle={style.quantity}
        state={value.quantity}
        setState={(value) => onChangeValue('quantity', value)}
        horizontal={!isMobile}
        hideArrows={isMobile}
        suffix={isMobile ? 'szt.' : undefined}
        label={isMobile ? 'Ilość' : undefined}
        id={'gift-qty'}
        validator={validator}
        rule={'required|only_positive'}
      />
      <Input
        placeholder={'Producent'}
        value={value.producer}
        onChange={(e) => onChangeValue('producer', e.target.value)}
        validator={validator}
        label={isMobile ? 'Producent' : undefined}
        rule={'required'}
      />
      <QtySelector
        wrapperStyle={style.quantity}
        state={value.price}
        setState={(value) => onChangeValue('price', value)}
        label={isMobile ? 'Wartość' : undefined}
        hideArrows
        suffix={'PLN'}
        id={'gift-price'}
        priceInput
        validator={validator}
        rule={'required|min_price'}
      />
      <SaveButton
        wrapperStyle={style.button}
        type={'save'}
        onClick={handleAddGift}
      />
      <Button
        className={style.mobileButton}
        label={'Zapisz gratis'}
        onClick={handleAddGift}
      />
    </div>
  );
};

export default GiftForm;
