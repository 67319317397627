import React, { forwardRef, useEffect, useState } from 'react';
import cn from 'classnames';

import { AsyncSelect, Select, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';

import style from './ProductsFiltering.module.scss';

export const searchTypeOptions = [
  { label: 'Products with offer', value: true },
  { label: 'Full product catalog', value: false }
];

const ProductsFiltering = forwardRef((props, ref) => {
  const { producerState, categoryState, searchTypeState, isFilteringVisible, onlyProductsBase = false } = props;

  const [selectedProducer, setSelectedProducer] = producerState;
  const [selectedCategory, setSelectedCategory] = categoryState;
  const [onlyProductsWithOffers, setOnlyProductsWithOffers] = searchTypeState;

  const [isLoading, setIsLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [abortController, setNewController] = useRequestAbortController();

  const getCategoriesList = async () => {
    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await ProductsApi.getProductsProperties(signal);
      setCategoriesList(data.categories);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCategoriesList();
  }, []);

  const containerClasses = cn(style.container, {
    [style.isHidden]: isFilteringVisible
  });

  return (
    <div
      className={containerClasses}
      ref={ref}
    >
      <div className={style.wrapper}>
        <Select
          options={categoriesList}
          value={selectedCategory}
          onChange={setSelectedCategory}
          placeholder={'Category'}
          label={'Category'}
          isLoading={isLoading}
          isClearable
        />
        <AsyncSelect
          value={selectedProducer}
          onChange={setSelectedProducer}
          apiCallback={ProductsApi.getProducers}
          valueKey={'id'}
          labelKey={'producer_name'}
          label={'Producer'}
          placeholder={'Producer'}
          queryParams={{ category_id: selectedCategory?.value }}
          isClearable
        />
        {!onlyProductsBase && (
          <>
            <span className={style.divider} />
            <Select
              options={searchTypeOptions}
              value={onlyProductsWithOffers}
              onChange={setOnlyProductsWithOffers}
              placeholder={'Products source'}
              label={'Products source'}
              isClearable={false}
            />
          </>
        )}
      </div>
    </div>
  );
});

export default ProductsFiltering;
