import React, { useState } from 'react';

import {
  AsyncSelect,
  Button,
  FileDropzone,
  Input,
  Modal,
  SaveButton,
  Select,
  Textarea,
  useIsAdmin,
  useValidator
} from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import CreateServiceForm from 'src/features/Services/components/ServicersList/components/CreateServiceForm';

import style from './EventForm.module.scss';

const options = [
  { label: 'Repair', value: 'FIX' },
  { label: 'Service', value: 'SERVICE' }
];

const defaultService = {
  event_date: null,
  servicer: null,
  comment: '',
  type: options[1],
  file: null
};

const EventForm = ({ eventData, onClose, refreshCallback, serviceId, selectedCompany }) => {
  const isAdmin = useIsAdmin();

  const validator = useValidator();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [servicersVersion, setServicersVersion] = useState(0);
  const [event, setEvent] = useState(eventData || defaultService);

  const onChange = (e) => {
    setEvent((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onChangeHandler = (value, key) => {
    setEvent((prev) => ({
      ...prev,
      [key]: value
    }));
  };

  const addOrEditServiceHandler = async (e, type) => {
    e.preventDefault();

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const params = {
      service_id: serviceId,
      ...(type === 'edit' && { event_id: eventData.id }),
      ...(isAdmin && { company_id: selectedCompany }),
      ...event,
      servicer_id: event.servicer.value,
      type: event.type.value
    };

    const apiCallback = type === 'edit' ? ServicesApi.editEvent : ServicesApi.addEvent;

    try {
      setIsLoading(true);
      const { data } = await apiCallback(params);
      if (onClose) onClose();
      if (refreshCallback) refreshCallback();
      notifyCommon([`Event został pomyślnie ${type === 'add' ? 'zapisany' : 'dodany'}.`]);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        className={style.container}
        onSubmit={(e) => addOrEditServiceHandler(e, eventData ? 'edit' : 'add')}
      >
        <div className={style.columns}>
          {!eventData && (
            <Select
              label={'Typ'}
              options={options}
              rule={'required'}
              value={event.type}
              isSearchable={false}
              validator={validator}
              onChange={(value) => onChangeHandler(value, 'type')}
            />
          )}
          <Input
            type='date'
            id='event_date'
            name='event_date'
            rule={'required'}
            onChange={onChange}
            label={event.type.value === 'SERVICE' ? 'Data serwisu' : 'Data naprawy'}
            validator={validator}
            value={event.event_date || ''}
          />
        </div>
        <div className={style.servicerWrapper}>
          <AsyncSelect
            valueKey={'id'}
            labelKey={'name'}
            rule={'required'}
            label={'Serwisant'}
            id={'select-product'}
            validator={validator}
            value={event.servicer}
            queryParams={{ servicersVersion, ...(isAdmin && { company_id: selectedCompany }) }}
            placeholder={'Wybierz serwisanta...'}
            apiCallback={ServicesApi.getServicersList}
            onChange={(value) => onChangeHandler(value, 'servicer')}
          />
          {!eventData && (
            <SaveButton
              type={'add'}
              onClick={() => setIsCreateOpen(true)}
              className={style.addButton}
              htmlType={'button'}
            />
          )}
        </div>
        <Textarea
          label={'Powód'}
          rule={'required'}
          value={event.comment}
          validator={validator}
          setValue={(value) => onChangeHandler(value, 'comment')}
        />
        {!eventData && (
          <FileDropzone
            id={'logo'}
            name={'logo'}
            value={event.file}
            label={'Załącznik'}
            onChange={(value) => onChangeHandler(value, 'file')}
          />
        )}
        <Button
          isLoading={isLoading}
          className={style.button}
          label={eventData ? 'Zapisz' : 'Dodaj'}
        />
      </form>
      <Modal
        visible={isCreateOpen}
        title={'Dodaj serwisanta'}
        onClose={() => setIsCreateOpen(false)}
      >
        <CreateServiceForm
          onClose={() => setIsCreateOpen(false)}
          refreshCallback={() => setServicersVersion((prev) => prev + 1)}
        />
      </Modal>
    </>
  );
};

export default EventForm;
