import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import classNames from 'classnames';

import { SaveButton, Textarea } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OrdersApi, SupplierApi } from 'src/api';
import Accordion from 'src/components/layout/Accordion';
import { toDate, toTime } from 'src/utils/dateTime';

import Message from './components/Message';

import style from './Communication.module.scss';
import shared from 'styles/Shared.module.scss';

const Communication = ({
  messages = [],
  refreshHandler,
  supplierView,
  supplierId,
  pricingComment,
  userComment,
  orderCreatedAt,
  hideInput,
  notifications
}) => {
  const { uid } = useParams();
  const [message, setMessage] = useState('');
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const onInput = (e) => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = e.target.scrollHeight + 'px';
    }
  };

  const submitHandler = async () => {
    const data = { uid, message, supplier_id: supplierId };
    const api = supplierView ? SupplierApi : OrdersApi;

    try {
      setIsLoading(true);
      await api.addOrderMessage(data);
      setMessage('');
      if (refreshHandler) refreshHandler();
      if (textAreaRef.current) {
        textAreaRef.current.style.height = '40px';
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submitHandler();
    }
  };

  useEffect(() => {
    if (containerRef.current) containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [messages]);

  const title = <h3 className={classNames(shared.tableCategoryTitle, shared.large, shared.noPadding)}>Communication</h3>;

  return (
    <Accordion
      section
      title={title}
      className={style.container}
      extraDeps={[messages]}
    >
      <div className={style.box}>
        <div
          className={classNames(style.messagesWrapper, { [style.isLoading]: isLoading })}
          ref={containerRef}
        >
          {!!pricingComment && (
            <Message
              displayRight={supplierView}
              text={pricingComment}
              label={supplierView ? 'Twój komentarz do wyceny' : 'Komentarz dostawcy do wyceny'}
            />
          )}
          {!!userComment && (
            <Message
              displayRight={!supplierView}
              text={userComment}
              label={supplierView ? 'Komentarz klienta do zamówienia' : 'Your order comment'}
            />
          )}
          {!!orderCreatedAt && (
            <span className={style.divider}>
              Order started {toDate(orderCreatedAt)}, {toTime(orderCreatedAt)}{' '}
            </span>
          )}
          {messages.length > 0 ? (
            messages.map((message) => (
              <Message
                key={message.id}
                displayRight={
                  supplierView
                    ? message.sender === 'supplier' || message.as_supplier === 1
                    : message.sender === 'user' || (message.sender === 'server' && message.as_supplier !== 1)
                }
                notification={notifications?.find((ntf) => ntf?.data?.message_id === message.id)}
                message={message}
              />
            ))
          ) : (
            <p className={style.emptyMessage}>No messages.</p>
          )}
        </div>
        {!hideInput && (
          <div className={style.inputWrapper}>
            <Textarea
              hideBorder
              wrapperStyle={style.textAreaBox}
              onKeyDown={onKeyDown}
              onInput={onInput}
              value={message}
              setValue={setMessage}
              placeholder={'Your message...'}
              ref={textAreaRef}
              disabled={isLoading}
              singleLine
            />
            {!!message && (
              <SaveButton
                onClick={submitHandler}
                isLoading={isLoading}
                type={'send'}
              />
            )}
          </div>
        )}
      </div>
    </Accordion>
  );
};

export default Communication;
