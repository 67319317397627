import React from 'react';

import { Menu, RelativeTime } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import styles from '../../FloorPlan.module.scss';

const ReceptionOverview = () => {
  const noteActions = [
    {
      title: 'Edit',
      icon: 'edit',
      action: () => console.log('edit')
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: () => console.log('remove')
    }
  ];

  return (
    <div className={styles.modalContainer}>
      <div className={styles.dataWrapper}>
        <h4 className={styles.dataWrapperHeadline}>Podstawowe informacje</h4>
        <ul className={styles.items}>
          <li>
            <span>Liczba umówionych wizyt:</span>
            <span>153</span>
          </li>
          <li>
            <span>Wartość umówionych wizyt:</span>
            <span className={styles.green}>{getFormattedAmount(84150)}</span>
          </li>
          <li>
            <span>Liczba odwołanych wizyt:</span>
            <span>23</span>
          </li>
          <li>
            <span>Wartość odwołanych wizyt:</span>
            <span className={styles.red}>{getFormattedAmount(12650.23)}</span>
          </li>
          <li>
            <span>Liczba nieodebranych połączeń:</span>
            <span>31</span>
          </li>
        </ul>
      </div>
      <div className={styles.dataWrapper}>
        <h4 className={styles.dataWrapperHeadline}>Notatka</h4>
        <div className={styles.note}>
          <p>Zapłacić faktury za serwis z poprzedniego miesiąca. Zebrać zamówienia.</p>
          <Menu
            actions={noteActions}
            className={styles.noteMenu}
          />
        </div>
      </div>
    </div>
  );
};

export default ReceptionOverview;
