import React from 'react';
import classNames from 'classnames';

import { ColumnSortButton, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import ProductRow from '../ProductRow';

import style from './ProductsList.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductsList = ({ list = [], isLoading, params }) => {
  return (
    <section className={shared.tableWrapper}>
      <div className={classNames(shared.tableHeader, style.header)}>
        <ColumnSortButton
          parameterName={'name'}
          params={params}
          content={'Nazwa'}
        />
        <ColumnSortButton
          parameterName={'category'}
          params={params}
          content={'Kategoria'}
        />
        <ColumnSortButton
          parameterName={'updated_at'}
          params={params}
          content={'Data edycji'}
        />
        <ColumnSortButton
          parameterName={'price'}
          params={params}
          content={'Twoja cena'}
        />
        <ColumnSortButton
          parameterName={'isActive'}
          params={params}
          content={'Availability'}
        />
      </div>
      <div className={shared.tableCategory}>
        {isLoading ? (
          <ProductPlaceholder />
        ) : list.length ? (
          list.map((product) => (
            <ProductRow
              key={product.id}
              product={product}
            />
          ))
        ) : (
          <div className={shared.tableCategory}>
            <h3 className={shared.tableCategoryTitle}>
              <EmptyState type={params.get('search') ? 'search' : 'collections'} />
            </h3>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProductsList;
