import React, { useEffect, useState } from 'react';

import { Checkbox, EmptyState } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import ChangeManyAtOnce from '../ChangeManyAtOnce';
import Product from '../Product';

import style from '../../SuppliersManagement.module.scss';

const ProductsList = ({ isLoading, list = [], isOpen, params }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (!isOpen) setSelectedProducts([]);
  }, [isOpen]);

  const onClickAll = () => {
    setSelectedProducts(selectedProducts.length === list.length ? [] : list);
  };

  const emptyStateType =
    params.get('search') || params.get('category') || params.get('producer') ? 'search' : 'suppliersManagements';

  return (
    <>
      {isOpen && (
        <ChangeManyAtOnce
          products={list}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
        />
      )}
      <div className={style.tableWrapper}>
        {isOpen && (
          <Checkbox
            value={selectedProducts.length === list.length}
            onChange={onClickAll}
            content={'Select all'}
            wrapperStyle={style.checkboxAll}
          />
        )}
        {isLoading ? (
          <ProductPlaceholder />
        ) : (
          list.map((product) => (
            <Product
              isCheckboxVisible={isOpen}
              product={product}
              key={product.name}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          ))
        )}
        {!list.length && !isLoading && <EmptyState type={emptyStateType} />}
      </div>
    </>
  );
};

export default ProductsList;
