import React, { useEffect, useMemo, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { EmptyState, useIsMobile } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';

import style from './PaginationScroll.module.scss';

const PaginationScroll = (props) => {
  const {
    data = [],
    hasMore,
    page,
    loadMoreResults,
    loader,
    children,
    isFilteringVisible,
    emptyState = <EmptyState type={'search'} />,
    wasDataCleared,
    height
  } = props;

  const parentRef = useRef(null);
  const isMobile = useIsMobile();

  const endMessage = <div className={style.allResults}>{data.length > 0 ? 'That is all.' : emptyState}</div>;

  const desktopHeight = useMemo(
    () =>
      parentRef.current
        ? Math.min(window.innerHeight - parentRef.current.getBoundingClientRect().top - 80, window.innerHeight / 2)
        : Math.floor(window.innerHeight / 2),
    [window.innerHeight, parentRef.current]
  );

  const mobileHeight = useMemo(
    () => window.innerHeight - (isFilteringVisible ? 136 : 280),
    [window.innerHeight, isFilteringVisible]
  );

  const containerHeight = useMemo(() => (isMobile ? mobileHeight : desktopHeight), [isMobile, isFilteringVisible]);

  const listLoader = useMemo(
    () => (loader ? loader : <ProductPlaceholder quantity={(containerHeight - (containerHeight % 60)) / 60 - 1} />),
    [loader]
  );

  useEffect(() => {
    if (wasDataCleared) {
      const div = document.querySelector('.infinite-scroll-component');
      if (div) div.scrollTop = 0;
    }
  }, [wasDataCleared]);

  return (
    <div
      className={style.container}
      ref={parentRef}
    >
      <InfiniteScroll
        height={height || containerHeight}
        dataLength={data.length}
        className={style.wrapper}
        hasMore={hasMore}
        version={page}
        next={loadMoreResults}
        loader={listLoader}
        endMessage={endMessage}
        scrollThreshold={containerHeight + 'px'}
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

export default PaginationScroll;
