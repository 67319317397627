import React, { useState } from 'react';

import { Button, Input, PhoneInput, SwitchButton, useCompany, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import style from '../../MemberForm.module.scss';

const defaultUser = {
  name: '',
  email: '',
  phone: '',
  position: ''
};

const NewMemberForm = ({ closeModal, changeVersion, member }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [sendWelcomeEmail, setSendWelcomeEmail] = useState(false);
  const [userData, setUserData] = useState(member || defaultUser);
  const validator = useValidator();
  const company = useCompany();

  const onUserPhoneChange = (value) =>
    setUserData((prev) => ({
      ...prev,
      phone: value
    }));

  const onUserChange = (e) =>
    setUserData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));

  const addNewUserHandler = async () => {
    const queryData = {
      ...userData,
      company_id: company.id,
      send_welcome_email: sendWelcomeEmail
    };

    try {
      setIsLoading(true);
      await CompanyApi.createEmployee(queryData);
      notifyCommon(
        sendWelcomeEmail ? ['Na podany email został wysłany link do ustawienia hasła.'] : ['Pracownik został dodany.']
      );
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const editUserHandler = async () => {
    const queryData = {
      ...userData,
      company_id: company.id,
      user_id: userData.id
    };

    await CompanyApi.editCompanyUser(queryData);
    notifyCommon(['Dane pracownika zostały zaktualizowane.']);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return;
    }

    try {
      setIsLoading(true);
      if (member) await editUserHandler();
      else await addNewUserHandler();
      changeVersion();
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={style.container}
    >
      <Input
        id='name'
        name='name'
        label='Imię i nazwisko'
        placeholder='Jan Kowalski'
        value={userData.name}
        onChange={onUserChange}
        validator={validator}
        rule='required'
      />
      <PhoneInput
        id='phone'
        name='phone'
        label='Phone'
        value={userData.phone}
        onChange={onUserPhoneChange}
        validator={validator}
        rule='required|phone'
      />
      <Input
        id='email'
        name='email'
        label='E-mail'
        placeholder='jan@klinika.pl'
        value={userData.email}
        onChange={onUserChange}
        validator={validator}
        disabled={!!member}
        rule='required|email'
      />
      <Input
        id='position'
        name='position'
        label='Stanowisko/rola'
        placeholder='Asystent'
        value={userData.position}
        onChange={onUserChange}
      />
      {!member && (
        <SwitchButton
          value={sendWelcomeEmail}
          setValue={setSendWelcomeEmail}
          label='Wyślij email powitalny'
        />
      )}
      <Button
        label={member ? 'Zapisz dane' : 'Dodaj do oddziału'}
        type='submit'
        isLoading={isLoading}
        className={style.button}
      />
    </form>
  );
};

export default NewMemberForm;
