import React, { useMemo } from 'react';

import { RelativeTime } from 'components/index';
import InfoWidget from 'components/layout/InfoWidget';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { getFormattedAmount, getPriceTypeContent } from 'src/utils/helpers';

import styles from './ChangedPricesProductThumbnail.module.scss';

const calculateDifference = (previousPrice, currentPrice) => {
  let prevPrice = previousPrice === null ? 0 : previousPrice;
  let currPrice = currentPrice === null ? 0 : currentPrice;

  const numericDifference = currPrice - prevPrice;
  const sign = numericDifference > 0 ? '+' : numericDifference < 0 ? '-' : '';

  let percentageDifference = 0;
  if (prevPrice !== 0) {
    percentageDifference = (Math.abs(numericDifference) / prevPrice) * 100;
  }

  return {
    percentageDifference: sign + percentageDifference.toFixed(2) + '%',
    numericDifference: sign + getFormattedAmount(Math.abs(numericDifference))
  };
};

const ChangedPricesProductThumbnail = ({ price, previous_price, name, thumbnail, image, product_id, created_at, add_type }) => {
  const difference = calculateDifference(previous_price, price);

  const { label: priceAddTypeLabel } = useMemo(() => getPriceTypeContent(add_type), [add_type]);

  return (
    <div className={styles.container}>
      <div className={styles.productThumbnail}>
        <ProductThumbnailImage
          thumbnail={thumbnail}
          image={image}
          title={name}
          id={product_id}
        />
        <span>{name}</span>
      </div>
      <div>{previous_price ? `${getFormattedAmount(previous_price)}` : '-'}</div>
      <div className={styles.priceContainer}>
        {price ? `${getFormattedAmount(price)}` : '-'}
        <InfoWidget place={'top'}>
          <p>{priceAddTypeLabel}</p>
        </InfoWidget>
      </div>
      <div>{previous_price ? `${difference.percentageDifference} (${difference.numericDifference})` : '-'}</div>
      <div>
        <RelativeTime date={created_at} />
      </div>
    </div>
  );
};

export default ChangedPricesProductThumbnail;
