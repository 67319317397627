import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAtom } from 'jotai/index';

import { EmptyState, PaginationScroll, Table, useIsAdmin, useRequestAbortController } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import query from 'src/utils/query';

import SingleRelease from './components/SingleRelease';

import productStyle from './components/SingleRelease/SingleRelease.module.scss';
import style from './Releases.module.scss';

const columns = [
  { name: 'Release type' },
  { name: 'Date' },
  { name: 'Quantity released' },
  { name: 'Released for' },
  { name: 'Released by' }
];

const QUERY_LIMIT = 10;

const Releases = (props) => {
  const { warehouseProdId } = props;

  const params = query(props);
  const isAdmin = useIsAdmin();
  const wasDataLoaded = useRef(false);

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [releases, setReleases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [abortController, setNewController] = useRequestAbortController();

  const getData = async (page = 1, clearData) => {
    const queryParams = {
      page: page || 1,
      perPage: QUERY_LIMIT,
      new_warehouse_products_id: warehouseProdId,
      ...(isAdmin && { company_id: selectedCompany })
    };

    if (abortController) abortController.abort();
    const signal = setNewController();

    try {
      setIsLoading(true);
      const { data } = await WarehouseApi.getProductReleases(queryParams, signal);

      if (clearData || data.data.length === 0) {
        setReleases(data.data);
        setHasMore(data.data.length < data.total);
      } else {
        setReleases((prev) => [...prev, ...data.data]);
        setHasMore(data.data.length + releases.length < data.total);
      }
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreResults = () => {
    if (!isLoading) {
      getData(page + 1);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (!wasDataLoaded.current) {
      getData(1);
      wasDataLoaded.current = true;
    }
  }, []);

  return (
    <div className={style.container}>
      <Table
        columns={columns}
        params={params}
        headerClasses={productStyle.gridRow}
        className={style.table}
      >
        <PaginationScroll
          data={releases}
          hasMore={hasMore}
          page={page}
          height={300}
          loadMoreResults={loadMoreResults}
          emptyState={
            <EmptyState
              type={'releases'}
              tiny
            />
          }
          loader={
            <Skeleton
              height={38}
              count={6}
              style={{ borderRadius: 8 }}
            />
          }
        >
          {releases.map((release) => (
            <SingleRelease
              key={release.id}
              release={release}
            />
          ))}
        </PaginationScroll>
      </Table>
    </div>
  );
};

export default Releases;
