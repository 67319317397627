import React from 'react';
import classnames from 'classnames';
import cn from 'classnames';
import moment from 'moment/moment';

import { useIsMobile } from 'components';
import ProductThumbnailImage from 'components/layout/ProductThumbnailImage';

import { toDateNumber, toTime } from 'src/utils/dateTime';

import IsActiveSwitch from '../IsActiveSwitch';
import PriceInput from '../PriceInput';

import style from './ProductRow.module.scss';

moment.locale('pl');

const ProductRow = ({ product }) => {
  const { thumbnail, image, unit_name, name, producer, category, updated_at, id } = product || {};
  const isMobile = useIsMobile(1250);

  return (
    <div className={style.container}>
      <div className={classnames(style.wrapper, style.nameWrapper)}>
        <div className={style.columnWrapper}>
          <ProductThumbnailImage
            thumbnail={thumbnail}
            image={image}
            title={name}
            id={id}
          />
        </div>
        <div className={style.columnWrapper}>
          <p className={style.text}>{name}</p>
          <div className={style.rowWrapper}>
            <p>Jednostka: {unit_name}</p>
            <p>Producer: {producer}</p>
          </div>
        </div>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Kategoria:</p>}
        <p className={style.text}>{category}</p>
      </div>
      <div className={classnames(style.wrapper, style.dateWrapper)}>
        {!!updated_at && updated_at !== '0000-00-00 00:00:00' ? (
          <>
            {isMobile && <p className={style.text}>Data edycji:</p>}
            <p className={cn(style.text, style.date)}>
              {toDateNumber(updated_at)} {toTime(updated_at)}
            </p>
          </>
        ) : (
          <p>-</p>
        )}
      </div>
      <div className={classnames(style.wrapper, style.price)}>
        {isMobile && <p className={style.text}>Cena:</p>}
        <PriceInput product={product} />
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.text}>Produkt dostęny:</p>}
        <IsActiveSwitch product={product} />
      </div>
    </div>
  );
};

export default ProductRow;
