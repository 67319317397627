import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import cn from 'classnames';

import { Button, Checkbox, EmptyState, Loader, useIsAdmin, useModalConfirm } from 'components';
import SingleProduct from 'components/layout/CreateValuationForm/components/SingleProduct';
import SingleSupplier from 'components/layout/CreateValuationForm/components/SingleSupplier';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi, UserApi } from 'src/api';
import ListComment from 'src/features/ListView/components/ListComment';
import { handleNumeralWords } from 'src/utils/helpers';

import style from './CreateValuationForm.module.scss';
import shared from 'styles/Shared.module.scss';

const CreateValuationForm = ({ products = [], selectedCompany, listUID, onFinish, listComment }) => {
  const history = useHistory();
  const isAdmin = useIsAdmin();

  const [selectedProducts, setSelectedProducts] = useState(products.map((prod) => prod.product_id || prod.id));
  const [isValuationCreateLoading, setIsValuationCreateLoading] = useState(false);
  const [isSuppliersLoading, setIsSuppliersLoading] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const [currentStep, setCurrentStep] = useState(0);
  const [suppliers, setSuppliers] = useState([]);

  const onChange = (value, id, setState = setSelectedProducts) => {
    if (value) setState((prev) => [...prev, id]);
    else setState((prev) => prev.filter((item) => item !== id));
  };

  const onChangeAllProducts = (value) => {
    if (value) setSelectedProducts(products.map((prod) => prod.product_id || prod.id));
    else setSelectedProducts([]);
  };

  const onChangeAllSuppliers = (value) => {
    if (value) setSelectedSuppliers(suppliers.map((sup) => sup.id));
    else setSelectedSuppliers([]);
  };

  const createValuation = async () => {
    const queryParams = {
      uid: listUID,
      distributors: selectedSuppliers,
      products: selectedProducts
    };

    try {
      setIsValuationCreateLoading(true);
      const { data } = await ListsApi.createPricing(queryParams);
      handleOpenModalConfirm({
        message: 'Wycena została utworzona. Czy chcesz do niej przejść?',
        handleConfirm: () => history.push(`/valuations/${data?.UID}`),
        handleCancel: onFinish,
        cancelButtonName: 'Zamknij',
        confirmButtonName: 'Przejdź do wyceny'
      });
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsValuationCreateLoading(false);
    }
  };

  useEffect(() => {
    const getSuppliers = async () => {
      const queryParams = {
        ...(isAdmin && { company_id: selectedCompany }),
        pagination: 0
      };

      try {
        setIsSuppliersLoading(true);
        const { data } = await UserApi.getDistributors(queryParams);
        setSuppliers(data);
      } catch (err) {
        notifyApiError(err);
      } finally {
        setIsSuppliersLoading(false);
      }
    };

    getSuppliers();
  }, []);

  if (currentStep > 0) {
    return (
      <>
        <div className={style.container}>
          <header className={style.topHeader}>
            <h2>Krok 2 z 2</h2>
            <p className={style.text}>
              Wybierz dostawców, od których chcesz uzyskać wycenę{' '}
              <strong>
                {selectedProducts.length} {handleNumeralWords(['product', 'products', 'products'], selectedProducts.length)}
              </strong>
            </p>
          </header>
          <ListComment
            uid={listUID}
            listComment={listComment}
            parentIsLoading={isSuppliersLoading}
          />
          <div className={cn(style.box, style.suppliers)}>
            {isSuppliersLoading ? (
              <Loader />
            ) : (
              <>
                <div className={classNames(shared.tableHeader, style.tableHeader)}>
                  <Checkbox
                    value={selectedSuppliers.length === suppliers.length}
                    onChange={(e) => onChangeAllSuppliers(e.target.checked)}
                    disabled={suppliers.length === 0}
                  />
                  <p>Dostawca</p>
                  <p>Email</p>
                  <p>Phone</p>
                </div>
                {suppliers.length > 0 ? (
                  suppliers.map((sup) => (
                    <SingleSupplier
                      supplier={sup}
                      key={sup.id}
                    >
                      <Checkbox
                        value={selectedSuppliers.includes(sup.id)}
                        onChange={(e) => onChange(e.target.checked, sup.id, setSelectedSuppliers)}
                      />
                    </SingleSupplier>
                  ))
                ) : (
                  <EmptyState
                    tiny
                    type={'suppliersSelect'}
                  />
                )}
              </>
            )}
          </div>
          <div className={style.buttonWrapper}>
            <Button
              label={'Wróć do produktów'}
              onClick={() => setCurrentStep(0)}
              gray
            />
            <Button
              label={`Wyślij wycenę do ${selectedSuppliers.length} ${handleNumeralWords(
                ['supplier', 'suppliers', 'suppliers'],
                selectedSuppliers.length
              )}`}
              disabled={selectedSuppliers.length === 0}
              isLoading={isValuationCreateLoading}
              onClick={createValuation}
            />
          </div>
        </div>
        {renderedModalConfirm}
      </>
    );
  }

  return (
    <div className={style.container}>
      <header className={style.topHeader}>
        <h2>Krok 1 z 2</h2>
        <p className={style.text}>Wybierz produkty, które chcesz wysłać do wyceny</p>
      </header>
      <div className={style.box}>
        <div className={classNames(shared.tableHeader, style.tableHeader)}>
          <Checkbox
            value={selectedProducts.length === products.length}
            onChange={(e) => onChangeAllProducts(e.target.checked)}
          />
          <p>Produkt</p>
          <p>Kategoria</p>
          <p>Producent</p>
        </div>
        {products.map((prod) => (
          <SingleProduct
            product={prod}
            key={prod.product_id || prod.id}
          >
            <Checkbox
              value={selectedProducts.includes(prod.product_id || prod.id)}
              onChange={(e) => onChange(e.target.checked, prod.product_id || prod.id)}
            />
          </SingleProduct>
        ))}
      </div>
      <div className={style.buttonWrapper}>
        <Button
          label={`Przejdź dalej (${selectedProducts.length}/${products.length} ${handleNumeralWords(
            ['product', 'products', 'products'],
            selectedProducts.length
          )})`}
          disabled={selectedProducts.length === 0}
          onClick={() => setCurrentStep(1)}
        />
      </div>
    </div>
  );
};

export default CreateValuationForm;
