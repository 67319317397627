import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { EmptyState, useIsAdmin, useManageMany, useModalConfirm } from 'components';
import ProductPlaceholder from 'components/layout/ProductPlaceholder';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WholesalersApi } from 'src/api';
import { changeSuppliersVersion } from 'src/features/Suppliers/actions';
import SuppliersItem from 'src/features/Suppliers/components/SuppliersItem';
import { handleNumeralWords } from 'src/utils/helpers';

import style from './SuppliersTable.module.scss';
import shared from 'styles/Shared.module.scss';

const SuppliersTable = ({ list = [], isLoading }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const handleAutoAssignWholesalers = async (supplierIds, resetHandler) => {
    if (supplierIds.length < 1) return;

    try {
      const { data } = await WholesalersApi.autoSetWholesalersToSuppliers(supplierIds);
      notifyCommon([data.message]);
      if (resetHandler) resetHandler();
      dispatch(changeSuppliersVersion());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const openAutoAssignWholesalersConfirm = (supplierIds, resetHandler) => {
    handleOpenModalConfirm({
      message: `Czy na pewno chcesz automatycznie przypisać hurtownie do ${supplierIds.length} ${handleNumeralWords(
        ['supplier', 'suppliers', 'suppliers'],
        supplierIds.length
      )}?`,
      handleConfirm: () => handleAutoAssignWholesalers(supplierIds, resetHandler)
    });
  };

  const { BulkManagementModule, renderSelectAllCheckbox, renderSingleCheckbox, resetHandler } = useManageMany({
    allElements: list,
    options: [
      {
        label: 'Przypisz dostawców automatycznie',
        value: '0',
        onClick: (supplierIds) => openAutoAssignWholesalersConfirm(supplierIds, resetHandler)
      }
    ]
  });

  if (isLoading) {
    return (
      <section className={shared.tableWrapper}>
        <div className={classNames(shared.tableHeader, style.header, { [style.admin]: isAdmin })}>
          <p>Name</p>
          <p>E-mail</p>
          <p>Phone</p>
          {isAdmin && (
            <>
              <p>Hurtownia</p>
              <p>Właściciel</p>
              <p>Ukryj dane</p>
            </>
          )}
        </div>
        <div className={shared.tableCategory}>
          <ProductPlaceholder quantity={6} />
        </div>
      </section>
    );
  }

  if (!list.length) {
    return (
      <section className={shared.tableWrapper}>
        <div className={shared.tableCategory}>
          <EmptyState type={'suppliers'} />
        </div>
      </section>
    );
  }

  return (
    <>
      {isAdmin && BulkManagementModule}
      <section className={classNames(shared.tableWrapper, { [style.tableWrapper]: isAdmin })}>
        <div className={classNames(shared.tableHeader, style.header, { [style.admin]: isAdmin })}>
          <p className={style.checkboxContainer}>
            <span>{renderSelectAllCheckbox()}</span>
            Name
          </p>
          <p>E-mail</p>
          <p>Phone</p>
          {isAdmin && (
            <>
              <p>Hurtownia</p>
              <p>Właściciel</p>
              <p>Ukryj dane</p>
            </>
          )}
        </div>
        <div className={shared.tableCategory}>
          {list.map((sup) => (
            <SuppliersItem
              key={sup.id}
              supplier={sup}
              manageCheckbox={renderSingleCheckbox(sup.id)}
            />
          ))}
        </div>
      </section>
      {isAdmin && BulkManagementModule}
      {renderedModalConfirm}
    </>
  );
};

export default SuppliersTable;
