import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useCompany, useIsAdmin } from 'components';
import ChangePrice from 'components/layout/changeMenu/ChangePrice';
import ChangeQuantity from 'components/layout/changeMenu/ChangeQuantity';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { getFormattedAmount } from 'src/utils/helpers';

import { refreshCartOrCatalog } from '../../actions';

import style from './RefPrice.module.scss';

const RefPrice = ({ price, quantity = 1, className, showRefPrice, selectedCompany, productId, product }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const company = useCompany();
  const [isQtyActive, setIsQtyActive] = useState();
  const [isRefActive, setIsRefActive] = useState(false);

  const { month_volume, unit_name, prices } = product;

  if (!showRefPrice) {
    return null;
  }

  const addPrice = async (newPrice) => {
    const queryData = {
      company_id: selectedCompany,
      product_id: productId,
      type: 'ref_price',
      price: +newPrice
    };

    try {
      await OfferCatalogApi.addPrice(queryData);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const addVolume = async (newVolume) => {
    const queryData = {
      ...(isAdmin && { company_id: selectedCompany }),
      product_id: productId,
      volume: newVolume
    };

    try {
      await OfferCatalogApi.addVolume(queryData);
      dispatch(refreshCartOrCatalog());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const savings = () => {
    const primaryPrice = prices.find((price) => price.priority === 'first');
    if (primaryPrice && price) {
      return getFormattedAmount(Math.max(Math.max(price * quantity, 0) - Math.max(primaryPrice.price, 0), 0));
    } else {
      return 'Brak';
    }
  };

  return (
    <div className={classNames(style.container, className)}>
      <div className={style.box}>
        <p className={style.label}>Wolumen:</p>
        <ChangeQuantity
          tagClassName={style.tag}
          quantity={month_volume || 0}
          unit={unit_name}
          setQuantity={addVolume}
          isActive={isQtyActive}
          displayValueFormatter={() =>
            month_volume === null
              ? 'Brak'
              : +month_volume === 0
              ? `0 ${unit_name}`
              : +month_volume > 0
              ? `${month_volume} ${unit_name}`
              : ''
          }
          setIsActive={setIsQtyActive}
          isOfferCatalogVolumeChange
          productId={productId}
          companyId={isAdmin ? selectedCompany : company.id}
        />
      </div>
      <div className={style.box}>
        <p className={style.label}>Cena ref.:</p>
        {isAdmin ? (
          <ChangePrice
            tagClassName={style.tag}
            price={price}
            setPrice={addPrice}
            isActive={isRefActive}
            setIsActive={setIsRefActive}
            displayValueFormatter={() => (!price || +price === 0 ? 'Brak' : getFormattedAmount(price * quantity))}
            label={'Cena za szt.'}
          />
        ) : (
          <h4 className={style.value}>{!price || +price === 0 ? 'Brak' : getFormattedAmount(price * quantity)}</h4>
        )}
      </div>
      <div className={style.box}>
        <p className={style.label}>Savings:</p>
        <h4 className={style.value}>{savings()}</h4>
      </div>
    </div>
  );
};

export default RefPrice;
