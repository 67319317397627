import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { useIsMobile } from 'components';
import SaveButton from 'components/layout/SaveButton';

import { deleteGift } from 'src/features/SupplierForm/actions';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './Gift.module.scss';

const initialValue = {
  name: '',
  quantity: 1,
  producer: '',
  price: 0
};

const Gift = ({ gift, disabled }) => {
  const [value, setValue] = useState(initialValue);
  const dispatch = useDispatch();
  const isMobile = useIsMobile(1240);

  const handleDeleteGift = () => {
    dispatch(deleteGift(gift?.id || gift?.giftId));
  };

  useEffect(() => {
    if (gift) {
      setValue(gift);
    }
  }, [gift]);

  if (!gift || gift?.deleted) {
    return null;
  }

  return (
    <div className={classNames(style.container, style.center, style.added)}>
      <p>
        {isMobile && 'Name: '}
        {value.name}
      </p>
      <p>
        {isMobile && 'Qty.: '}
        {value.quantity}
      </p>
      <p>
        {isMobile && 'Producer: '}
        {value.producer}
      </p>
      <p>
        {isMobile && 'Price: '}
        {getFormattedAmount(value.price)}
      </p>
      {!disabled && (
        <SaveButton
          type={'delete'}
          onClick={handleDeleteGift}
          wrapperStyle={style.button}
        />
      )}
    </div>
  );
};

export default Gift;
