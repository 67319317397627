import React, { useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { AddressForm, Icon, Menu, Modal, Tag, useAuthUser, useModalConfirm, usePermissions } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi, CompanyApi } from 'src/api';
import { userPermissions, userRoles } from 'src/constants/enums';
import { changeCompanyAddressesVersion } from 'src/features/CompaniesList/actions';
import { refreshUserData } from 'src/features/onboarding/actions';
import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from '../details.module.scss';

const AddressDetails = ({ details, inProfile = false, onClick, readOnly, companyId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();
  const dispatch = useDispatch();
  const editRef = useRef();
  const deleteRef = useRef();
  const user = useAuthUser();

  if (!details) {
    return null;
  }

  const { name, company_name, email, phone, address, is_default } = details || {};
  const { street, building_number, apartment_number, zip_code, city } = address || {};

  const ApiClass = useMemo(() => (user?.role === userRoles.admin ? AdminApi : CompanyApi), [user]);
  const [canEdit] = usePermissions([userPermissions.company.admin]);
  const refreshCallbackAction = useMemo(() => (companyId ? changeCompanyAddressesVersion : refreshUserData), [companyId]);

  const setAsDefault = async (isDefault) => {
    const data = {
      ...details,
      ...(user?.role === userRoles.admin && { company_id: companyId }),
      shipping_id: details.id,
      is_default: isDefault
    };

    try {
      await ApiClass.updateShippingDetails(data);
      notifyCommon(['Adres został ustawiony jako domyślny.']);
      await dispatch(refreshCallbackAction());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteAddress = async () => {
    try {
      await ApiClass.deleteShippingDetails(details.id);
      notifyCommon(['Adres został usunięty.']);
      await dispatch(refreshCallbackAction());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleDeleteAddress = () => {
    handleOpenModalConfirm({
      message: 'Czy na pewno chcesz usunąć ten adres?',
      handleConfirm: deleteAddress
    });
  };

  const actions = [
    {
      title: details.is_default ? 'Usuń domyślny' : 'Ustaw jako domyślny',
      icon: 'ticket',
      action: () => setAsDefault(!details.is_default)
    },
    {
      title: 'Edit',
      icon: 'edit',
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: handleDeleteAddress
    }
  ];

  const handleClick = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleOnClick = (e) => {
    if (
      readOnly ||
      e.target === editRef.current ||
      e.target.parentNode === editRef.current ||
      e.target === deleteRef.current ||
      e.target.parentNode === deleteRef.current
    ) {
      e.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <div
        className={classNames(style.details, {
          [style.click]: !!onClick,
          [style.default]: is_default
        })}
        onClick={handleOnClick}
      >
        {!!is_default && (
          <Tag
            value={'Default'}
            className={style.tag}
          />
        )}
        <p className={style.text}>{name || company_name}</p>
        <p className={style.text}>
          ul. {street} {building_number}
          {apartment_number ? `/${apartment_number}` : null}
        </p>
        <p className={style.text}>
          {zip_code} {city}
        </p>
        <p className={style.text}>{email}</p>
        <p className={style.text}>{getFormattedPhoneNumber(phone)}</p>
        {!readOnly &&
          canEdit &&
          (inProfile ? (
            <Menu
              actions={actions}
              className={style.menu}
            />
          ) : (
            <>
              <button
                className={style.edit}
                onClick={handleClick}
                ref={editRef}
              >
                <Icon
                  name={'edit'}
                  fill={'#6F767E'}
                />
              </button>
              <button
                className={style.delete}
                onClick={handleDeleteAddress}
                ref={deleteRef}
              >
                <Icon
                  name={'trash'}
                  fill={'#ea6c64'}
                />
              </button>
            </>
          ))}
        <span className={style.tag}></span>
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={'Edytuj adres'}
      >
        <AddressForm
          addressData={details}
          closeModal={() => setIsModalOpen(false)}
          companyId={companyId}
        />
      </Modal>
      {renderedModalConfirm}
    </>
  );
};

export default AddressDetails;
