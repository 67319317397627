import React, { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import classNames from 'classnames';

import DropzoneArea from './components/DropzoneArea';
import List from './components/List';
import ProductDetails from './components/ProductDetails';

import style from './SuppliersList.module.scss';

const SuppliersList = ({ product, isQuietLoading }) => {
  const [isDragged, setIsDragged] = useState(false);

  if (!product) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classNames(style.container, { [style.isQuietLoading]: isQuietLoading })}>
        <ProductDetails product={product} />
        <p className={style.description}>
          To set an offer as <strong>preferred</strong>, <strong>alternative</strong> or <strong>backup</strong>, drag an item
          from the list to the appropriate field. Assigned offers will be taken into account when placing an order.
        </p>
        <DropzoneArea
          product={product}
          isDragged={isDragged}
          setIsDragged={setIsDragged}
        />
        <List
          product={product}
          setIsDragged={setIsDragged}
        />
      </div>
    </DndProvider>
  );
};

export default SuppliersList;
