import React, { useState } from 'react';

import { Menu, Modal, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ServicesApi } from 'src/api';
import CreateServiceForm from 'src/features/Services/components/ServicersList/components/CreateServiceForm';
import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './ServicerThumbnail.module.scss';

const ServicerThumbnail = ({ servicer, refreshCallback }) => {
  const { email, id, keeper, name, nip, phone } = servicer;

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteServicer = async () => {
    try {
      await ServicesApi.deleteServicer(id);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteServicerHandler = () =>
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć serwisanta?',
      handleConfirm: deleteServicer,
      confirmButtonName: 'Tak',
      cancelButtonName: 'Nie'
    });

  const actions = [
    {
      title: 'Edit',
      icon: 'edit',
      color: 'blue',
      action: () => setIsEditOpen(true)
    },
    {
      title: 'Delete',
      icon: 'trash',
      color: 'red',
      action: deleteServicerHandler
    }
  ];

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <p>{name}</p>
      </div>
      <div className={style.wrapper}>
        <p>{keeper}</p>
      </div>
      <div className={style.wrapper}>
        <p>{email}</p>
      </div>
      <div className={style.wrapper}>
        <p>{phone ? getFormattedPhoneNumber(phone) : '-'}</p>
      </div>
      <div className={style.wrapper}>
        <p>{nip}</p>
      </div>
      <Menu
        actions={actions}
        className={style.menu}
      />
      <Modal
        onClose={() => setIsEditOpen(false)}
        visible={isEditOpen}
        title={'Dodaj serwisanta'}
      >
        <CreateServiceForm
          onClose={() => setIsEditOpen(false)}
          refreshCallback={refreshCallback}
          servicerData={servicer}
        />
      </Modal>
      {renderedModalConfirm}
    </div>
  );
};

export default ServicerThumbnail;
