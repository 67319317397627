import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, SwitchButton } from 'components';

import { changeViewType } from '../../../../actions';
import AddNewPrice from '../../../AddNewPrice';
import FlatFileHandling from '../../../FlatFileHandling';
import PendingValuations from '../../../PendingValuations';
import ChangedPricesProductsList from './components/ChangedPricesProductsList';
import Tile from './components/Tile';

import managementStyle from '../../CatalogManagement.module.scss';
import style from './Management.module.scss';

const Management = () => {
  const dispatch = useDispatch();

  const [isVolumeModalOpen, setIsVolumeModalOpen] = useState(false);
  const [isFlatFileModalOpen, setIsFlatFileModalOpen] = useState(false);
  const [isExportChangedPricesOpen, setIsExportChangedPricesOpen] = useState(false);

  const isDefinedView = useSelector((state) => state.cartAndCatalog.isDefinedView);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const changeView = (value) => dispatch(changeViewType(value));
  const closeVolumesModal = () => setIsVolumeModalOpen(false);
  const closeFlatFileModal = () => setIsFlatFileModalOpen(false);
  const closeExportChangedPrices = () => setIsExportChangedPricesOpen(false);

  const tiles = [
    {
      id: 1,
      icon: null,
      title: 'Add new product',
      description: 'It allows you to expand the catalog with offers for products that are not yet in our catalog',
      action: (
        <AddNewPrice
          asLink
          selectedCompany={selectedCompany}
        />
      )
    },
    {
      id: 2,
      icon: null,
      title: 'Import and export of offers',
      description:
        'It allows you to download a catalog with offers from a given supplier or a list of products with room for offers in .csv or .xlsx format.',
      action: <a onClick={() => setIsFlatFileModalOpen(true)}>Click to open the form</a>
    },
    {
      id: 3,
      icon: null,
      title: 'Import and export of volumes',
      description: 'It allows you to download a product list in .csv or .xlsx format to complete monthly product volume data.',
      action: <a onClick={() => setIsVolumeModalOpen(true)}>Click to open the form</a>
    },
    {
      id: 4,
      icon: null,
      title: 'Valuations in progress',
      content: <PendingValuations selectedCompany={selectedCompany} />
    },
    {
      id: 5,
      icon: null,
      title: 'Show full view',
      description: 'It allows you to display all available offers for given products',
      action: (
        <SwitchButton
          reverse
          value={!isDefinedView}
          setValue={(value) => changeView(!value)}
          label={'Show full view'}
        />
      )
    },
    {
      id: 6,
      icon: null,
      title: 'Prices',
      description: 'Allows you to see or download a list of products whose price has been changed.',
      action: (
        <>
          <a onClick={() => setIsExportChangedPricesOpen(true)}>Click to see the products</a>
        </>
      )
    }
  ];

  return (
    <div className={style.container}>
      <div className={managementStyle.header}>
        <h2>Management</h2>
        <p className={managementStyle.text}>
          Customize the offer view, download and import new price lists or individual offers.
        </p>
      </div>
      <div className={style.wrapper}>
        {tiles.map((tile) => (
          <Tile
            key={tile.id}
            tile={tile}
          />
        ))}
      </div>
      <Modal
        visible={isFlatFileModalOpen}
        onClose={closeFlatFileModal}
        title={'Import & Export offers'}
      >
        <FlatFileHandling
          type={'flatFile'}
          closeModal={closeFlatFileModal}
          selectedCompany={selectedCompany}
        />
      </Modal>
      <Modal
        visible={isVolumeModalOpen}
        onClose={closeVolumesModal}
        title={'Import & Export volumes'}
      >
        <FlatFileHandling
          type={'volumes'}
          closeModal={closeVolumesModal}
          selectedCompany={selectedCompany}
        />
      </Modal>
      <Modal
        visible={isExportChangedPricesOpen}
        onClose={closeExportChangedPrices}
        title={'Products whose price has been changed'}
      >
        <ChangedPricesProductsList />
      </Modal>
    </div>
  );
};

export default Management;
