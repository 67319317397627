import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useAtom } from 'jotai/index';

import {
  ChangeValue,
  CommentModal,
  Icon,
  ItemTagsList,
  Menu,
  Modal,
  RelativeTime,
  useIsAdmin,
  useIsMobile,
  useModalConfirm
} from 'components';
import AddProductToList from 'components/layout/AddProductToList';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { WarehouseApi } from 'src/api';
import { selectedCompanyAtom } from 'src/features/Warehouse';
import ProductComment from 'src/features/Warehouse/new/ProductComment';
import ProductDetails from 'src/features/Warehouse/new/ProductDetails';
import { getExpireDateStatus } from 'src/utils/dateTime';
import { getFormattedAmount, imageErrorHandler } from 'src/utils/helpers';

import style from './ProductThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const ProductThumbnail = ({ product, refreshData, children, onlyName }) => {
  const {
    out_of_stock_quantity,
    latest_expire_date,
    top_category_name,
    product_comment,
    producer_name,
    newest_price,
    product_id,
    thumbnail,
    quantity,
    tags,
    name,
    id
  } = product;

  const isAdmin = useIsAdmin();
  const isMobile = useIsMobile();

  const [selectedCompany] = useAtom(selectedCompanyAtom);

  const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
  const [isProductCommentOpen, setIsProductCommentOpen] = useState(false);
  const [isModalAddToListOpen, setIsModalAddToListOpen] = useState(false);
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteProduct = async () => {
    try {
      await WarehouseApi.deleteProduct(id);
      if (refreshData) refreshData();
      notifyCommon([`Product "${name}" has been deleted from warehouse.`]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const saveOutOfStockQuantity = async (qty) => {
    const params = {
      ...(isAdmin && { company_id: selectedCompany }),
      out_of_stock_quantity: qty,
      wh_product_id: id,
      product_id
    };

    try {
      await WarehouseApi.addOrUpdateProduct(params);
      if (refreshData) await refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteProductHandler = () => {
    handleOpenModalConfirm({
      message: 'Are you sure you want to remove the product? This action cannot be undone.',
      handleConfirm: deleteProduct
    });
  };

  const actions = [
    {
      title: 'Add to list',
      icon: 'add',
      action: () => setIsModalAddToListOpen(true)
    },
    {
      title: 'Add comment',
      icon: 'message',
      action: () => setIsProductCommentOpen(true)
    },
    {
      title: 'Delete product',
      icon: 'trash',
      color: 'red',
      action: deleteProductHandler
    }
  ];

  const outOfStockQuantityElement = (
    <ChangeValue
      apiCallback={saveOutOfStockQuantity}
      initialValue={out_of_stock_quantity}
      type={'number'}
      label={'Change min. level'}
      placement={'bottom'}
      unit={'pcs.'}
      icon={
        <Icon
          name={'edit'}
          fill={'#888'}
        />
      }
    />
  );

  const quantityWrapperStyles = useMemo(
    () =>
      cn(style.wrapper, {
        [style.error]: quantity < out_of_stock_quantity
      }),
    [quantity, out_of_stock_quantity]
  );

  const expireDateWrapperStyles = useMemo(() => {
    const type = getExpireDateStatus(latest_expire_date);
    return cn(style.wrapper, { [style[type]]: !!type }, style.expireDate);
  }, [latest_expire_date]);

  if (onlyName) {
    return (
      <div className={cn(style.container, { [style.withChildren]: !!children, [style.onlyName]: onlyName })}>
        {!!children && <div className={cn(style.wrapper, style.checkbox)}>{children}</div>}
        <a onClick={() => setIsProductDetailsOpen(true)}>
          <div className={style.wrapper}>
            {!isMobile && (
              <img
                src={thumbnail}
                alt={name}
                className={cn(shared.defaultThumbnail)}
                loading={'lazy'}
                onError={imageErrorHandler}
              />
            )}
            <p className={style.text}>{name}</p>
          </div>
        </a>
        <div className={style.productMeta}>
          <p>
            <span>Producer: </span>
            {producer_name}
          </p>
          <p>
            <span>Category: </span>
            {top_category_name}
          </p>
        </div>
        <Modal
          title={'Product details'}
          visible={isProductDetailsOpen}
          onClose={() => setIsProductDetailsOpen(false)}
        >
          <ProductDetails
            productData={product}
            refreshData={refreshData}
          />
        </Modal>
      </div>
    );
  }

  return (
    <div className={cn(style.container, { [style.withChildren]: !!children })}>
      <div className={style.wrapper}>
        <p className={style.text}>
          <ItemTagsList
            displayOnly
            tags={tags ? tags : []}
          />
        </p>
      </div>
      <div className={style.wrapper}>{outOfStockQuantityElement}</div>
      <div className={quantityWrapperStyles}>{quantity} pcs.</div>
      <div className={style.wrapper}>
        <p>{newest_price ? getFormattedAmount(newest_price) : '-'}</p>
      </div>
      <div className={style.wrapper}>
        <p>{getFormattedAmount(Math.max(newest_price, 0) * Math.max(quantity, 0))}</p>
      </div>
      <div className={expireDateWrapperStyles}>{latest_expire_date ? <RelativeTime date={latest_expire_date} /> : '-'}</div>

      <div className={style.wrapper}>
        <CommentModal
          comment={product_comment}
          title={name}
          modalTitle={'Product comment'}
        />
      </div>
      <div className={style.wrapper}>
        <Menu
          actions={actions}
          className={style.menu}
        />
      </div>
      <AddProductToList
        title={`Add "${name}" to list`}
        visible={isModalAddToListOpen}
        onClose={() => setIsModalAddToListOpen(false)}
        productId={product_id}
      />
      {renderedModalConfirm}
      <Modal
        title={'Add comment'}
        visible={isProductCommentOpen}
        onClose={() => setIsProductCommentOpen(false)}
      >
        <ProductComment
          product={product}
          onClose={() => {
            setIsProductCommentOpen(false);
            refreshData();
          }}
        />
      </Modal>
    </div>
  );
};

export default ProductThumbnail;
