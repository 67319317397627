import React, { useState } from 'react';

import { OptionSwitch } from 'components';
import AddToListForm from 'components/layout/AddProductToList/components/AddToListForm';

import CreateList from '../../../Lists/components/CreateList';

import style from './OrderCancelledProducts.module.scss';

const OrderCancelledProducts = ({ products, selectedProducts, productId, onClose, orderID }) => {
  const [isNewList, setIsNewList] = useState(false);

  return (
    <div className={style.container}>
      <OptionSwitch
        falseLabel={'Add to list'}
        trueLabel={'Utwórz nową listę'}
        defaultOption={isNewList}
        onChange={setIsNewList}
      />
      {isNewList ? (
        <CreateList
          orderID={orderID}
          closeModal={onClose}
          selectedProducts={selectedProducts}
          products={products}
        />
      ) : (
        <AddToListForm
          orderID={orderID}
          products={products}
          selectedProducts={selectedProducts}
          productId={productId}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default OrderCancelledProducts;
