import React, { useState } from 'react';
import classNames from 'classnames';

import { Gifts as GiftModalContent, Modal, Tag } from 'components';

import { getFormattedAmount, getGiftsValue } from 'src/utils/helpers';

import style from '../../Column.module.scss';

const Gifts = ({ gifts = [], supplier, lastElement }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={classNames(style.commentsAndOffers, {
        [style.lastElement]: lastElement
      })}
    >
      {gifts.length === 0 ? (
        <span className={style.giftsAndCommentWrapper}>
          <Tag value={'No freebies'} />
        </span>
      ) : (
        <div className={style.giftsAndCommentWrapper}>
          <div className={style.giftWrapper}>
            <p>Ilość:</p>
            <p className={style.giftValue}>{gifts.length}</p>
          </div>
          <div className={style.giftWrapper}>
            <p>Wartość:</p>
            <p className={style.giftValue}>{getFormattedAmount(getGiftsValue(gifts))}</p>
          </div>
          <a
            onClick={() => setIsOpen(true)}
            className={style.comment}
          >
            Zobacz szczegoły
          </a>
        </div>
      )}
      <Modal
        visible={isOpen}
        onClose={() => setIsOpen(false)}
        title={`Gratisy do zamówienia od ${supplier.name}`}
      >
        <GiftModalContent gifts={gifts} />
      </Modal>
    </div>
  );
};

export default Gifts;
