import React from 'react';
import classNames from 'classnames';

import { useIsMobile } from 'components';

import { getFormattedAmount } from 'src/utils/helpers';

import style from '../../Gifts.module.scss';

const GiftThumbnail = ({ gift }) => {
  const { price, quantity } = gift;
  const isMobile = useIsMobile(768);

  return (
    <div className={style.container}>
      <div className={style.thumbnailWrapper}>
        <p className={style.text}>
          {isMobile && 'Nazwa: '}
          {gift.name}
        </p>
      </div>
      <div className={style.thumbnailWrapper}>
        <p className={style.text}>
          {isMobile && 'Producer: '}
          {gift.producer}
        </p>
      </div>
      <div className={style.thumbnailWrapper}>
        <p className={style.text}>
          {isMobile && 'Ilość: '}
          {quantity}
        </p>
      </div>
      <div className={style.thumbnailWrapper}>
        <p className={classNames(style.text, style.amount)}>
          {isMobile && 'Wartość: '}
          {getFormattedAmount(price)}
        </p>
      </div>
    </div>
  );
};

export default GiftThumbnail;
