import React from 'react';
import cn from 'classnames';

import { EmptyState } from 'components';

import { supplierTypes } from 'src/constants/enums';

import AddNewPrice from '../../../AddNewPrice';
import SupplierThumbnail from '../SupplierThumbnail';

import style from '../../SuppliersList.module.scss';
import shared from 'styles/Shared.module.scss';

const List = ({ product, setIsDragged, selectedCompany }) => {
  const prices = product.prices?.filter((p) => p.priority === supplierTypes.normal) || [];

  return (
    <div className={style.suppliersWrapper}>
      <h4 className={cn(shared.tableCategoryTitle, style.tableTitle)}>
        <span>
          Other offers <span className={shared.counter}>({prices.length})</span>
        </span>
        <AddNewPrice
          asButton
          selectedCompany={selectedCompany}
          productId={product.product_id}
        />
      </h4>
      <div className={style.scrollWrapper}>
        {prices.length ? (
          prices.map((price) => (
            <SupplierThumbnail
              key={price.id}
              item={price}
              setIsDragged={setIsDragged}
            />
          ))
        ) : (
          <EmptyState
            type={'supplierChoice'}
            tiny
          />
        )}
      </div>
    </div>
  );
};

export default List;
