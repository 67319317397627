import React from 'react';
import Skeleton from 'react-loading-skeleton';
import cn from 'classnames';

import { Menu, useModalConfirm } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';
import { getFormattedAmount, getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './ThresholdThumbnail.module.scss';

export const ThresholdSkeleton = () => {
  return (
    <div className={style.container}>
      <h3 className={style.title}>
        <Skeleton
          width={260}
          height={20}
        />
      </h3>
      <Skeleton
        width={230}
        height={15}
      />
      <p></p>
      <span className={style.amount}>
        <Skeleton
          width={70}
          height={18}
        />
      </span>
    </div>
  );
};

const ThresholdThumbnail = ({ item, isLast, handleEdit, refreshData }) => {
  const { email, first_name, last_name, phone, threshold } = item;
  const [renderedModalConfirm, handleOpenModalConfirm] = useModalConfirm();

  const deleteThreshold = async () => {
    try {
      await CompanyApi.deleteThreshold(item.id);
      await refreshData();
    } catch (err) {
      notifyApiError(err);
    }
  };

  const deleteHandler = () =>
    handleOpenModalConfirm({
      message: 'Na pewno chcesz usunąć próg?',
      handleConfirm: deleteThreshold
    });

  const actions = [
    {
      title: 'Edit',
      icon: 'edit',
      action: handleEdit
    },
    {
      title: 'Usuń próg',
      icon: 'trash',
      color: 'red',
      action: deleteHandler
    }
  ];

  return (
    <div className={cn(style.container, { [style.withIndicator]: !isLast })}>
      <h3 className={style.title}>
        {first_name} {last_name}
      </h3>
      <p>{email}</p>
      {!!phone && <p>{getFormattedPhoneNumber(phone)}</p>}
      <span className={style.amount}>{getFormattedAmount(threshold)}</span>
      <Menu
        actions={actions}
        className={style.menu}
      />
      {renderedModalConfirm}
    </div>
  );
};

export default ThresholdThumbnail;
