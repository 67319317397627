import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import placeholder from 'assets/img/product-thumbnail.png';
import { Menu, useIsMobile } from 'components';
import BlurDataInfo from 'components/layout/BlurDataInfo';

import { getFormattedPhoneNumber } from 'src/utils/helpers';

import style from './ClientThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

const ClientThumbnail = ({ company }) => {
  const { name, logo_small, phone, nip, uid, valuations_count, orders_count, client_data_hidden } = company;
  const isMobile = useIsMobile(1240);
  const history = useHistory();

  const actions = [
    {
      title: 'Przejdź do wycen',
      icon: 'share',
      action: () => history.push(`/supplier/valuations?search=${nip}`)
    },
    {
      title: 'Przejdź do zamówień',
      icon: 'share',
      action: () => history.push(`/supplier/orders?search=${nip}`)
    },
    {
      title: 'Przejdź do oferty',
      icon: 'share',
      action: () => history.push(`/supplier/collections/${uid}`)
    }
  ];

  return (
    <div className={classnames(style.container, { [style.blur]: client_data_hidden })}>
      <div className={classnames(style.wrapper, style.userDetails)}>
        <img
          className={classnames(shared.defaultThumbnail, shared.tiny)}
          src={logo_small || placeholder}
          loading={'lazy'}
          alt={name}
        />
        <p className={classnames(style.text, style.name)}>{name}</p>
        {!!client_data_hidden && <BlurDataInfo />}
      </div>
      <div className={classnames(style.wrapper)}>
        <p className={classnames(style.text, style.name)}>{nip}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.label}>Phone:</p>}
        <p className={style.text}>{phone ? getFormattedPhoneNumber(phone) : '-'}</p>
      </div>
      <div className={style.wrapper}>
        <p className={style.text}>{orders_count}</p>
      </div>
      <div className={style.wrapper}>
        {isMobile && <p className={style.label}>Data logowania:</p>}
        <p className={style.text}>{valuations_count}</p>
      </div>
      {!client_data_hidden && (
        <Menu
          actions={actions}
          className={style.menu}
        />
      )}
    </div>
  );
};

export default ClientThumbnail;
