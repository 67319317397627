import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Card } from 'components';
import BlurDataInfo from 'components/layout/BlurDataInfo';

import { companyPlaceholder } from 'src/constants/misc';

import CompanyDetails from '../CompanyDetails';

import style from './ClientDetails.module.scss';
import shared from 'styles/Shared.module.scss';
const ClientDetails = () => {
  const comment = useSelector((state) => state.supplierForm.list_comment);
  const company = useSelector((state) => state.supplierForm.company);
  const areDataHidden = useSelector((state) => state.supplierForm.client_data_hidden);

  if (areDataHidden) {
    return (
      <div className={style.container}>
        <Card className={classNames(shared.defaultWrapper, style.card)}>
          <h2 className={style.title}>
            Customer data
            <BlurDataInfo />
          </h2>
          <CompanyDetails
            company={companyPlaceholder}
            areDataHidden
          />
        </Card>
        {comment && (
          <Card className={classNames(shared.defaultWrapper, style.card)}>
            <h2 className={style.title}>Komentarz klienta do wyceny</h2>
            <p className={style.comment}>{comment}</p>
          </Card>
        )}
      </div>
    );
  }

  return (
    <div className={style.container}>
      {company && (
        <Card className={classNames(shared.defaultWrapper, style.card)}>
          <h2 className={style.title}>Dane klienta</h2>
          <CompanyDetails company={company} />
        </Card>
      )}
      {comment && (
        <Card className={classNames(shared.defaultWrapper, style.card)}>
          <h2 className={style.title}>Komentarz klienta do wyceny</h2>
          <p className={style.comment}>{comment}</p>
        </Card>
      )}
    </div>
  );
};

export default ClientDetails;
