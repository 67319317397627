import React from 'react';
import { useSelector } from 'react-redux';

import { Card } from 'components';

import { getSelectedId, useSupplierFormValidationForceUpdate } from '../../../../helpers';
import AddShipment from '../../../AddShipment';
import ShippingSelection from '../../../ShippingSelection';

import style from '../../OrderDetails.module.scss';
import shared from 'styles/Shared.module.scss';

const Shipments = ({ uid, disabled }) => {
  useSupplierFormValidationForceUpdate();
  const shipments = useSelector((store) => store.supplierForm.form.shipments);
  const validator = useSelector((state) => state.supplierForm.validator);

  const selectedShipmentsId = getSelectedId(shipments);

  return (
    <Card
      className={shared.defaultWrapper}
      id={'shipments'}
    >
      <h2 className={style.title}>Shipping method</h2>
      {shipments.map((method) => (
        <ShippingSelection
          key={method.id}
          uid={uid}
          shippingMethod={method}
          disabled={disabled}
          showError={!!validator?.message('shipments', selectedShipmentsId, 'required')}
        />
      ))}
      <span className={style.errorMessage}>{validator?.message('shipments', selectedShipmentsId, 'required')}</span>
      <AddShipment uid={uid} />
    </Card>
  );
};

export default Shipments;
