import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { Button, Input, PhoneInput, Select, useValidator } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { changeUsersVersion } from 'src/features/Users/actions';
import { getRoleColorCode, getUserRoleLabel } from 'src/utils/helpers';
import { imageErrorHandler } from 'src/utils/helpers';

import style from './EditUserData.module.scss';

const EditUserData = ({ user, closeModal }) => {
  const { email: currentEmail, id, original_profile_image } = user;

  const [isLoading, setIsLoading] = useState(true);
  const [selectedRole, setSelectedRole] = useState();
  const [roles, setRoles] = useState([]);
  const [phone, setPhone] = useState(user.phone);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);

  const dispatch = useDispatch();
  const validator = useValidator();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validator.allValid()) {
      validator.showMessages();
      return null;
    }

    const data = {
      ...user,
      name,
      phone,
      email,
      role: selectedRole?.value
    };

    setIsLoading(true);
    try {
      await AdminApi.putUserData(id, data);
      dispatch(changeUsersVersion());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  const formatRolesToOptions = (roles) =>
    roles.map((role) => ({
      value: role,
      label: getUserRoleLabel(role),
      color: getRoleColorCode(role)
    }));

  const getRoles = async () => {
    setIsLoading(true);
    try {
      const { data } = await AdminApi.getUserRoles();
      setRoles(formatRolesToOptions(data));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (roles.length) {
      setSelectedRole(roles.find((role) => role.value === user.role));
    }
  }, [roles]);

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <header className={style.header}>
        <img
          src={original_profile_image || ''}
          className={style.picture}
          alt='Zdjęcie profilowe'
          onError={imageErrorHandler}
        />
        <div className={style.wrapper}>
          <p className={style.importantData}>{user.name}</p>
          <p className={classNames(style.importantData, style.email)}>{currentEmail}</p>
          <Select
            wrapperStyle={style.selectSmall}
            options={roles}
            value={selectedRole}
            onChange={setSelectedRole}
            placeholder={'Rola'}
            label={'Wybierz rolę'}
            isDisabled={isLoading}
          />
        </div>
      </header>
      <div className={style.container}>
        <Input
          id='name'
          name='name'
          label='Nazwa'
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          disabled={isLoading}
          validator={validator}
          rule={'required'}
        />
        <Input
          id='email'
          name='email'
          label='E-mail'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          disabled={isLoading}
          validator={validator}
          rule={'required|email'}
        />
        <PhoneInput
          id='phone'
          name='phone'
          label='Phone'
          value={phone}
          onChange={setPhone}
          disabled={isLoading}
          validator={validator}
          rule={'required|phone'}
        />
        <Button
          label={'Zapisz'}
          type={'submit'}
          className={style.button}
          isLoading={isLoading}
        />
      </div>
    </form>
  );
};

export default EditUserData;
