import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import cn from 'classnames';

import { Button, Card, Checkbox, Textarea, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OrdersApi, WarehouseApi } from 'src/api';
import { productStatuses } from 'src/constants/enums';
import { getOrderProductModalContent } from 'src/constants/misc';
import { handleNumeralWords } from 'src/utils/helpers';

import { refreshOrder } from '../../../../actions';
import ProductSelector from '../ProductSelector';

import style from './ProductsSelect.module.scss';

export const ADD_TO_WAREHOUSE = 'ADD_TO_WAREHOUSE';
export const UNDO_STATUS = 'UNDO_STATUS';

const getComplaintOrRefundedComment = (comment, products = [], newStatus) => `
    Zgłoszono ${newStatus === productStatuses.complaint ? 'reklamację' : 'zwrot'} ${products.length} ${handleNumeralWords(
  ['product', 'products', 'products'],
  products.length
)}:
    ${products.map((prod, index) => `${index + 1}. ${prod.name}`).join('\n')}

    Komentarz do ${newStatus === productStatuses.complaint ? 'reklamacji' : 'zwrotu'}: 
    ${comment} 
    `;

const ProductsSelect = ({
  products = [],
  newStatus,
  closeModal,
  supplierId,
  suborderUid,
  orderHistory,
  partialCollect = false
}) => {
  const { uid } = useParams();
  const dispatch = useDispatch();
  const validator = useValidator();

  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [productsList, setProductsList] = useState([]);

  const isCommentAllowed = useMemo(() => [productStatuses.complaint, productStatuses.refunded].includes(newStatus), [newStatus]);
  const formContent = useMemo(() => getOrderProductModalContent(newStatus, orderHistory), [newStatus, orderHistory]);
  const onlySelectedProducts = useMemo(() => productsList.filter((prod) => prod.isSelected), [productsList]);

  const allowedProducts = useMemo(() => {
    const allowed = products
      .filter((product) => formContent.allowedStatuses.includes(product.status))
      .filter((prod) => (formContent.extraFilter ? formContent.extraFilter(prod) : true));
    setProductsList(allowed);
    return allowed;
  }, [products, formContent]);

  const selectAll = () => {
    setProductsList(
      allowedProducts.map((prod) => ({ ...prod, isSelected: onlySelectedProducts.length !== allowedProducts.length }))
    );
  };

  const onProductChange = (product) => {
    setProductsList((prev) => prev.map((item) => (item.id === product.id ? product : item)));
  };

  useEffect(() => {
    if (newStatus === productStatuses.completed) {
      const wasSomeProductSent = allowedProducts.some((prod) => prod.send_quantity > 0);

      if (wasSomeProductSent) {
        setProductsList(
          allowedProducts.map((prod) => ({
            ...prod,
            currentQuantity:
              prod.status === productStatuses.processing && prod.send_quantity === 0
                ? 0
                : Math.max((prod.send_quantity || prod.qty) - prod.received_quantity, 0)
          }))
        );
      } else {
        setProductsList(
          allowedProducts.map((prod) => ({
            ...prod,
            currentQuantity: Math.max(prod.qty - prod.received_quantity, 0)
          }))
        );
      }
    }
  }, [products, formContent]);

  const changeItemSelection = (item) => {
    setProductsList((prev) => prev.map((prod) => (prod.id === item.id ? item : prod)));
  };

  const addCommentHandler = useCallback(
    (comment) =>
      OrdersApi.sendSystemMessage({
        uid,
        message: getComplaintOrRefundedComment(comment, onlySelectedProducts, newStatus),
        supplier_id: supplierId
      }),
    [supplierId, onlySelectedProducts, newStatus, comment]
  );

  const getProductStatusesToUndo = useCallback(() => {
    let products = onlySelectedProducts.map(({ id }) => ({ id, status: productStatuses.pending }));

    if (formContent.wasSupplierAcceptedOrder) {
      products = products.map((prod) => ({ ...prod, status: productStatuses.processing }));
    }

    if (formContent.wasSupplierSentOrder) {
      products = products.map((prod) => ({ ...prod, status: productStatuses.sent }));
    }

    if (formContent.complainedProductsIds?.length > 0) {
      products = products.map((prod) =>
        formContent.complainedProductsIds.includes(prod.id) ? { ...prod, status: productStatuses.complaint } : prod
      );
    }

    return products;
  }, [productsList, formContent]);

  const undoProductsStatuses = async () => {
    const { pending, complaint, processing, sent } = productStatuses;
    const products = getProductStatusesToUndo();

    const undoStatus = async (status) => {
      let items = products.filter((prod) => prod.status === status);
      if (items.length > 0) await changeProductStatus(items, status, true);
    };

    const promises = [pending, complaint, processing, sent].map(undoStatus);
    await Promise.all(promises);
  };

  const changeProductStatus = async (products = onlySelectedProducts, status = newStatus, is_reversing) => {
    const data = {
      product_ids: products.map((prod) => prod.id),
      is_reversing,
      status,
      uid
    };

    if (isCommentAllowed && !validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await OrdersApi.changeProductsStatus(data);
      if (isCommentAllowed) await addCommentHandler(comment);
      if (formContent?.notify) notifyCommon([formContent.notify]);
      dispatch(refreshOrder());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addSelectedProductsToWarehouse = async () => {
    const selectedProducts = productsList.filter((prod) => prod.isSelected);
    const data = {
      order_link_uid: suborderUid,
      products: selectedProducts.map((prod) => ({
        product_id: prod.product_id,
        expire_date: prod.expireDate || null
      }))
    };

    try {
      setIsLoading(true);
      await WarehouseApi.addOrderSupply(data);
      notifyCommon([formContent.notify]);
      dispatch(refreshOrder());
      closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const collectPartOfProductAmountHandler = async () => {
    const queryData = {
      uid,
      products: productsList
        .filter((prod) => prod.currentQuantity > 0)
        .map((prod) => ({ product_id: prod.product_id, received_quantity: prod.currentQuantity }))
    };

    if (!validator.allValid()) {
      return validator.showMessages();
    }

    try {
      setIsLoading(true);
      await OrdersApi.partialReceiveProducts(queryData);
      closeModal();
      notifyCommon(['Produkty zostały odebrane.']);
      dispatch(refreshOrder());
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const changeProductStatusHandler = useMemo(() => {
    switch (newStatus) {
      case UNDO_STATUS:
        return undoProductsStatuses;
      case ADD_TO_WAREHOUSE:
        return addSelectedProductsToWarehouse;
      case productStatuses.completed:
        return collectPartOfProductAmountHandler;
      default:
        return changeProductStatus;
    }
  }, [newStatus, suborderUid, onlySelectedProducts, productsList, comment]);

  if (allowedProducts.length === 0) {
    return <p className={style.emptyState}>{formContent.emptyState}</p>;
  }

  return (
    <div className={style.container}>
      {isCommentAllowed && (
        <Textarea
          value={comment}
          setValue={setComment}
          label={'Komentarz dla dostawcy'}
          rule={'required'}
          validator={validator}
        />
      )}
      {partialCollect && (
        <Card
          yellow
          className={style.announcementCard}
        >
          Uwaga! Niektóre z produktów <strong>zostały wysłane</strong> przez dostawcę <strong>częściowo!</strong> Zwróć uwagę na
          status produktu.
        </Card>
      )}

      <div className={cn(style.header, style.wrapperHeader)}>
        <p className={style.text}>{formContent.description}</p>
        {![ADD_TO_WAREHOUSE, productStatuses.completed].includes(newStatus) && (
          <Checkbox
            onChange={selectAll}
            value={onlySelectedProducts.length === allowedProducts.length}
            wrapperStyle={style.checkbox}
            content={'Select all'}
            reverse
          />
        )}
      </div>
      <div className={style.wrapper}>
        {(newStatus === productStatuses.completed ? productsList : allowedProducts).map((product) => (
          <ProductSelector
            product={product}
            key={product.id}
            isSelected={onlySelectedProducts.some((prod) => prod.id === product.id)}
            onSelectChange={(value) => changeItemSelection(value)}
            disabled={product.added_to_warehouse && newStatus === ADD_TO_WAREHOUSE}
            suborderUid={suborderUid}
            status={newStatus}
            isLoading={isLoading}
            onChange={onProductChange}
            withExpireDate={newStatus === ADD_TO_WAREHOUSE}
            parentValidator={validator}
          />
        ))}
      </div>
      <Button
        label={formContent.button}
        onClick={() => changeProductStatusHandler()}
        isLoading={isLoading}
        disabled={newStatus !== productStatuses.completed && onlySelectedProducts.length === 0}
      />
    </div>
  );
};

export default ProductsSelect;
