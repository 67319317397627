import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { OrderOrValuationId, RelativeTime } from 'components';

import { valuationStatuses } from 'src/constants/enums';
import { toDate, toTime } from 'src/utils/dateTime';

import style from '../../SupplierForm.module.scss';

const ValuationDetails = () => {
  const store = useSelector((state) => state.supplierForm);
  const { status, created_at, public_id, sent_date, expire_date } = store;

  const createdDate = useMemo(() => (created_at ? `${toDate(created_at)}, ${toTime(created_at)}` : ''), [created_at]);
  const sentDate = useMemo(() => (sent_date ? `${toDate(sent_date)}, ${toTime(sent_date)}` : ''), [sent_date]);
  const expireDate = useMemo(() => (expire_date ? `${toDate(expire_date)}, ${toTime(expire_date)}` : ''), [expire_date]);
  const formDate = useMemo(
    () =>
      status === valuationStatuses.pending ? (
        <span>
          Expires: <RelativeTime date={expire_date} /> ({expireDate})
        </span>
      ) : status === valuationStatuses.sent ? (
        <span>
          Sent: <RelativeTime date={sent_date} /> ({sentDate})
        </span>
      ) : (
        ''
      ),
    [status, expire_date, sent_date]
  );

  return (
    <div
      className={style.info}
      id={'valuation-info'}
    >
      <div className={style.valuationInfo}>
        {!!public_id && (
          <OrderOrValuationId
            id={public_id}
            withPrefix={'Valuation number:'}
          />
        )}
        {!!created_at && (
          <p>
            Received: <RelativeTime date={created_at} /> ({createdDate})
          </p>
        )}
        {formDate}
      </div>
    </div>
  );
};

export default ValuationDetails;
