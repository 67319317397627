import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import classNames from 'classnames';

import { Button } from 'components';

import 'react-easy-crop/react-easy-crop.css';
import style from './PhotoCropper.module.scss';

const PhotoCropper = ({ onCropComplete, image, onSubmit, isLoading, showRound, onCancel }) => {
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleOnCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    onCropComplete(croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <div className={style.container}>
      <div className={classNames(style.wrapper, { cropAreaRound: showRound })}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropComplete={handleOnCropComplete}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          disableAutomaticStylesInjection
        />
      </div>
      <div className={style.control}>
        <Button
          label={'Zapisz zdjęcie'}
          onClick={() => onSubmit(croppedAreaPixels)}
          isLoading={isLoading}
        />
        <Button
          label={'Cancel'}
          onClick={onCancel}
          gray
        />
      </div>
    </div>
  );
};

export default PhotoCropper;
