import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';
import { pl } from 'date-fns/locale';
import parseISO from 'date-fns/parseISO';

import { handleNumeralWords } from './helpers';

const formatDate = (date) => {
  return format(date, 'd MMMM y');
};

const formatDateNumbers = (date) => {
  return format(date, 'd.MM.y');
};

export const formatTime = (date) => {
  try {
    return format(date, 'HH:mm');
  } catch (e) {
    return '';
  }
};

export const timeToDate = (start, end) => {
  try {
    return intervalToDuration({ start, end });
  } catch (err) {
    return {};
  }
};

export const toDate = (date) => {
  return formatDate(parseISO(date));
};

export const toDateNumber = (date) => {
  try {
    if (!date) return;
    return formatDateNumbers(parseISO(date));
  } catch (err) {
    console.error(err);
    return date;
  }
};

export const toTime = (date) => {
  try {
    return formatTime(parseISO(date));
  } catch (err) {
    console.error(err);
    return '';
  }
};

export const getTimeAgo = (date) => {
  const data = timeToDate(new Date(), new Date(date));

  const { days, hours, minutes, months, seconds, years } = data || {};

  if (years > 0 || months > 0 || days > 0) {
    return {
      date: toDateNumber(date) + ', ' + toTime(date),
      refresh: 0
    };
  } else if (hours > 0) {
    return {
      date: `${hours} godz. temu`,
      refresh: 0
    };
  } else if (minutes > 0) {
    return {
      date: `${minutes} min. temu`,
      refresh: 60000
    };
  } else {
    return {
      date: `${seconds} s temu`,
      refresh: 5000
    };
  }
};

// 30 days
const WARNING_PERIOD = 2_592_000_000;
const ERROR_PERIOD = 0;

export const getExpireDateStatus = (date) => {
  if (date) {
    const toDate = new Date(date).getTime() - new Date().getTime();

    if (toDate > WARNING_PERIOD) {
      return '';
    } else if (toDate > ERROR_PERIOD) {
      return 'warning';
    } else {
      return 'error';
    }
  }
};

export const getShortTime = (seconds) => {
  const minutes = (seconds / 60).toFixed(0);
  if (minutes > 59) {
    return minutes % 60 > 0 ? (minutes / 60).toFixed(0) + 'h ' + (minutes % 60) + 'min' : (minutes / 60).toFixed(0) + 'h';
  } else if (minutes <= 59 && seconds > 59) {
    return seconds % 60 > 0 ? minutes + 'min ' + (seconds % 60) + 's' : minutes + 'min';
  } else {
    return seconds + 's';
  }
};

export const disabledDateForTimeOfResponse = (current) => {
  // Can not select days before 01/07/2023 (because source of data - order history)
  return current && current.valueOf() < new Date('07/01/2023').getTime();
};
