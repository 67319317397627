import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import copyToClipboard from 'copy-to-clipboard';

import { ExtendDateForm, Modal, OrderOrValuationId, RelativeTime, Tag, Thumbnail, useIsAdmin, useIsMobile } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { AdminApi } from 'src/api';
import { valuationStatuses } from 'src/constants/enums';
import { toDateNumber, toTime } from 'src/utils/dateTime';
import { getDatePlusTime, getThumbnailName, getValuationStatusTag } from 'src/utils/helpers';

import { refreshValuations } from '../../../ValuationView/actions';

import style from '../ValuationThumbnail/ValuationThumbnail.module.scss';
import shared from 'styles/Shared.module.scss';

export const getClientName = (valuation) =>
  valuation?.clinics_name ? (
    <p>
      Dla: {valuation?.clinics_name} {valuation?.name && ` (${valuation?.name})`}
    </p>
  ) : (
    <p>Dla: {valuation?.name}</p>
  );

const SupplierValuationThumbnail = ({ item: valuation }) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const isAdmin = useIsAdmin();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const clientName = useMemo(() => getClientName(valuation), [valuation]);

  const handleValuationExtend = async (date) => {
    try {
      await AdminApi.extendValuationDate(valuation.uid, date);
      notifyCommon([`Wycena została przedłużona do ${toDateNumber(date)}, ${toTime(date)}`]);
      dispatch(refreshValuations());
    } catch (err) {
      notifyApiError(err);
    }
  };

  const extendValuationExpireDate = (days) => {
    handleValuationExtend(getDatePlusTime(valuation.expire_date, days));
  };

  const handleRemind = async () => {
    try {
      await AdminApi.remindDistributorValuation(valuation.uid);
      notifyCommon(['Przypomnienie zostało wysłane!']);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const handleCopyLink = () => {
    copyToClipboard(`${window.location.origin}/supplier-form/${valuation.uid}`);
    notifyCommon(['Link został skopiowany do schowka.']);
  };

  const actions = [
    {
      title: 'Przedłuż o 24h',
      icon: 'add',
      action: () => extendValuationExpireDate(1)
    },
    {
      title: 'Przedłuż o 48h',
      icon: 'add',
      action: () => extendValuationExpireDate(2)
    },
    {
      title: 'Przedłuż o 72h',
      icon: 'add',
      action: () => extendValuationExpireDate(3)
    },
    {
      title: 'Wybierz datę wygaśnięcia',
      icon: 'edit',
      action: () => setIsModalOpen(true)
    },
    {
      title: 'Wyślij przypomnienie',
      icon: 'notification',
      action: handleRemind
    },
    {
      title: 'Skopiuj link dla dostawcy',
      icon: 'copy',
      color: 'blue',
      action: handleCopyLink
    }
  ];

  return (
    <Thumbnail
      item={valuation}
      small={isMobile}
      link={`/supplier/valuations/${valuation.uid}`}
      namePrefix={valuation.client_data_hidden ? 'Wyceny numer: ' : 'Wycena dla: '}
      title={getThumbnailName(valuation, isAdmin)}
      showTime
      status={getValuationStatusTag(valuation.status)}
      menu={isAdmin ? actions : []}
      hideOwner={!isAdmin && valuation.client_data_hidden}
      extraInfo={
        <OrderOrValuationId
          id={valuation.public_id}
          withPrefix={'Number:'}
        />
      }
    >
      <div className={style.container}>
        {!!valuation?.name && clientName}
        {valuation?.status === valuationStatuses.pending
          ? !!valuation?.expire_date && (
              <p>
                Expires: {toDateNumber(valuation.expire_date)}, {toTime(valuation.expire_date)}
                <span className={style.counter}>
                  <RelativeTime date={valuation.expire_date} />{' '}
                </span>
              </p>
            )
          : !!valuation?.sent_date && <p>Wysłano: {toDateNumber(valuation.sent_date)}</p>}
        {isAdmin && (
          <div className={shared.thumbnailContentWrapper}>
            <p className={shared.thumbnailContentLabel}>Dostawca:</p>
            <Tag
              value={valuation?.supplier_user_name || valuation?.supplier_name}
              className={style.tag}
            />
          </div>
        )}
      </div>
      <Modal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Wycena dostawcy: ${valuation.distributor_name}`}
      >
        <ExtendDateForm
          handleValuationExtend={handleValuationExtend}
          closeModal={() => setIsModalOpen(false)}
        />
      </Modal>
    </Thumbnail>
  );
};

export default SupplierValuationThumbnail;
