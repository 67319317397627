import React from 'react';

import SupplierTag from 'components/layout/SupplierTag';
import ThumbnailLists from 'components/layout/ThumbnailLists';

import { getOrderStatusTag } from 'src/utils/helpers';

const statuses = ['PENDING', 'SENT', 'CANCELLED', 'EXPIRED'];

const SuppliersList = ({ suppliers = [], uid, statusesList = statuses, getStatusHandler = getOrderStatusTag }) => {
  const renderElement = (sup) => (
    <SupplierTag
      {...(getStatusHandler({ status: sup.status, returnData: true }) || {})}
      supplier={sup}
      key={sup.id + uid}
      type={'valuations'}
    />
  );

  return (
    <ThumbnailLists
      renderElement={renderElement}
      statusesList={statusesList}
      label={'Suppliers:'}
      items={suppliers}
      uid={uid}
    />
  );
};

export default SuppliersList;
