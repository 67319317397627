import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Loader } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { CompanyApi } from 'src/api';

import RoleHeader from './components/RoleHeader';
import UserRowForm from './components/UserRowForm';

import style from './PermissionsForm.module.scss';

const PermissionsForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [owner, setOwner] = useState(null);

  const getMembersList = async () => {
    try {
      setIsLoading(true);
      const { data } = await CompanyApi.getMembers();
      setMembers(data.members);
      setOwner(data.owner);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMembersList();
  }, []);

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <div className={style.container}>
      <div className={style.header}>
        <span />
        <div className={style.selectAll}>
          <RoleHeader
            value={'Select all'}
            info={'Przyznaje wszystkie permisje oprócz uprawnień administatora'}
          />
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Listy</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na wyświetlanie i edytowanie zawartości list'}
            />
            <RoleHeader
              value={'tworzenie'}
              info={'Pozwala na tworzenie i edytowanie nazw list'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Umożliwia usuwanie list'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Wyceny</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na wyświetlanie i wybór produktów w wycenie'}
            />
            <RoleHeader
              value={'tworzenie'}
              info={'Pozwala wysłać wycenę do dostawców'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Pozwala na anulowanie wyceny'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Zamówienia</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na wyświetlanie zamówień'}
            />
            <RoleHeader
              value={'tworzenie'}
              info={'Pozwala na składanie zamówienia na podstawie wyceny'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Pozwala na anulowanie zamówienia'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Oferty</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na dostęp do panelu ofert'}
            />
            <RoleHeader
              value={'zarządzanie'}
              info={'Pozwala na zarządzanie panelem ofert'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Statystyki</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na przeglądanie statystyk'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Magazyn</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na przeglądanie magazynu'}
            />
            <RoleHeader
              value={'edycja'}
              info={'Pozwala na edycje danych w magazynie'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Pozwala na usuwanie produktów z magazynu'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Faktury</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na przeglądanie faktur'}
            />
            <RoleHeader
              value={'edycja'}
              info={'Pozwala na edycje i dodawanie faktur'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Pozwala na usuwanie faktur'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Produkty</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na dostęp do bazy produktów'}
            />
            <RoleHeader
              value={'edycja'}
              info={'Pozwala na edycje i dodawanie produktów'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Pozwala na usuwanie produktów firmowych'}
            />
          </div>
        </div>
        <div className={style.division}>
          <h3 className={style.name}>Dostawcy</h3>
          <div className={style.columns}>
            <RoleHeader
              value={'dostęp'}
              info={'Pozwala na dostęp do listy dostawców'}
            />
            <RoleHeader
              value={'edycja'}
              info={'Pozwala na edycje i dodawanie dostawców'}
            />
            <RoleHeader
              value={'usuwanie'}
              info={'Pozwala na usuwanie dostawców'}
            />
          </div>
        </div>
        <div className={cn(style.division, style.admin)}>
          <h3 className={style.name}>Admin oddziału</h3>
        </div>
      </div>
      <div className={style.membersWrapper}>
        {owner && (
          <UserRowForm
            user={owner}
            isOwner
          />
        )}
        {members.map((member) => (
          <UserRowForm
            user={member}
            key={member.id}
          />
        ))}
      </div>
    </div>
  );
};

export default PermissionsForm;
