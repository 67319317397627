import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Button, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';
import { EXCEL_FILE_TYPE } from 'src/constants/misc';
import { downloadFile, getDecodeBase64File } from 'src/utils/helpers';

import style from '../../FlatFileHandling.module.scss';

const VolumeExport = ({ selectedCompany }) => {
  const isAdmin = useIsAdmin();
  const [isLoadingCSV, setIsLoadingCSV] = useState(false);
  const [isLoadingXLSX, setIsLoadingXLSX] = useState(false);

  const setIsLoading = (value, type, withSupplier) => {
    if (!withSupplier && type === 'XLSX') {
      setIsLoadingXLSX(value);
    } else if (!withSupplier && type === 'CSV') {
      setIsLoadingCSV(value);
    }
  };

  const downloadHandler = async (type, withSupplier = false, downloadAll = false) => {
    const queryData = {
      file_format: type,
      ...(isAdmin && { company_id: selectedCompany })
    };

    const filename = 'wolumeny-miesieczne-produktow';

    const date = dayjs().format('DD.MM.YYYY, HH:mm');

    const downloadCSV = async () => {
      const { data } = await OfferCatalogApi.exportProductsVolume(queryData);
      const blob = new Blob([data], { type: 'text/csv' });
      downloadFile(blob, filename + '_' + date + '.csv');
    };
    const downloadXLSX = async () => {
      const { data } = await OfferCatalogApi.exportProductsVolume(queryData);
      const blob = getDecodeBase64File(data, EXCEL_FILE_TYPE);
      downloadFile(blob, filename + '_' + date + '.xlsx');
    };

    const callback = type === 'CSV' ? downloadCSV : downloadXLSX;

    try {
      setIsLoading(true, type, withSupplier, downloadAll);
      await callback();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false, type, withSupplier, downloadAll);
    }
  };

  return (
    <div className={style.container}>
      <header className={style.header}>
        <h3>Export wolumenów miesięcznych</h3>
        <p>
          Pobierz szablon z Twoimi produktami, aby wypełnić go cenami dostawcy. Plik możesz przesłać Twojemu dostawcy, aby
          przygotował w nim wycenę. Następnie przejdź do zakładki <strong>Import</strong>, i prześlij wpisane dane.
        </p>
      </header>
      <div className={style.buttonsWrapper}>
        <Button
          label={'Pobierz listę (.csv)'}
          isLoading={isLoadingCSV}
          onClick={() => downloadHandler('CSV')}
        />
        <Button
          label={'Pobierz listę Excel (.xlsx)'}
          isLoading={isLoadingXLSX}
          onClick={() => downloadHandler('XLSX')}
        />
      </div>
    </div>
  );
};

export default VolumeExport;
