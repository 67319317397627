import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Table, useIsAdmin } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import Supplier from './components/Supplier';

import managementStyle from '../../CatalogManagement.module.scss';
import style from './components/Supplier/Supplier.module.scss';

const SUPPLIERS_LIMIT = 5;

const SuppliersSummary = ({ suppliers = [], isSuppliersLoading }) => {
  const isAdmin = useIsAdmin();
  const [summary, setSummary] = useState();
  const [showAll, setShowAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const version = useSelector((state) => state.cartAndCatalog.version);
  const selectedCompany = useSelector((state) => state.cartAndCatalog.selectedCompany);

  const getData = async () => {
    const params = {
      suppliers_ids: suppliers.map((sup) => sup.id),
      ...(isAdmin && { company_id: selectedCompany })
    };

    try {
      setIsLoading(true);
      const { data } = await OfferCatalogApi.getSuppliersParticipation(params);
      setSummary(data.sort((a, b) => b.products.offered_products - a.products.offered_products));
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    { name: 'Lp.' },
    { name: 'Supplier' },
    { name: 'Total offers' },
    { name: 'Substitutes' },
    { name: 'Preferred offers' },
    { name: 'Alternative offers' },
    { name: 'Estimated expenses' }
  ];

  useEffect(() => {
    if (suppliers.length > 0) getData();
  }, [version, suppliers]);

  const trimmedSummary = useMemo(
    () => (summary?.length <= SUPPLIERS_LIMIT + 1 ? summary : summary?.slice(0, SUPPLIERS_LIMIT)),
    [summary]
  );

  const loading = isLoading || isSuppliersLoading;

  return (
    <>
      <div className={cn(managementStyle.header, style.header)}>
        <h2>Suppliers</h2>
        <p className={managementStyle.text}>
          Below is a list of offers received from suppliers. To modify offers, use the buttons in the menu on the left.
        </p>
      </div>
      <div className={style.tableWrapper}>
        <Table
          columns={columns}
          headerClasses={cn(style.row, style.tableHeader)}
          className={cn(style.table, managementStyle.tableWrapper)}
        >
          {loading ? (
            <Skeleton
              width={'100%'}
              height={22}
              count={SUPPLIERS_LIMIT + 2}
            />
          ) : (
            <>
              {(showAll ? summary : trimmedSummary)?.map((sup, index) => (
                <Supplier
                  key={sup.supplier_id}
                  index={index}
                  supplier={sup}
                />
              ))}
            </>
          )}
        </Table>
      </div>
      {!loading && summary?.length >= SUPPLIERS_LIMIT + 2 && (
        <>
          <p className={managementStyle.disclaimer}>
            * The amount refers to monthly expenses. The spend forecast is based on the selected preferred offer and volume.
          </p>
          <a
            onClick={() => setShowAll((prev) => !prev)}
            className={style.link}
          >
            {showAll ? 'Collapse' : `Show all (+${summary?.length - SUPPLIERS_LIMIT})`}
          </a>
        </>
      )}
    </>
  );
};

export default SuppliersSummary;
