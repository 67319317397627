import { useCallback, useEffect, useState } from 'react';
import { Popover } from 'antd';

import { toDateNumber, toTime } from 'src/utils/dateTime';

const RelativeTime = ({ date, className, hideTime }) => {
  const [time, setTime] = useState();
  const [intervalValue, setIntervalValue] = useState();

  const getTime = useCallback((date) => {
    const parsed = new Date(date);

    if (!date) {
      return '-';
    }

    const formatter = new Intl.RelativeTimeFormat('en', {
      numeric: 'auto'
    });

    let diff = Math.floor((Date.now() - parsed.getTime()) / 1000);
    let xd = -1;

    if (diff < 0) {
      diff = Math.floor((parsed.getTime() - Date.now()) / 1000);
      xd = 1;
    }

    if (diff < 60) {
      return formatter.format(xd * diff, 'seconds');
    }

    if (diff < 60 * 60) {
      return formatter.format(xd * Math.floor(diff / 60), 'minutes');
    }

    if (diff < 60 * 60 * 24) {
      return formatter.format(xd * Math.floor(diff / (60 * 60)), 'hours');
    }

    if (diff < 60 * 60 * 24 * 7) {
      return formatter.format(xd * Math.floor(diff / (60 * 60 * 24)), 'days');
    }

    if (diff < 60 * 60 * 24 * 30) {
      return formatter.format(xd * Math.floor(diff / (60 * 60 * 24 * 7)), 'weeks');
    }

    if (diff < 60 * 60 * 24 * 30 * 12) {
      return formatter.format(xd * Math.floor(diff / (60 * 60 * 24 * 30)), 'months');
    }

    return formatter.format(xd * Math.floor(diff / (60 * 60 * 24 * 30 * 12)), 'years');
  }, []);

  useEffect(() => {
    const scheduleCallback = (pastDate, callback) => {
      const diff = Math.floor((Date.now() - new Date(pastDate).getTime()) / 1000);

      let interval;

      if (diff < 1000 * 60) {
        interval = 1000 * 5;
      } else if (diff < 1000 * 60 * 60) {
        interval = 1000 * 60;
      } else if (diff < 1000 * 60 * 60 * 24) {
        interval = 1000 * 60 * 60;
      } else {
        return;
      }

      if (interval !== intervalValue) setIntervalValue(interval);
      return setInterval(callback, interval);
    };

    const calculateDiff = () => setTime(getTime(date));
    calculateDiff();
    const intervalId = scheduleCallback(date, calculateDiff);

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [date, intervalValue]);

  return (
    <>
      <Popover
        content={<>{toDateNumber(date) + (hideTime ? '' : `, ${toTime(date)}`)}</>}
        placement='top'
      >
        <span
          className={className}
          style={{ cursor: 'default' }}
        >
          {time}
        </span>
      </Popover>
    </>
  );
};

export default RelativeTime;
