import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  Button,
  Modal,
  PageHeader,
  ProcessStepper,
  ProductSearch,
  SeoHelmet,
  useCompany,
  useIsMobile,
  usePermissions
} from 'components';
import CreateValuationForm from 'components/layout/CreateValuationForm';
import TimelineHistory from 'components/layout/TimelineHistory';
import { notifyApiError } from 'components/layout/Toasts';

import { ListsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import { orderProcessSteps } from 'src/constants/steps';
import ListViewTable from 'src/features/ListView/components/ListViewTable';
import { handleNumeralWords } from 'src/utils/helpers';

import { deleteAll, refreshListView } from './actions';

import style from './ListView.module.scss';
import shared from 'styles/Shared.module.scss';

const ListView = () => {
  const { uid } = useParams();
  const company = useCompany();
  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const [pageData, setPageData] = useState([]);
  const [listData, setListData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [historyData, setHistoryData] = useState([]);
  const [isValuationModalOpen, setIsValuationModalOpen] = useState();
  const [hiddenElements, setHiddenElements] = useState([]);

  const [canCreateVertical] = usePermissions([userPermissions.valuations.create]);
  const [canCreateOrder] = usePermissions([userPermissions.orders.create]);

  const products = useSelector((state) => state.listsView.products);
  const version = useSelector((state) => state.listsView.version);
  const isOfferCatalogEnabled = company?.offer_catalog_enabled;

  const { listProducts, productsLength } = useMemo(() => {
    const products = [];
    pageData.forEach((cat) => products.push(...cat.items.map((item) => ({ ...item, category_name: cat.name }))));
    return { listProducts: products, productsLength: products.length };
  }, [pageData]);

  const getData = async () => {
    try {
      const { data } = await ListsApi.getListProducts(uid);
      setPageData(data.categories);
      setListData(data.list);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getHistory = async () => {
    try {
      const { data } = await ListsApi.getListActions(uid);
      setHistoryData(data);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const addToList = async (item, quantity) => {
    const data = {
      product_id: item?.product_id || item.id,
      quantity,
      uid
    };

    try {
      await ListsApi.storeToList(data);
      dispatch(refreshListView());
      setHiddenElements((prev) => [...prev, item?.product_id || item.id]);
    } catch (err) {
      notifyApiError(err);
    }
  };

  const getProductName = (id) => listProducts.find((prod) => prod.id === id)?.name || '';

  useEffect(() => {
    const getDataWithLoading = async () => {
      dispatch(deleteAll());
      setIsLoading(true);
      await getData();

      setIsLoading(false);
    };

    getDataWithLoading();
  }, []);

  useEffect(() => {
    if (version) getData();
    getHistory();
  }, [version]);

  return (
    <>
      <SeoHelmet title={listData?.title || 'Lista'} />
      <PageHeader
        name={`List: ${listData?.title}`}
        text={`${productsLength} ${handleNumeralWords(['product', 'products', 'products'], listData?.count || 0)}`}
        isLoading={isLoading}
        className={style.title}
      >
        <div
          className={classNames(shared.buttonWrapper, {
            [shared.reverse]: isMobile
          })}
        >
          <TimelineHistory
            history={historyData}
            getProductName={getProductName}
            title={`List history (${listData?.title})`}
            buttonLabel={'List history'}
          />
          {canCreateVertical && (
            <Button
              label='Ask for valuation'
              onClick={() => setIsValuationModalOpen(true)}
              disabled={!pageData?.length || products?.length === 0}
              gray={isOfferCatalogEnabled}
            />
          )}
          {isOfferCatalogEnabled && canCreateOrder && (
            <Button
              label={'Your cart'}
              to={`/lists/${uid}/cart`}
              state={{ clearSelection: true }}
              disabled={!pageData?.length || products?.length === 0}
            />
          )}
        </div>
      </PageHeader>
      <ProcessStepper
        steps={orderProcessSteps}
        current={0}
      />
      <div className={style.container}>
        <ProductSearch
          list={pageData}
          companyId={listData?.company_id}
          hiddenElements={hiddenElements}
          setHiddenElements={setHiddenElements}
          placeholder={'Search for a product to add it to your list...'}
          onProductClick={addToList}
          scrollIntoViewOnActive
          fullWidth
        />
        <ListViewTable
          list={pageData}
          isLoading={isLoading}
          uid={uid}
        />
      </div>
      <Modal
        visible={isValuationModalOpen}
        onClose={() => setIsValuationModalOpen(false)}
        title={'Ask for valuation'}
      >
        <CreateValuationForm
          listUID={uid}
          products={listProducts}
          listComment={listData.list_comment}
          onFinish={() => setIsValuationModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default ListView;
