import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';

import { LabelWithValue } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { StatisticsApi } from 'src/api';
import { ordersAtom, suppliersCountAtom } from 'src/features/Analytics';
import { getFormattedAmount } from 'src/utils/helpers';

import style from './OrdersSummary.module.scss';

export const getOrdersSumForUser = (data = []) => data?.reduce((acc, value) => acc + value, 0);

const OrdersSummary = ({ filters, selectedCompanyId }) => {
  const [ordersData, setOrdersData] = useAtom(ordersAtom);
  const [suppliersData, setSuppliersData] = useAtom(suppliersCountAtom);

  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    const queryData = {
      ...(!!filters.selectedCompanies && { company_ids: filters.selectedCompanies.map((company) => company.value) }),
      ...(selectedCompanyId && { company_id: selectedCompanyId }),
      ...(filters.startDate && filters.endDate && { date_from: filters.startDate, date_to: filters.endDate })
    };

    try {
      setIsLoading(true);
      const { data: order } = await StatisticsApi.getExpensesMonthly(queryData);
      const { data: suppliers } = await StatisticsApi.getProductsRealisationBySupplierMonthly(queryData);
      setOrdersData(order);
      setSuppliersData(suppliers);
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [filters, selectedCompanyId]);

  const ordersTotalValue = useMemo(() => {
    const sum = getOrdersSumForUser(ordersData?.total_sum);
    const count = ordersData?.orders_count?.reduce((acc, value) => acc + value, 0);

    const productsCount = ordersData?.series_unique_products_count
      ?.map((item) => item.data.reduce((acc, value) => acc + value, 0))
      .reduce((acc, value) => acc + value, 0);

    return { sum, count, productsCount };
  }, [ordersData]);

  const suppliersCount = useMemo(() => {
    const count = suppliersData?.series_realisation_count
      ?.map((sup) => sup.data.reduce((acc, [_, value]) => acc + value, 0))
      .filter((value) => value > 0).length;

    return { count };
  }, [suppliersData]);

  return (
    <div className={style.wrapper}>
      <LabelWithValue
        label={'Total orders value'}
        value={ordersTotalValue.sum ? getFormattedAmount(ordersTotalValue.sum) : '-'}
        isLoading={isLoading}
      />
      <LabelWithValue
        isLoading={isLoading}
        label={'Number of orders'}
        value={ordersTotalValue.count || '-'}
      />
      <LabelWithValue
        label={'Suppliers participating'}
        value={suppliersCount.count || '-'}
      />
    </div>
  );
};

export default OrdersSummary;
