import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Button, FileDropzone, useIsAdmin, useValidator } from 'components';
import { notifyApiError, notifyCommon } from 'components/layout/Toasts';

import { OfferCatalogApi } from 'src/api';

import { refreshCartOrCatalog } from '../../../../actions';

import style from '../../FlatFileHandling.module.scss';

export const getFormData = (params) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(params)) {
    formData.append(key, value);
  }
  return formData;
};

const VolumeImport = ({ selectedCompany, closeModal }) => {
  const isAdmin = useIsAdmin();
  const dispatch = useDispatch();
  const validator = useValidator();
  const [flatFile, setFlatFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      return validator.showMessages();
    }

    const queryData = {
      volumes_file: flatFile,
      ...(isAdmin && { company_id: selectedCompany })
    };

    const formData = getFormData(queryData);

    try {
      setIsLoading(true);
      await OfferCatalogApi.importProductsVolume(formData);
      notifyCommon(['Wolumeny zostały zaimportowane'], { title: 'Sukces!' });
      validator.hideMessages();
      setFlatFile(null);
      dispatch(refreshCartOrCatalog());
      if (closeModal) closeModal();
    } catch (err) {
      notifyApiError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      className={style.container}
      onSubmit={submitHandler}
    >
      <header className={style.header}>
        <h3>Import pliku z wolumenami miesięcznymi</h3>
        <p>
          Wybierz plik oraz dostawcę, aby dodać do katalogu wprowadzone w pliku ceny. Zostaną one automatycznie przypisane do
          wybranego poniżej odstawcy.
        </p>
      </header>
      <FileDropzone
        label={'Szablon z wolumenami produktów'}
        onChange={setFlatFile}
        value={flatFile}
        validator={validator}
        rule={'required'}
      />
      <Button
        label={'Zaimportuj wolumeny'}
        type={'submit'}
        className={style.button}
        isLoading={isLoading}
      />
    </form>
  );
};

export default VolumeImport;
