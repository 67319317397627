import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { AsyncSelect, useAuthUser } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { AdminApi, UserApi } from 'src/api';
import { userRoles } from 'src/constants/enums';
import { compareArrays, formatUserOption } from 'src/utils/helpers';

import style from '../Filtering.module.scss';

const FilterBySupplier = ({
  params,
  fullWidth,
  valueKey = 'id',
  labelKey = 'name',
  isMulti,
  queryParams = {},
  asUser,
  label,
  disabledElements,
  isOptionDisabled,
  ...rest
}) => {
  const [selectedSupplier, setSelectedSupplier] = useState(isMulti ? [] : null);
  const user = useAuthUser();

  const paramValue = params.get('supplier');

  const setSingleValue = (supplier) => {
    const areValueTheSame = supplier?.value === paramValue;

    if (!areValueTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'supplier', value: supplier?.value },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('supplier', supplier?.value);
      }
    }
  };

  const setMultiValue = (suppliers) => {
    const idsArray = suppliers?.map((sup) => sup.value);
    const areArrayTheSame = compareArrays(idsArray, paramValue);

    if (!areArrayTheSame) {
      if (params.get('page')) {
        params.setFew([
          { key: 'supplier', value: idsArray },
          { key: 'page', value: '1' }
        ]);
      } else {
        params.set('supplier', idsArray);
      }
    }
  };

  const onChangeHandler = (e) => {
    setSelectedSupplier(e);
    if (isMulti) {
      setMultiValue(e);
    } else {
      setSingleValue(e);
    }
  };

  useEffect(() => {
    const fetchCategory = (param = paramValue) => {
      const queryParams = {
        supplier_id: param,
        perPage: 1,
        page: 1
      };
      return UserApi.getDistributors(queryParams);
    };

    const getSelectedElement = async () => {
      try {
        const { data } = await fetchCategory();
        setSelectedSupplier(formatUserOption(data[0]));
      } catch (err) {
        notifyApiError(err);
      }
    };

    const getDataForAllCategories = async () => {
      try {
        const results = await Promise.all(paramValue.map((param) => fetchCategory(param)));
        setSelectedSupplier(results.map((result) => formatUserOption(result.data[0])));
      } catch (err) {
        notifyApiError(err);
      }
    };

    if (paramValue) {
      if (Array.isArray(paramValue)) getDataForAllCategories();
      else getSelectedElement();
    }
  }, []);

  if (user?.role !== userRoles.admin || asUser) {
    return (
      <AsyncSelect
        value={selectedSupplier}
        onChange={onChangeHandler}
        apiCallback={UserApi.getDistributors}
        valueKey={'id'}
        labelKey={'name'}
        label={label}
        placeholder={'Supplier'}
        queryParams={{ pagination: 1, ...queryParams }}
        isOptionDisabled={isOptionDisabled}
        disabledElements={disabledElements}
        isClearable
        isMulti={isMulti}
        {...rest}
      />
    );
  }

  return (
    <AsyncSelect
      value={selectedSupplier}
      onChange={onChangeHandler}
      apiCallback={AdminApi.getUsers}
      valueKey={valueKey}
      label={label}
      labelKey={labelKey}
      placeholder={'Supplier'}
      isOptionDisabled={isOptionDisabled}
      disabledElements={disabledElements}
      queryParams={{ role: userRoles.supplier, ...queryParams }}
      wrapperStyle={classNames(style.select, {
        [style.selected]: !!selectedSupplier,
        [style.fullWidth]: fullWidth
      })}
      isClearable
      isMulti={isMulti}
      {...rest}
    />
  );
};

export default FilterBySupplier;
