import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { useIsAdmin, usePermissions } from 'components';
import { notifyApiError } from 'components/layout/Toasts';

import { ProductsApi } from 'src/api';
import { userPermissions } from 'src/constants/enums';
import ProductFormAdmin from 'src/features/ProductsBase/components/ProductForms/components/ProductFormAdmin';

import ProductDetailsSection from './components/ProductDetailsSection';
import ProductOrdersChart from './components/ProductOrdersChart';
import ProductVolumesHistory from './components/ProductVolumesHistory';
import Skeleton from './components/Skeleton';
import TableWithPagination from './components/TableWithPagination';

import 'react-medium-image-zoom/dist/styles.css';
import style from './ProductDetails.module.scss';

const ProductDetails = forwardRef(({ id, image }, ref) => {
  const [canViewVolumes] = usePermissions([userPermissions.offer_catalog.read]);
  const isAdmin = useIsAdmin();
  const containerRef = useRef(null);
  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalProductFromOpen, setIsModalProductFromOpen] = useState(false);

  const getProductData = async (silent = false) => {
    try {
      !silent && setIsLoading(true);
      const { data } = await ProductsApi.productDetails(id);
      setProduct(data);
    } catch (err) {
      notifyApiError(err);
    } finally {
      !silent && setIsLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
  }, []);

  useEffect(() => {
    disableBodyScroll(containerRef.current);

    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useImperativeHandle(ref, () => containerRef.current, [containerRef]);

  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      {isLoading ? (
        <Skeleton />
      ) : (
        <ProductDetailsSection
          refreshCallback={getProductData}
          product={product}
          image={image}
        >
          {isAdmin && (
            <a
              style={{ marginTop: '12px' }}
              onClick={() => setIsModalProductFromOpen(true)}
            >
              Edytuj dane produktu
            </a>
          )}
        </ProductDetailsSection>
      )}
      <div className={style.wrapper}>
        {canViewVolumes && !isAdmin && (
          <ProductVolumesHistory
            id={id}
            title={'Volume history'}
            canLoad={!!product}
          />
        )}
        <ProductOrdersChart
          id={id}
          canLoad={!!product}
        />
        <TableWithPagination
          asOrder
          id={id}
          apiCallback={ProductsApi.productOrders}
          title={'Product history in orders'}
          canLoad={!!product}
        />
        <TableWithPagination
          id={id}
          apiCallback={ProductsApi.productPricings}
          title={'Product history in valuations'}
          canLoad={!!product}
        />
      </div>
      {isAdmin && (
        <ProductFormAdmin
          title='Edytuj produkt'
          product_id={id}
          visible={isModalProductFromOpen}
          type='edit-product'
          onClose={() => setIsModalProductFromOpen(false)}
          refreshCallback={getProductData}
        />
      )}
    </div>
  );
});

export default ProductDetails;
