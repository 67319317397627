import React, { useMemo } from 'react';
import classNames from 'classnames';

import { ProgressLine, SupplierColor, useIsMobile } from 'components';

import { getColumnPricesSum, getNumberOfSelectedProductInColumn, getSumOfProducts } from 'src/features/ValuationView/helpers';
import { getFormattedAmount, getValuationStatusTag } from 'src/utils/helpers';

import style from './CartSummary.module.scss';
import shared from 'styles/Shared.module.scss';

const CartSummary = ({ columns = [], isHorizontal }) => {
  // const isTabletWidth = useIsMobile(768);

  const suppliersSummary = useMemo(
    () =>
      columns.map((column) => ({
        ...column.supplier,
        itemsNumber: getNumberOfSelectedProductInColumn(column),
        amount: getColumnPricesSum(column)
      })),
    [columns]
  );

  const getSumOfSelectedProducts = () => {
    const itemsNumberArray = suppliersSummary.map((item) => item.itemsNumber);
    return itemsNumberArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  const getSumOfAmounts = () => {
    const sumArray = suppliersSummary.map((item) => item.amount);
    return sumArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  };

  return (
    <div className={style.container}>
      {!isHorizontal && <h2 className={style.title}>Order summary</h2>}
      {!isHorizontal && (
        <ProgressLine
          maxValue={getSumOfProducts(columns)}
          currentValue={getSumOfSelectedProducts()}
          prefix={'selected'}
        />
      )}
      <section className={classNames(shared.tableWrapper, style.table, style.tableWrapper)}>
        <div className={classNames(shared.tableHeader, style.tableHeader, { [style.horizontal]: isHorizontal })}>
          {isHorizontal && <p>Status</p>}
          <p>Supplier</p>
          <p className={style.textRight}>Selected</p>
          <p className={style.textRight}>Value</p>
          <p className={style.textRight}>Shipping</p>
        </div>
        <div className={classNames(shared.tableCategory, style.tableRows)}>
          {suppliersSummary.map((row) => (
            <div
              className={classNames(style.row, { [style.horizontal]: isHorizontal })}
              key={row.name}
            >
              {isHorizontal && <div className={style.data}>{getValuationStatusTag(row.status)}</div>}
              <div className={style.rowSupplierName}>
                <SupplierColor supplier={row} />
                <p>{row.name}</p>
              </div>
              <div className={style.data}>
                <p>{row.itemsNumber}</p>
              </div>
              <div className={style.data}>
                <p>{getFormattedAmount(row.amount)}</p>
              </div>
              <div className={style.data}>
                <p className={style.data}>
                  {+row.amount === 0 || +row.amount >= +row.free_shipment_price
                    ? getFormattedAmount(0)
                    : getFormattedAmount(row?.shipment_price)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
      {!isHorizontal && (
        <div className={style.summaryRow}>
          <p className={classNames(style.summary, style.twoColumns)}>Order total</p>
          <p className={style.summary}>{getFormattedAmount(getSumOfAmounts())}</p>
        </div>
      )}
    </div>
  );
};

export default CartSummary;
